import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {deserializeObject, getObjectiveDataWithMatchedData} from "../helpers/utils";
import Select from "react-select";

interface SelectWrapperProps {
    name?: string;
    accessor?: string;

    classNamePrefix: string;
    quickFilters: Array<any>;

    isMulti?: boolean;
    menuShouldBlockScroll?: boolean;
    menuShouldScrollIntoView?: boolean;
    tabSelectsValue?: boolean;
    backspaceRemovesValue?: boolean;
    defaultValue?: any,
    isConstantData?: boolean;

    options: { label: string, value: string }[];
    placeholder: string;
    onChange?: Function;
    reset?: Function;
}

export default function SearchSelectWrapper(props: SelectWrapperProps) {

    const location: any = useLocation();

    const {
        name,
        accessor,
        classNamePrefix,
        quickFilters,
        isMulti,
        menuShouldBlockScroll,
        menuShouldScrollIntoView,
        tabSelectsValue,
        backspaceRemovesValue,
        defaultValue,
        isConstantData = false,
        options,
        placeholder,
        onChange,
    } = props;

    let [values, setValues] = useState(null);
    const [optionState, setOptionState] = useState(null);


    //to prevent re-rendering of options multiple times when options are unChanged...
    useEffect(() => {
        if ((optionState === null || optionState === undefined) && isConstantData) {
            setOptionState(options);
        }
        if (!isConstantData) {
            setOptionState(options);
        }
    }, [options]);


    useEffect(() => {
        if (defaultValue !== null && values === null) {
            isMulti ?
                setValues((location.search !== "" && deserializeObject(location.search))?.hasOwnProperty(accessor) ? deserializeObject(location.search)[accessor] : [defaultValue])
                :
                setValues((location.search !== "" && deserializeObject(location.search?.hasOwnProperty(accessor))) ? deserializeObject(location.search)[accessor] : defaultValue);
        }
    }, []);


    useEffect(() => {
        if (location.search !== undefined && location.search !== '' && (deserializeObject(location.search)?.hasOwnProperty("quickFilters") || !deserializeObject(location.search).hasOwnProperty(accessor))) {
            if (quickFilters === null) {
                setValues("");
            }
        }
    }, [location.search]);


    useEffect(() => {
        if (location.search && location.search !== "" && optionState !== undefined) {

            if (deserializeObject(location.search) instanceof Object && Object.keys(deserializeObject(location.search)).length > 0) {

                Object.keys(deserializeObject(location.search)).map((cur, i) => {
                    if (cur === accessor) {
                        setValues(deserializeObject(location.search)[cur]);
                    }
                })
            }
        }

        if (quickFilters !== null && Array.isArray(quickFilters) && quickFilters.length > 0 && (location.search === "" || location.search === "?")) {
            setValues(null);
        }

        if (quickFilters !== null && Array.isArray(quickFilters) && quickFilters.length > 0 && (location.search && location.search !== "" && location.search !== "?") && Object.keys(deserializeObject(location.search)).length > 0 && !deserializeObject(location.search)?.hasOwnProperty("quickFilters") && !deserializeObject(location.search)?.hasOwnProperty(Object.keys(quickFilters[0]?.['keyVal'])[0])) {
            setValues(null);
        }

    }, [optionState]);


    const handleChange = (value: any) => {
        location.state = undefined;
        setValues(value && Array.isArray(value) ? value.map(subValue => subValue.value) : value?.value);
        onChange(value && Array.isArray(value) ? value.map(subValue => subValue.value) : value?.value);
    };

    // props.reset = () => {
    //     location.search = undefined;
    //     location.state = undefined;
    //     setValues(null);
    // };


    return <Select
        name={name}
        classNamePrefix={classNamePrefix}

        isMulti={isMulti}
        menuShouldBlockScroll={menuShouldBlockScroll}
        menuShouldScrollIntoView={menuShouldScrollIntoView}
        tabSelectsValue={tabSelectsValue}
        backspaceRemovesValue={backspaceRemovesValue}
        blurInputOnSelect={!isMulti}
        isClearable
        options={optionState}
        placeholder={placeholder}
        onChange={handleChange}
        isDisabled={!optionState || !optionState.length}
        value={getObjectiveDataWithMatchedData(isMulti, optionState, values, "value")}
    />;
};
