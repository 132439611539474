import exceptionHandler, {responseDTOExceptionHandler} from "../../common/helpers/exceptionHandler";
import {alertActions} from "../alert/alert";
import {showSuccessToast} from "../../common/components/Toast";
import {serialize} from "../../common/helpers/utils";
import axiosInstance from "../../axiosInstance";


//export actions
export const assignToActions = {
    getAllData,
    isUserManagerAction,
    assignProductToAction,
    updateProductTargetTeamIdAction

}


//export constants
export const assignToConstants = {
    GET_ALL_ASSIGN_TO_MEMBERS_REQUEST: 'GET_ALL_ASSIGN_TO_MEMBERS_REQUEST',
    GET_ALL_ASSIGN_TO_MEMBERS_SUCCESS: 'GET_ALL_ASSIGN_TO_MEMBERS_SUCCESS',
    GET_ALL_ASSIGN_TO_MEMBERS_FAILURE: 'GET_ALL_ASSIGN_TO_MEMBERS_FAILURE',

    GET_IS_USER_MANAGER_REQUEST: 'GET_IS_USER_MANAGER_REQUEST',
    GET_IS_USER_MANAGER_SUCCESS: 'GET_IS_USER_MANAGER_SUCCESS',
    GET_IS_USER_MANAGER_FAILURE: 'GET_IS_USER_MANAGER_FAILURE',

    ASSIGN_PRODUCT_TO_REQUEST: 'ASSIGN_PRODUCT_TO_REQUEST',
    ASSIGN_PRODUCT_TO_SUCCESS: 'ASSIGN_PRODUCT_TO_SUCCESS',
    ASSIGN_PRODUCT_TO_FAILURE: 'ASSIGN_PRODUCT_TO_FAILURE',

    ASSIGN_PRODUCT_TO_TEAM_REQUEST: 'ASSIGN_PRODUCT_TO_TEAM_REQUEST',
    ASSIGN_PRODUCT_TO_TEAM_SUCCESS: 'ASSIGN_PRODUCT_TO_TEAM_SUCCESS',
    ASSIGN_PRODUCT_TO_TEAM_FAILURE: 'ASSIGN_PRODUCT_TO_TEAM_FAILURE',

}


//export service
export const assignToService = {
    getAllAssignToMember,
    checkUserIsManager,
    assignProductTo,
    updateProductTargetTeamId

};

{/*********** Actions started here. ******************/
}


//get all users-manager Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());
        assignToService.getAllAssignToMember(query)
            .then(
                members => dispatch(success(members)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: assignToConstants.GET_ALL_ASSIGN_TO_MEMBERS_REQUEST}
    }

    function success(members) {
        return {type: assignToConstants.GET_ALL_ASSIGN_TO_MEMBERS_SUCCESS, members}
    }

    function failure(error) {
        return {type: assignToConstants.GET_ALL_ASSIGN_TO_MEMBERS_FAILURE, error}
    }
}


//get order needs to assigned status status action
function isUserManagerAction(pathVar) {
    return dispatch => {
        dispatch(request());
        assignToService.checkUserIsManager(pathVar)
            .then(
                status => dispatch(success(status)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: assignToConstants.GET_IS_USER_MANAGER_REQUEST}
    }

    function success(status) {
        return {type: assignToConstants.GET_IS_USER_MANAGER_SUCCESS, status}
    }

    function failure(error) {
        return {type: assignToConstants.GET_IS_USER_MANAGER_FAILURE, error}
    }
}


//assign to product action
function assignProductToAction(productId, userId, isAssigned, setDisable) {
    return dispatch => {
        dispatch(request());
        assignToService.assignProductTo(productId, userId, isAssigned)
            .then(
                data => {
                    if (data['errorCode'] === 200) {
                        showSuccessToast(data['message']);
                        dispatch(success(data));
                        window.location.reload();
                    } else {
                        responseDTOExceptionHandler(data);
                    }

                    setDisable(false)
                },
                error => {
                    setDisable(false);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: assignToConstants.ASSIGN_PRODUCT_TO_REQUEST}
    }

    function success(data) {
        return {type: assignToConstants.ASSIGN_PRODUCT_TO_SUCCESS, data}
    }

    function failure(error) {
        return {type: assignToConstants.ASSIGN_PRODUCT_TO_FAILURE, error}
    }
}


function updateProductTargetTeamIdAction(productId, teamId, setDisable, setShow) {
    return dispatch => {
        dispatch(request());
        assignToService.updateProductTargetTeamId(productId, teamId)
            .then(
                data => {
                    if (data['errorCode'] === 200) {
                        showSuccessToast(data['message']);
                        dispatch(success(data));
                        setShow(false);
                        window.location.reload();
                    } else {
                        responseDTOExceptionHandler(data);
                    }
                    setDisable(false)
                },
                error => {
                    setDisable(false);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: assignToConstants.ASSIGN_PRODUCT_TO_TEAM_REQUEST}
    }

    function success(data) {
        return {type: assignToConstants.ASSIGN_PRODUCT_TO_TEAM_SUCCESS, data}
    }

    function failure(error) {
        return {type: assignToConstants.ASSIGN_PRODUCT_TO_TEAM_FAILURE, error}
    }
}


/*********** Actions ended here. ******************/


/*********** Reducers started here. ******************/
export function getAllAssignToMemberReducer(state = {}, action) {
    switch (action.type) {
        case assignToConstants.GET_ALL_ASSIGN_TO_MEMBERS_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case assignToConstants.GET_ALL_ASSIGN_TO_MEMBERS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.members
            };
        case assignToConstants.GET_ALL_ASSIGN_TO_MEMBERS_FAILURE:
            return {};
        default:
            return state
    }
}


export function isUserManager(state = {}, action) {
    switch (action.type) {
        case assignToConstants.GET_IS_USER_MANAGER_REQUEST:
            return {
                ...state,
                loggingIn: true,
                items: []
            };
        case assignToConstants.GET_IS_USER_MANAGER_SUCCESS:
            return {
                loggedIn: true,
                items: action.status
            };
        case assignToConstants.GET_IS_USER_MANAGER_FAILURE:
            return {};
        default:
            return state
    }
}


/*********** Reducers ended here. ******************/


/*********** Services started here. ******************/

//get all assign to members
export function getAllAssignToMember(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/get-assign-to-member?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

//get team by id service
export function checkUserIsManager(pathVar) {
    return axiosInstance.get(`/ajax/check-is-user-manager/${pathVar}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

// assign product to
export function assignProductTo(productId, userId, isAssigned) {
    return axiosInstance.post(`/ajax/assign-product/${productId}/${userId}/${isAssigned}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function updateProductTargetTeamId(productId, teamId) {
    return axiosInstance.post(`/ajax/update-product-team-id/${productId}/${teamId}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}




/*********** Services ended here. ******************/


