import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import "./Header.css";
import {useLocation} from "react-router";
import {getReportDefaultUrl, isUserHaveAccess, useAuthorities} from "../../common/helpers/utils";
import {useDispatch, useSelector} from "react-redux";
import {homeActions} from "../home/home";

function Navbar() {
    const location = useLocation();
    const {pathname} = location;
    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    const dispatch = useDispatch();

    const [active, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!active);
    };

    const authorities: any = useAuthorities();
    const storageData = useSelector((state: any) => state.utilDataReducer);

    const reportAuthorities = ["TRNREPO-V", "REVREPO-V", "CTYREPO-V", "AGGUSRREPO-V", "DETUSRREPO-V", "AUTMUNREPO-V", "ACCRECREPO-V", "DLYCUNTREPO-V"];

    useEffect(() => {
        if (!authorities && !Array.isArray(authorities)) {
            dispatch(homeActions.fetchAuthoritiesAction());
            dispatch(homeActions.fetchEnumsAction());
        }
    }, []);


    return (
        <div id="wrapper"
             className={`side_navbar ${active ? 'toggled' : 'side_nav_'}`}>

            <aside id="sidebar-wrapper">

                <ul className="sidebar-nav">

                    <li className={splitLocation[1] === "home" ? "active" : ""}>
                        <Link to="/home"><i className="fa fa-home"/><span className="menu_items">Home</span></Link>
                    </li>


                    {isUserHaveAccess(authorities, ["ORDR-V"]) &&
                        <li className={splitLocation[1] === "orders" ? "active" : ""}>
                            <Link
                                to="/orders?state=TX&orderStatus%5B%5D=AWAITING_PARTNER&orderStatus%5B%5D=NEW&orderStatus%5B%5D=AWAITING_LOCATE&orderStatus%5B%5D=AWAITING_FULFILLMENT&orderStatus%5B%5D=PENDING&orderStatus%5B%5D=ISSUE&orderStatus%5B%5D=COMPLETED"><i
                                className="fa fa-table fa-icon"/><span
                                className="menu_items">Orders</span></Link>
                        </li>
                    }

                    {

                        (isUserHaveAccess(authorities, ["USR-V", "ROL-V", "TEAM-V"])) && (

                            active ?

                                <li className="nav-item dropdown">

                                    <a href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                                       aria-haspopup="true"
                                       aria-expanded="false" className="disabled navbarDropdown">
                                        <i className="fa fa-users"/><span className="menu_items">User</span>
                                    </a>

                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        {isUserHaveAccess(authorities, ["USR-V"]) && <Link to="/users" onClick={(e) => e.stopPropagation()}
                                                                                           className={splitLocation[1] === "users" ? "active dropdown-item" : "dropdown-item"}>Users</Link>}

                                        {isUserHaveAccess(authorities, ["ROL-V"]) && <Link to="/roles" onClick={(e) => e.stopPropagation()}
                                                                                           className={splitLocation[1] === "roles" ? "active dropdown-item" : "dropdown-item"}>Roles</Link>}
                                        {isUserHaveAccess(authorities, ["TEAM-V"]) && <Link to="/teams" onClick={(e) => e.stopPropagation()}
                                                                                            className={splitLocation[1] === "teams" ? "active dropdown-item" : "dropdown-item"}>Teams</Link>
                                        }

                                    </div>
                                </li>
                                :
                                <li className="nav-item dropdown">

                                    <a href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                                       aria-haspopup="true"
                                       aria-expanded="false" className="navbarDropdown">
                                        <i className="fa fa-users"/><span className="menu_items">User</span>
                                    </a>

                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                        {isUserHaveAccess(authorities, ["USR-V"]) &&
                                            <Link to="/users" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "users" ? "active dropdown-item" : "dropdown-item"}>Users</Link>}

                                        {isUserHaveAccess(authorities, ["ROL-V"]) &&
                                            <Link to="/roles" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "roles" ? "active dropdown-item" : "dropdown-item"}>Roles</Link>}

                                        {isUserHaveAccess(authorities, ["TEAM-V"]) &&
                                            <Link to="/teams" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "teams" ? "active dropdown-item" : "dropdown-item"}>Teams</Link>
                                        }
                                    </div>
                                </li>

                        )
                    }


                    {isUserHaveAccess(authorities, ["CUST-V"]) &&
                        <li className={splitLocation[1] === "customers" ? "active" : ""}>
                            <Link to="/customers?enabled=true"><i className="fa fa-list"/><span
                                className="menu_items">Customers</span></Link>
                        </li>
                    }


                    {isUserHaveAccess(authorities, [...reportAuthorities, "ORDREVNT-V", "REPOHIS-V"]) ?
                        (active ?

                                <li className="nav-item dropdown">
                                    <a href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                                       aria-haspopup="true"
                                       aria-expanded="false" className="disabled navbarDropdown">
                                        <i className="fa fa-chart-line"/><span
                                        className="menu_items">Reports</span>
                                    </a>

                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                        {isUserHaveAccess(authorities, reportAuthorities) &&
                                            <Link onClick={(e) => {
                                                e.stopPropagation();
                                            }} to={getReportDefaultUrl(authorities)}
                                                  className={splitLocation[1] === "reports" ? "active dropdown-item" : "dropdown-item"}>Reports</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["REPOHIS-V"]) &&
                                            <Link onClick={(e) => {
                                                e.stopPropagation();
                                            }} to="/report-history"
                                                  className={splitLocation[1] === "report-history" ? "active dropdown-item" : "dropdown-item"}>History</Link>
                                        }


                                        {isUserHaveAccess(authorities, ["ORDREVNT-V"]) &&
                                            <Link onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                                  to="/order-events"
                                                  className={splitLocation[1] === "order-events" ? "active dropdown-item" : "dropdown-item"}>Order
                                                Events</Link>
                                        }


                                    </div>
                                </li>

                                :

                                <li className="nav-item dropdown">
                                    <a href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                                       aria-haspopup="true" aria-expanded="false" className="navbarDropdown">
                                        <i className="fa fa-chart-line"/><span
                                        className="menu_items">Reports</span>
                                    </a>

                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown"
                                         id="accountingDropDown">

                                        {isUserHaveAccess(authorities, ["TRNREPO-V","REVREPO-V", "CTYREPO-V", "AGGUSRREPO-V", "DETUSRREPO-V", "AUTMUNREPO-V", "ACCRECREPO-V", "DLYCUNTREPO-V"]) &&
                                            <Link onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                                  to={getReportDefaultUrl(authorities)}
                                                  className={splitLocation[1] === "reports" ? "active dropdown-item" : "dropdown-item"}>Reports</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["REPOHIS-V"]) &&
                                            <Link onClick={(e) => {
                                                e.stopPropagation();
                                            }} to="/report-history"
                                                  className={splitLocation[1] === "report-history" ? "active dropdown-item" : "dropdown-item"}>History</Link>

                                        }

                                        {isUserHaveAccess(authorities, ["ORDREVNT-V"]) &&
                                            <Link onClick={(e) => {
                                                e.stopPropagation();
                                            }} to="/order-events"
                                                  className={splitLocation[1] === "order-events" ? "active dropdown-item" : "dropdown-item"}>Order
                                                Events</Link>
                                        }


                                    </div>
                                </li>
                        ) : ''

                    }


                    {isUserHaveAccess(authorities, ["VPRC-V", "PTPRC-V", "PRIC-V", "PAYMT-V","FFPRC-V"]) ?

                        (active ?

                                <li className="nav-item dropdown">
                                    <a href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                                       aria-haspopup="true"
                                       aria-expanded="false" className="disabled navbarDropdown">
                                        <i className="fa fa-file-invoice-dollar"/><span
                                        className="menu_items">Accounting</span>
                                    </a>

                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                        {isUserHaveAccess(authorities, ["VPRC-V", "PTPRC-V", "PRIC-V","FFPRC-V"]) &&
                                            <Link onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                                  to="/accounting"
                                                  className={splitLocation[1] === "accounting" ? "active dropdown-item" : "dropdown-item"}>Product
                                                Pricing</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["PAYMT-V"]) &&
                                            <Link onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                                  to="/payments?deleted=false"
                                                  className={splitLocation[1] === "payments" ? "active dropdown-item" : "dropdown-item"}>Payments</Link>
                                        }


                                        {isUserHaveAccess(authorities, ["FNFTRANS-V"]) &&
                                            <Link onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                                  to="/fnf-transactions"
                                                  className={splitLocation[1] === "fnf-transactions" ? "active dropdown-item" : "dropdown-item"}>CRRAR Transactions</Link>
                                        }
                                    </div>
                                </li>

                                :

                                <li className="nav-item dropdown">
                                    <a href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                                       aria-haspopup="true" aria-expanded="false" className="navbarDropdown">
                                        <i className="fa fa-file-invoice-dollar"/><span
                                        className="menu_items">Accounting</span>
                                    </a>

                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown"
                                         id="accountingDropDown">

                                        {isUserHaveAccess(authorities, ["VPRC-V", "PTPRC-V", "PRIC-V","FFPRC-V"]) &&

                                            <Link onClick={(e) => {
                                                e.stopPropagation();
                                            }} to="/accounting"
                                                  className={splitLocation[1] === "accounting" ? "active dropdown-item" : "dropdown-item"}>Product
                                                Pricing</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["PAYMT-V"]) &&
                                            <Link onClick={(e) => {
                                                e.stopPropagation();
                                            }} to="/payments?deleted=false"
                                                  className={splitLocation[1] === "payments" ? "active dropdown-item" : "dropdown-item"}>Payments</Link>
                                        }


                                        {isUserHaveAccess(authorities, ["FNFTRANS-V"]) &&
                                            <Link onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                                  to="/fnf-transactions"
                                                  className={splitLocation[1] === "fnf-transactions" ? "active dropdown-item" : "dropdown-item"}>CRRAR Transactions</Link>
                                        }
                                        {isUserHaveAccess(authorities, ["REVEVT-V"]) &&
                                            <Link onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                                  to="/revenue-event"
                                                  className={splitLocation[1] === "revenue-event" ? "active dropdown-item" : "dropdown-item"}>Revenue
                                                Event</Link>
                                        }

                                    </div>
                                </li>
                        ) : ''

                    }

                    {
                        isUserHaveAccess(authorities, ["MGTCOM-V", "ASSOC-V", "AUTOHOA-V","HOATPP-V"]) ?

                            (active ?

                                    <li className="nav-item dropdown">

                                        <a href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                                           aria-haspopup="true"
                                           aria-expanded="false" className="disabled navbarDropdown">
                                            <i className="fa fa-handshake"/><span className="menu_items">HOA</span>
                                        </a>

                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                            {isUserHaveAccess(authorities, ["MGTCOM-V"]) &&
                                                < Link to="/management-companies"
                                                       onClick={(e) => e.stopPropagation()}
                                                       className={splitLocation[1] === "management-companies" ? "active dropdown-item" : "dropdown-item"}>Mgmt
                                                    Company</Link>
                                            }
                                            {isUserHaveAccess(authorities, ["ASSOC-V"]) &&
                                                <Link to="/associations?state=TX" onClick={(e) => e.stopPropagation()}
                                                      className={splitLocation[1] === "associations" ? "active dropdown-item" : "dropdown-item"}>Associations</Link>
                                            }
                                            {isUserHaveAccess(authorities, ["AUTOHOA-V"]) &&
                                                <Link to="/auto-hoa?state=TX" onClick={(e) => e.stopPropagation()}
                                                      className={splitLocation[1] === "auto-hoa" ? "active dropdown-item" : "dropdown-item"}>Auto
                                                    HOA</Link>
                                            }
                                            {isUserHaveAccess(authorities, ["FEESORT-V"]) &&
                                                <Link to="/hoa-fee-types" onClick={(e) => e.stopPropagation()}
                                                      className={splitLocation[1] === "hoa-fee-types" ? "active dropdown-item" : "dropdown-item"}>Fee Sorting
                                                    </Link>
                                            }
                                            {isUserHaveAccess(authorities, ["HOATPP-V"]) &&
                                                <Link to="/third-party-providers" onClick={(e) => e.stopPropagation()}
                                                      className={splitLocation[1] === "third-party-providers" ? "active dropdown-item" : "dropdown-item"}>Third Party Provider
                                                </Link>
                                            }
                                        </div>
                                    </li>

                                    :

                                    <li className="nav-item dropdown">

                                        <a href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                                           aria-haspopup="true" aria-expanded="false" className="navbarDropdown">
                                            <i className="fa fa-handshake"/><span className="menu_items">HOA</span>
                                        </a>

                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                            {isUserHaveAccess(authorities, ["MGTCOM-V"]) &&
                                                < Link to="/management-companies"
                                                       onClick={(e) => e.stopPropagation()}
                                                       className={splitLocation[1] === "management-companies" ? "active dropdown-item" : "dropdown-item"}>Mgmt
                                                    Companies</Link>
                                            }
                                            {isUserHaveAccess(authorities, ["ASSOC-V"]) &&
                                                < Link to="/associations?state=TX" onClick={(e) => e.stopPropagation()}
                                                       className={splitLocation[1] === "associations" ? "active dropdown-item" : "dropdown-item"}>Associations</Link>
                                            }

                                            {isUserHaveAccess(authorities, ["AUTOHOA-V"]) &&
                                                <Link to="/auto-hoa?state=TX" onClick={(e) => e.stopPropagation()}
                                                      className={splitLocation[1] === "auto-hoa" ? "active dropdown-item" : "dropdown-item"}>Auto
                                                    HOA</Link>
                                            }
                                            {isUserHaveAccess(authorities, ["FEESORT-V"]) &&
                                                <Link to="/hoa-fee-types" onClick={(e) => e.stopPropagation()}
                                                      className={splitLocation[1] === "hoa-fee-types" ? "active dropdown-item" : "dropdown-item"}>Fee Sorting
                                                    </Link>
                                            }

                                            {isUserHaveAccess(authorities, ["HOATPP-V"]) &&
                                                <Link to="/third-party-providers" onClick={(e) => e.stopPropagation()}
                                                      className={splitLocation[1] === "third-party-providers" ? "active dropdown-item" : "dropdown-item"}>Third Party Provider
                                                </Link>
                                            }

                                        </div>
                                    </li>
                            ) : ''

                    }


                    {isUserHaveAccess(authorities, ["COUN-V", "JURI-V", "STAT-V","STATCODE-V", "TAXR-V", "APRROL-V", "SUBD-V", "METR-V", "CANNOTE-V", "COLL-V", "COLLINK-V", "LINKACC-V", "BILLREL-V","TAXRCONF-V"]) &&
                        (active ?

                                <li className="nav-item dropdown">

                                    <a href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                                       aria-haspopup="true"
                                       aria-expanded="false" className="disabled navbarDropdown">
                                        <i className="fa fa-database"/><span className="menu_items">Data</span>
                                    </a>

                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">


                                        {isUserHaveAccess(authorities, ["CANNOTE-V"]) &&
                                            <Link to="/canned-notes" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "canned-notes" ? "active dropdown-item" : "dropdown-item"}>Canned
                                                Notes</Link>
                                        }

                                        {
                                            isUserHaveAccess(authorities, ["COLL-V"]) &&
                                            <Link to="/collectors" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "collectors" ? "active dropdown-item" : "dropdown-item"}>Collectors</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["COLLINK-V"]) &&
                                            <Link to="/collector-links" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "collector-links" ? "active dropdown-item" : "dropdown-item"}>Collector
                                                Links</Link>
                                        }

                                        {
                                            isUserHaveAccess(authorities, ["COUN-V"]) &&
                                            <Link to="/counties" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "counties" ? "active dropdown-item" : "dropdown-item"}>Counties</Link>
                                        }

                                        {
                                            isUserHaveAccess(authorities, ["BILLREL-V"]) &&
                                            <Link to="/bill-releases?state=TX" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "bill-releases" ? "active dropdown-item" : "dropdown-item"}>Bill
                                                Release</Link>
                                        }

                                        {
                                            isUserHaveAccess(authorities, ["JURI-V"]) &&
                                            <Link to="/jurisdictions?state=TX" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "jurisdictions" ? "active dropdown-item" : "dropdown-item"}>Jurisdictions</Link>
                                        }
                                        {
                                            isUserHaveAccess(authorities, ["STAT-V"]) &&
                                            <Link to="/states" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "states" ? "active dropdown-item" : "dropdown-item"}>States</Link>

                                        }
                                        {
                                            isUserHaveAccess(authorities, ["STATCODE-V"]) &&
                                            <Link to="/state-codes" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "state-codes" ? "active dropdown-item" : "dropdown-item"}>State Codes</Link>

                                        }

                                        {isUserHaveAccess(authorities, ["TAXR-V"]) &&
                                            <Link to="/tax-rolls" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "tax-rolls" ? "active dropdown-item" : "dropdown-item"}>Tax
                                                Rolls</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["APRROL-V"]) &&
                                            <Link to="/appraisal-rolls" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "appraisal-rolls" ? "active dropdown-item" : "dropdown-item"}>Appraisal
                                                Roll</Link>

                                        }

                                        {isUserHaveAccess(authorities, ["SUBD-V"]) &&
                                            < Link to="/subdivisions?state=TX" onClick={(e) => e.stopPropagation()}
                                                   className={splitLocation[1] === "subdivisions" ? "active dropdown-item" : "dropdown-item"}>Subdivisions</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["METR-V"]) &&
                                            < Link to="/metro-areas?stateName=TX" onClick={(e) => e.stopPropagation()}
                                                   className={splitLocation[1] === "metro-areas" ? "active dropdown-item" : "dropdown-item"}>Metro
                                                Areas</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["LINKACC-V"]) &&
                                            < Link to="/linked-accounts" onClick={(e) => e.stopPropagation()}
                                                   className={splitLocation[1] === "linked-accounts" ? "active dropdown-item" : "dropdown-item"}>Linked
                                                Accounts</Link>
                                        }
                                        {isUserHaveAccess(authorities, ["HRAV-V"]) &&
                                            < Link to="/high-risk-account-verified" onClick={(e) => e.stopPropagation()}
                                                   className={splitLocation[1] === "high-risk-account-verified" ? "active dropdown-item" : "dropdown-item"}>Verified High Risk Acc
                                                </Link>
                                        }
                                        {isUserHaveAccess(authorities, ["HRA-V"]) &&
                                            < Link to="/high-risk-account" onClick={(e) => e.stopPropagation()}
                                                   className={splitLocation[1] === "high-risk-account" ? "active dropdown-item" : "dropdown-item"}>High Risk Acc
                                            </Link>
                                        }

                                        {isUserHaveAccess(authorities, ["TAXRCONF-V"]) &&
                                            < Link to="/tax-rounding-configurations" onClick={(e) => e.stopPropagation()}
                                                   className={splitLocation[1] === "tax-rounding-configurations" ? "active dropdown-item" : "dropdown-item"}>Tax Rounding Config
                                            </Link>
                                        }

                                    </div>
                                </li>

                                :

                                <li className="nav-item dropdown">

                                    <a href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                                       aria-haspopup="true" aria-expanded="false" className="navbarDropdown">
                                        <i className="fa fa-database"/><span className="menu_items">Data</span>
                                    </a>

                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                        {isUserHaveAccess(authorities, ["CANNOTE-V"]) &&
                                            <Link to="/canned-notes" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "canned-notes" ? "active dropdown-item" : "dropdown-item"}>Canned
                                                Notes</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["COLL-V"]) &&
                                            <Link to="/collectors" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "collectors" ? "active dropdown-item" : "dropdown-item"}>Collectors</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["COLLINK-V"]) &&
                                            <Link to="/collector-links" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "collector-links" ? "active dropdown-item" : "dropdown-item"}>Collector
                                                Links</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["COUN-V"]) &&
                                            <Link to="/counties" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "counties" ? "active dropdown-item" : "dropdown-item"}>Counties</Link>
                                        }

                                        {
                                            isUserHaveAccess(authorities, ["BILLREL-V"]) &&
                                            <Link to="/bill-releases?state=TX" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "bill-releases" ? "active dropdown-item" : "dropdown-item"}>Bill
                                                Release</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["JURI-V"]) &&
                                            <Link to="/jurisdictions?state=TX" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "jurisdictions" ? "active dropdown-item" : "dropdown-item"}>Jurisdictions</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["STAT-V"]) &&
                                            <Link to="/states" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "states" ? "active dropdown-item" : "dropdown-item"}>States</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["STATCODE-V"]) &&
                                            <Link to="/state-codes" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "state-codes" ? "active dropdown-item" : "dropdown-item"}>State Codes</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["TAXR-V"]) &&
                                            <Link to="/tax-rolls" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "tax-rolls" ? "active dropdown-item" : "dropdown-item"}>Tax
                                                Rolls</Link>
                                        }


                                        {isUserHaveAccess(authorities, ["APRROL-V"]) &&
                                            <Link to="/appraisal-rolls" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "appraisal-rolls" ? "active dropdown-item" : "dropdown-item"}>Appraisal
                                                Rolls</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["SUBD-V"]) &&
                                            < Link to="/subdivisions?state=TX" onClick={(e) => e.stopPropagation()}
                                                   className={splitLocation[1] === "subdivisions" ? "active dropdown-item" : "dropdown-item"}>Subdivisions</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["METR-V"]) &&
                                            < Link to="/metro-areas?stateAbbreviation=TX" onClick={(e) => e.stopPropagation()}
                                                   className={splitLocation[1] === "metro-areas" ? "active dropdown-item" : "dropdown-item"}>Metro
                                                Areas</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["LINKACC-V"]) &&
                                            < Link to="/linked-accounts" onClick={(e) => e.stopPropagation()}
                                                   className={splitLocation[1] === "linked-accounts" ? "active dropdown-item" : "dropdown-item"}>Linked
                                                Accounts</Link>}
                                        {isUserHaveAccess(authorities, ["HRAV-V"]) &&
                                            < Link to="/high-risk-account-verified" onClick={(e) => e.stopPropagation()}
                                                   className={splitLocation[1] === "high-risk-account-verified" ? "active dropdown-item" : "dropdown-item"}>Verified High Risk Acc
                                                </Link>}
                                        {isUserHaveAccess(authorities, ["HRA-V"]) &&
                                            < Link to="/high-risk-account" onClick={(e) => e.stopPropagation()}
                                                   className={splitLocation[1] === "high-risk-account" ? "active dropdown-item" : "dropdown-item"}>High Risk Acc
                                            </Link>}

                                        {isUserHaveAccess(authorities, ["TAXRCONF-V"]) &&
                                            < Link to="/tax-rounding-configurations" onClick={(e) => e.stopPropagation()}
                                                   className={splitLocation[1] === "tax-rounding-configurations" ? "active dropdown-item" : "dropdown-item"}>Tax Rounding Config
                                            </Link>
                                        }

                                    </div>
                                </li>
                        )
                    }

                    {
                        isUserHaveAccess(authorities, ["LOGIC-V", "SCRMOD-V", "DATARETVAL-V", "FOLLUP-V"]) &&

                        (active ?

                                <li className="nav-item dropdown">

                                    <a href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                                       aria-haspopup="true"
                                       aria-expanded="false" className="disabled navbarDropdown">
                                        <i className="fa fa-user-gear"/><span
                                        className="menu_items">Tenant Admin</span>
                                    </a>

                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">


                                        {isUserHaveAccess(authorities, ["DATARETVAL-V"]) &&
                                            <Link to="/import-versions" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "import-versions" ? "active dropdown-item" : "dropdown-item"}>Import Versions</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["LOGIC-V"]) &&
                                            <Link to="/qc-logics" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "qc-logics" ? "active dropdown-item" : "dropdown-item"}>QC
                                                Logics</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["LOGIC-V"]) &&
                                            <Link to="/note-logics" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "note-logics" ? "active dropdown-item" : "dropdown-item"}>
                                                Note Logics</Link>
                                        }


                                        {isUserHaveAccess(authorities, ["SCRMOD-V"]) &&
                                            <Link to="/scrapers-modules" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "scrapers-modules" ? "active dropdown-item" : "dropdown-item"}>Scraper
                                                Modules</Link>
                                        }


                                        {isUserHaveAccess(authorities, ["FOLLUP-V"]) &&
                                            <Link to="/follow-up-types" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "follow-up-types" ? "active dropdown-item" : "dropdown-item"}>Follow
                                                Up Types</Link>}

                                        {isUserHaveAccess(authorities, ["AUDIT-V"]) &&
                                            <Link to="/record-audit" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "record-audit" ? "active dropdown-item" : "dropdown-item"}>Record
                                                Audit</Link>
                                        }


                                    </div>
                                </li>

                                :

                                <li className="nav-item dropdown">

                                    <a href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                                       aria-haspopup="true" aria-expanded="false" className="navbarDropdown">
                                        <i className="fa fa-user-gear"/><span
                                        className="menu_items">Tenant Admin</span>
                                    </a>


                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                        {
                                            isUserHaveAccess(authorities, ["DATARETVAL-V"]) &&
                                            <Link to="/import-versions" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "import-versions" ? "active dropdown-item" : "dropdown-item"}>Import Versions</Link>
                                        }

                                        {
                                            isUserHaveAccess(authorities, ["LOGIC-V"]) &&
                                            <Link to="/qc-logics" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "qc-logics" ? "active dropdown-item" : "dropdown-item"}>QC
                                                Logics</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["LOGIC-V"]) &&
                                            <Link to="/note-logics" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "note-logics" ? "active dropdown-item" : "dropdown-item"}>
                                                Note Logics</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["SCRMOD-V"]) &&
                                            <Link to="/scrapers-modules" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "scrapers-modules" ? "active dropdown-item" : "dropdown-item"}>Scraper
                                                Modules</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["FOLLUP-V"]) &&
                                            <Link to="/follow-up-types" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "follow-up-types" ? "active dropdown-item" : "dropdown-item"}>Follow
                                                Up Types</Link>}
                                        {isUserHaveAccess(authorities, ["AUDIT-V"]) &&
                                            <Link to="/record-audit" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "record-audit" ? "active dropdown-item" : "dropdown-item"}>Record
                                                Audit</Link>
                                        }
                                    </div>

                                </li>
                        )
                    }

                    {isUserHaveAccess(authorities, ["SPRO-V", "ERRLOG-V", "SCRMOD-V", "CODES-V", "ACCTRA-V"]) &&

                        (active ?

                                <li className="nav-item dropdown system_admin">

                                    <a href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                                       aria-haspopup="true"
                                       aria-expanded="false" className="disabled navbarDropdown">
                                        <i className="fa fa-user-lock"/><span
                                        className="menu_items">System Admin</span>
                                    </a>

                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                        {
                                            isUserHaveAccess(authorities, ["SPRO-V"]) && storageData?.items?.customerLevel === 'SYS' &&
                                            <Link to="/system-properties" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "system-properties" ? "active dropdown-item" : "dropdown-item"}>System
                                                Properties</Link>
                                        }

                                        {
                                            isUserHaveAccess(authorities, ["ERRLOG-V"]) &&
                                            <Link to="/error-logs" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "error-logs" ? "active dropdown-item" : "dropdown-item"}>Error
                                                Logs</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["WORKER-V"]) &&
                                            <Link to="/registered-scrapers" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "registered-scrapers" ? "active dropdown-item" : "dropdown-item"}>
                                                Registered Scrapers</Link>
                                        }


                                        {isUserHaveAccess(authorities, ["CODES-V"]) &&
                                            <Link to="/codes" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "codes" ? "active dropdown-item" : "dropdown-item"}>Codes</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["ACCTRA-V"]) &&
                                            <Link to="/account-translations" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "account-translations" ? "active dropdown-item" : "dropdown-item"}>Account
                                                Translations</Link>
                                        }

                                    </div>
                                </li>

                                :

                                <li className="nav-item dropdown system_admin">

                                    <a href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                                       aria-haspopup="true" aria-expanded="false" className="navbarDropdown">
                                        <i className="fa fa-user-lock"/><span
                                        className="menu_items">System Admin</span>
                                    </a>

                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                        {
                                            isUserHaveAccess(authorities, ["SPRO-V"]) && storageData?.items?.customerLevel === 'SYS' &&
                                            <Link to="/system-properties" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "system-properties" ? "active dropdown-item" : "dropdown-item"}>System
                                                Properties</Link>
                                        }

                                        {
                                            isUserHaveAccess(authorities, ["ERRLOG-V"]) &&
                                            <Link to="/error-logs" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "error-logs" ? "active dropdown-item" : "dropdown-item"}>Error
                                                Logs</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["SCRMOD-V"]) &&
                                            <Link to="/registered-scrapers" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "registered-scrapers" ? "active dropdown-item" : "dropdown-item"}>
                                                Registered Scrapers</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["CODES-V"]) &&
                                            <Link to="/codes" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "codes" ? "active dropdown-item" : "dropdown-item"}>Codes</Link>
                                        }

                                        {isUserHaveAccess(authorities, ["ACCTRA-V"]) &&
                                            <Link to="/account-translations" onClick={(e) => e.stopPropagation()}
                                                  className={splitLocation[1] === "account-translations" ? "active dropdown-item" : "dropdown-item"}>Account
                                                Translations</Link>
                                        }

                                    </div>
                                </li>
                        )

                    }


                    <li className="toggle_btn">
                        <button onClick={toggleClass} className="navbar-brand navbar_toggle_btn"
                                id="sidebar-toggle"><i className="fa fa-angle-double-left"
                                                       aria-hidden="true"/></button>
                    </li>
                </ul>
            </aside>


        </div>

    );
}

export default Navbar;
