import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showErrorToast, showSuccessToast} from "../../../common/components/Toast";
import {alertActions} from "../../alert/alert";
import axiosInstance from "../../../axiosInstance";


export const sortHoaFeeTypeActions = {
    getAllData,
    updateFeeTypeSortOrderAction,

};


export const sortHoaFeeTypeConstant = {
    GET_ALL_FEE_TYPE_REQUEST: 'GET_ALL_FEE_TYPE_REQUEST',
    GET_ALL_FEE_TYPE_SUCCESS: 'GET_ALL_FEE_TYPE_SUCCESS',
    GET_ALL_FEE_TYPE_FAILURE: 'GET_ALL_FEE_TYPE_FAILURE',

    UPDATE_FEE_TYPE_ORDER_REQUEST: 'UPDATE_FEE_TYPE_ORDER_REQUEST',
    UPDATE_FEE_TYPE_ORDER_SUCCESS: 'UPDATE_FEE_TYPE_ORDER_SUCCESS',
    UPDATE_FEE_TYPE_ORDER_FAILURE: 'UPDATE_FEE_TYPE_ORDER_FAILURE',


}


export const sortHoaFeeTypeService = {
    getAllFeeTypesList,
    updateFeeTypeSortOrder,
}


/******************************** Management Company Actions Starts here  ****************************************/

function updateFeeTypeSortOrderAction(listOfIds) {
    return dispatch => {
        dispatch(request());
        sortHoaFeeTypeService.updateFeeTypeSortOrder(listOfIds)
            .then(
                feeTypes => {
                    if (feeTypes['errorCode'] === 200) {
                        showSuccessToast("Fee Type order updated successfully!");
                        dispatch(success(feeTypes));
                    } else {
                        responseDTOExceptionHandler(feeTypes);
                    }
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: sortHoaFeeTypeConstant.UPDATE_FEE_TYPE_ORDER_REQUEST}
    }

    function success(feeTypes) {
        return {type: sortHoaFeeTypeConstant.UPDATE_FEE_TYPE_ORDER_SUCCESS, feeTypes}
    }

    function failure(error) {
        return {type: sortHoaFeeTypeConstant.UPDATE_FEE_TYPE_ORDER_FAILURE, error}
    }

}


function getAllData() {
    return dispatch => {
        dispatch(request());
        sortHoaFeeTypeService.getAllFeeTypesList()
            .then(
                feeTypes => dispatch(success(feeTypes)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: sortHoaFeeTypeConstant.GET_ALL_FEE_TYPE_REQUEST}
    }

    function success(feeTypes) {
        return {type: sortHoaFeeTypeConstant.GET_ALL_FEE_TYPE_SUCCESS, feeTypes}
    }

    function failure(error) {
        return {type: sortHoaFeeTypeConstant.GET_ALL_FEE_TYPE_FAILURE, error}
    }

}




/******************************** Fee Type  Actions ENDS here  ****************************************/


/************************** Reducers starts here. **************************************/
export function getAllFeeTypesReducer(state = {}, action) {
    switch (action.type) {
        case sortHoaFeeTypeConstant.GET_ALL_FEE_TYPE_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case sortHoaFeeTypeConstant.GET_ALL_FEE_TYPE_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.feeTypes
            };
        case sortHoaFeeTypeConstant.GET_ALL_FEE_TYPE_FAILURE:
            return {};
        default:
            return state
    }

}




/************************** Reducers ends here. **************************************/


/****************************** Fee Types Services starts here  ****************************************/
export function updateFeeTypeSortOrder( listIds) {
    return axiosInstance.put(`/ajax/update-hoa-fee-type-order`, listIds).then(
        (response) => {
            return exceptionHandler(response);
        })
}



export function getAllFeeTypesList() {
    return axiosInstance.get(`/ajax/get-all-fee-types`).then(
        (response) => {
            return exceptionHandler(response);
        })
}




/****************************** Management Company Services ends here  ****************************************/




