import exceptionHandler, {responseDTOExceptionHandler} from "../../common/helpers/exceptionHandler";
import {showSuccessToast} from "../../common/components/Toast";
import {alertActions} from "../alert/alert";
import {serialize} from "../../common/helpers/utils";
import axiosInstance from "../../axiosInstance";


export const customerResWareActions = {
    getAllResWareCredentialData,
    createResWareCredentialAction,
    getResWareCredentialByIdAction,
    updateResWareCredentialAction,
    deleteResWareCredByIdAction,
    getAllResWareTypeMapData,
    createResWareTypeMapAction,
    getResWareTypeMapByIdAction,
    updateResWareTypeMapAction,
    deleteResWareTypeMapByIdAction


}

export const customerResWareCredentialConstant = {
    GET_ALL_RESWARE_CREDENTIALS_REQUEST: 'GET_ALL_RESWARE_CREDENTIALS_REQUEST',
    GET_ALL_RESWARE_CREDENTIALS_SUCCESS: 'GET_ALL_RESWARE_CREDENTIALS_SUCCESS',
    GET_ALL_RESWARE_CREDENTIALS_FAILURE: 'GET_ALL_RESWARE_CREDENTIALS_FAILURE',

    CREATE_RESWARE_CREDENTIAL_REQUEST:'CREATE_RESWARE_CREDENTIAL_REQUEST',
    CREATE_RESWARE_CREDENTIAL_SUCCESS:'CREATE_RESWARE_CREDENTIAL_SUCCESS',
    CREATE_RESWARE_CREDENTIAL_FAILURE:'CREATE_RESWARE_CREDENTIAL_FAILURE',

    GET_RESWARE_CREDENTIAL_BY_ID_REQUEST: 'GET_RESWARE_CREDENTIAL_BY_ID_REQUEST',
    GET_RESWARE_CREDENTIAL_BY_ID_SUCCESS: 'GET_RESWARE_CREDENTIAL_BY_ID_SUCCESS',
    GET_RESWARE_CREDENTIAL_BY_ID_FAILURE: 'GET_RESWARE_CREDENTIAL_BY_ID_FAILURE',

    UPDATE_RESWARE_CREDENTIAL_REQUEST:'UPDATE_RESWARE_CREDENTIAL_REQUEST',
    UPDATE_RESWARE_CREDENTIAL_SUCCESS:'UPDATE_RESWARE_CREDENTIAL_SUCCESS',
    UPDATE_RESWARE_CREDENTIAL_FAILURE:'UPDATE_RESWARE_CREDENTIAL_FAILURE',

    DELETE_RESWARE_CREDENTIAL_BY_ID_REQUEST:'DELETE_RESWARE_CREDENTIAL_BY_ID_REQUEST',
    DELETE_RESWARE_CREDENTIAL_BY_ID_SUCCESS:'DELETE_RESWARE_CREDENTIAL_BY_ID_SUCCESS',
    DELETE_RESWARE_CREDENTIAL_BY_ID_FAILURE:'DELETE_RESWARE_CREDENTIAL_BY_ID_FAILURE',

    GET_ALL_RESWARE_TYPE_MAP_REQUEST: 'GET_ALL_RESWARE_TYPE_MAP_REQUEST',
    GET_ALL_RESWARE_TYPE_MAP_SUCCESS: 'GET_ALL_RESWARE_TYPE_MAP_SUCCESS',
    GET_ALL_RESWARE_TYPE_MAP_FAILURE: 'GET_ALL_RESWARE_TYPE_MAP_FAILURE',

    CREATE_RESWARE_TYPE_MAP_REQUEST:'CREATE_RESWARE_TYPE_MAP_REQUEST',
    CREATE_RESWARE_TYPE_MAP_SUCCESS:'CREATE_RESWARE_TYPE_MAP_SUCCESS',
    CREATE_RESWARE_TYPE_MAP_FAILURE:'CREATE_RESWARE_TYPE_MAP_FAILURE',

    GET_RESWARE_TYPE_MAP_BY_ID_REQUEST: 'GET_RESWARE_TYPE_MAP_BY_ID_REQUEST',
    GET_RESWARE_TYPE_MAP_BY_ID_SUCCESS: 'GET_RESWARE_TYPE_MAP_BY_ID_SUCCESS',
    GET_RESWARE_TYPE_MAP_BY_ID_FAILURE: 'GET_RESWARE_TYPE_MAP_BY_ID_FAILURE',

    UPDATE_RESWARE_TYPE_MAP_REQUEST:'UPDATE_RESWARE_TYPE_MAP_REQUEST',
    UPDATE_RESWARE_TYPE_MAP_SUCCESS:'UPDATE_RESWARE_TYPE_MAP_SUCCESS',
    UPDATE_RESWARE_TYPE_MAP_FAILURE:'UPDATE_RESWARE_TYPE_MAP_FAILURE',

    DELETE_RESWARE_TYPE_MAP_BY_ID_REQUEST:'DELETE_RESWARE_TYPE_MAP_BY_ID_REQUEST',
    DELETE_RESWARE_TYPE_MAP_BY_ID_SUCCESS:'DELETE_RESWARE_TYPE_MAP_BY_ID_SUCCESS',
    DELETE_RESWARE_TYPE_MAP_BY_ID_FAILURE:'DELETE_RESWARE_TYPE_MAP_BY_ID_FAILURE',



}

//export service
export const customerResWareService = {
    getAllResWareCredential,
    submitResWareCredential,
    getResWareCredentialById,
    updateResWareCredential,
    deleteResWareCredById,
    getAllResWareTypeMap,
    createResWareTypeMap,
    getResWareTypeMapById,
    updateResWareTypeMap,
    deleteResWareTypeMapById


}

function getAllResWareCredentialData(query) {
    return dispatch => {
        dispatch(request());

        customerResWareService.getAllResWareCredential(query)
            .then(
                resWareCredentials => dispatch(success(resWareCredentials)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: customerResWareCredentialConstant.GET_ALL_RESWARE_CREDENTIALS_REQUEST}
    }

    function success(resWareCredentials) {
        return {type: customerResWareCredentialConstant.GET_ALL_RESWARE_CREDENTIALS_SUCCESS, resWareCredentials}
    }

    function failure(error) {
        return {type: customerResWareCredentialConstant.GET_ALL_RESWARE_CREDENTIALS_FAILURE, error}
    }

}


//create resware credentials  Action
function createResWareCredentialAction(obj, setDisable, setShow) {
    return dispatch => {
        dispatch(request({obj}));
        customerResWareService.submitResWareCredential(obj.customerId,obj)
            .then(
                resWareCredential => {
                    if (resWareCredential['errorCode'] === 200) {
                        showSuccessToast("Resware Credential Added Successfully!");
                        dispatch(success(resWareCredential));
                            setShow(false)
                            dispatch(getAllResWareCredentialData({customerId: obj.customerId}))
                    } else {
                        responseDTOExceptionHandler(resWareCredential);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(resWareCredential) {
        return {type: customerResWareCredentialConstant.CREATE_RESWARE_CREDENTIAL_REQUEST, resWareCredential}
    }

    function success(resWareCredential) {
        return {type: customerResWareCredentialConstant.CREATE_RESWARE_CREDENTIAL_SUCCESS, resWareCredential}
    }

    function failure(error) {
        return {type: customerResWareCredentialConstant.CREATE_RESWARE_CREDENTIAL_FAILURE, error}
    }
}



//get resWare cred by id
function getResWareCredentialByIdAction(customerId,id) {
    return dispatch => {
        dispatch(request());
        customerResWareService.getResWareCredentialById(customerId,id)
            .then(
                resWareCred => dispatch(success(resWareCred)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: customerResWareCredentialConstant.GET_RESWARE_CREDENTIAL_BY_ID_REQUEST}
    }

    function success(resWareCred) {
        return {type: customerResWareCredentialConstant.GET_RESWARE_CREDENTIAL_BY_ID_SUCCESS, resWareCred}
    }

    function failure(error) {
        return {type: customerResWareCredentialConstant.GET_RESWARE_CREDENTIAL_BY_ID_FAILURE, error}
    }
}



//update resware Cred action
function updateResWareCredentialAction(obj, id, setDisable, setShow) {
    return dispatch => {
        dispatch(request({obj}));
        customerResWareService.updateResWareCredential(obj, id)
            .then(
                resWareCred => {
                    if (resWareCred['errorCode'] === 200) {
                        showSuccessToast("Resware Credential updated successfully!");
                        dispatch(success(resWareCred));
                            setShow(false)
                            dispatch(getAllResWareCredentialData({customerId: obj.customerId}))
                        setDisable(false);
                    } else {
                        responseDTOExceptionHandler(resWareCred);
                        setDisable(false);
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(resWareCred) {
        return {type: customerResWareCredentialConstant.UPDATE_RESWARE_CREDENTIAL_REQUEST, resWareCred}
    }

    function success(resWareCred) {
        return {type: customerResWareCredentialConstant.UPDATE_RESWARE_CREDENTIAL_SUCCESS, resWareCred}
    }

    function failure(error) {
        return {type: customerResWareCredentialConstant.UPDATE_RESWARE_CREDENTIAL_FAILURE, error}
    }
}


//delete resWare Cred action
function deleteResWareCredByIdAction(customerId,resWareCredId, setDisable, setShow) {
    return dispatch => {
        dispatch(request({resWareCredId}));
        customerResWareService.deleteResWareCredById(customerId, resWareCredId)
            .then(
                resWareCred => {
                    if (resWareCred['errorCode'] === 200) {
                        showSuccessToast("Resware Credential deleted successfully!");
                        dispatch(success(resWareCred));
                        setShow(false)
                        dispatch(getAllResWareCredentialData({customerId: customerId}))
                        setDisable(false);
                    } else {
                        responseDTOExceptionHandler(resWareCred);
                        setDisable(false);
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(resWareCredId) {
        return {type: customerResWareCredentialConstant.DELETE_RESWARE_CREDENTIAL_BY_ID_REQUEST, resWareCredId}
    }

    function success(resWareCred) {
        return {type: customerResWareCredentialConstant.DELETE_RESWARE_CREDENTIAL_BY_ID_SUCCESS, resWareCred}
    }

    function failure(error) {
        return {type: customerResWareCredentialConstant.DELETE_RESWARE_CREDENTIAL_BY_ID_FAILURE, error}
    }
}




function getAllResWareTypeMapData(query) {
    return dispatch => {
        dispatch(request());

        customerResWareService.getAllResWareTypeMap(query)
            .then(
                resWareTypeMap => dispatch(success(resWareTypeMap)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: customerResWareCredentialConstant.GET_ALL_RESWARE_TYPE_MAP_REQUEST}
    }

    function success(resWareTypeMap) {
        return {type: customerResWareCredentialConstant.GET_ALL_RESWARE_TYPE_MAP_SUCCESS, resWareTypeMap}
    }

    function failure(error) {
        return {type: customerResWareCredentialConstant.GET_ALL_RESWARE_TYPE_MAP_FAILURE, error}
    }

}


//create resware type map  Action
function createResWareTypeMapAction(obj, setDisable, setShow) {
    return dispatch => {
        dispatch(request({obj}));
        customerResWareService.createResWareTypeMap(obj.customerId,obj)
            .then(
                resWareTypeMap => {
                    if (resWareTypeMap['errorCode'] === 200) {
                        showSuccessToast("Resware Type Map Added Successfully!");
                        dispatch(success(resWareTypeMap));
                        setShow(false)
                        dispatch(getAllResWareTypeMapData({customerId: obj.customerId}))
                    } else {
                        responseDTOExceptionHandler(resWareTypeMap);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(resWareTypeMap) {
        return {type: customerResWareCredentialConstant.CREATE_RESWARE_CREDENTIAL_REQUEST, resWareTypeMap}
    }

    function success(resWareTypeMap) {
        return {type: customerResWareCredentialConstant.CREATE_RESWARE_CREDENTIAL_SUCCESS, resWareTypeMap}
    }

    function failure(error) {
        return {type: customerResWareCredentialConstant.CREATE_RESWARE_CREDENTIAL_FAILURE, error}
    }
}



//get resWare cred by id
function getResWareTypeMapByIdAction(customerId,id) {
    return dispatch => {
        dispatch(request());
        customerResWareService.getResWareTypeMapById(customerId,id)
            .then(
                resWareTypeMap => dispatch(success(resWareTypeMap)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: customerResWareCredentialConstant.GET_RESWARE_TYPE_MAP_BY_ID_REQUEST}
    }

    function success(resWareTypeMap) {
        return {type: customerResWareCredentialConstant.GET_RESWARE_TYPE_MAP_BY_ID_SUCCESS, resWareTypeMap}
    }

    function failure(error) {
        return {type: customerResWareCredentialConstant.GET_RESWARE_TYPE_MAP_BY_ID_FAILURE, error}
    }
}


//update resware Cred action
function updateResWareTypeMapAction(obj, id, setDisable, setShow) {
    return dispatch => {
        dispatch(request({obj}));
        customerResWareService.updateResWareTypeMap(obj, id)
            .then(
                resWareCred => {
                    if (resWareCred['errorCode'] === 200) {
                        showSuccessToast("Resware Type Map updated successfully!");
                        dispatch(success(resWareCred));
                        setShow(false)
                        dispatch(getAllResWareTypeMapData({customerId: obj.customerId}))
                        setDisable(false);
                    } else {
                        responseDTOExceptionHandler(resWareCred);
                        setDisable(false);
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(resWareCred) {
        return {type: customerResWareCredentialConstant.UPDATE_RESWARE_TYPE_MAP_REQUEST, resWareCred}
    }

    function success(resWareCred) {
        return {type: customerResWareCredentialConstant.UPDATE_RESWARE_TYPE_MAP_SUCCESS, resWareCred}
    }

    function failure(error) {
        return {type: customerResWareCredentialConstant.UPDATE_RESWARE_TYPE_MAP_FAILURE, error}
    }
}




//update resware Type Map action
function deleteResWareTypeMapByIdAction(customerId, id, setDisable, setShow) {
    return dispatch => {
        dispatch(request({customerId}));
        customerResWareService.deleteResWareTypeMapById(customerId, id)
            .then(
                resWareType => {
                    if (resWareType['errorCode'] === 200) {
                        showSuccessToast("Resware Type Map deleted successfully!");
                        dispatch(success(resWareType));
                        setShow(false)
                        dispatch(getAllResWareTypeMapData({customerId: customerId}))
                        setDisable(false);
                    } else {
                        responseDTOExceptionHandler(resWareType);
                        setDisable(false);
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(customerId) {
        return {type: customerResWareCredentialConstant.DELETE_RESWARE_TYPE_MAP_BY_ID_REQUEST, customerId}
    }

    function success(resWareType) {
        return {type: customerResWareCredentialConstant.DELETE_RESWARE_TYPE_MAP_BY_ID_SUCCESS, resWareType}
    }

    function failure(error) {
        return {type: customerResWareCredentialConstant.DELETE_RESWARE_TYPE_MAP_BY_ID_FAILURE, error}
    }
}


/************ Reducers started here. *******************/

//resware credentials list reducer
export function resWareCredentialReducers(state = {}, action) {
    switch (action.type) {
        case customerResWareCredentialConstant.GET_ALL_RESWARE_CREDENTIALS_REQUEST:
            return {
                loading: true,
                loggingIn: true,
            };
        case customerResWareCredentialConstant.GET_ALL_RESWARE_CREDENTIALS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.resWareCredentials
            };
        case customerResWareCredentialConstant.GET_ALL_RESWARE_CREDENTIALS_FAILURE:
            return {};
        default:
            return state
    }
}



//resware credentials  by id  reducer
export function resWareCredentialByIdReducers(state = {}, action) {
    switch (action.type) {
        case customerResWareCredentialConstant.GET_RESWARE_CREDENTIAL_BY_ID_REQUEST:
            return {
                loading: true,
                loggingIn: true,
            };
        case customerResWareCredentialConstant.GET_RESWARE_CREDENTIAL_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.resWareCred
            };
        case customerResWareCredentialConstant.GET_RESWARE_CREDENTIAL_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}




//resware credentials list reducer
export function resWareTypeMapReducers(state = {}, action) {
    switch (action.type) {
        case customerResWareCredentialConstant.GET_ALL_RESWARE_TYPE_MAP_REQUEST:
            return {
                loading: true,
                loggingIn: true,
            };
        case customerResWareCredentialConstant.GET_ALL_RESWARE_TYPE_MAP_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.resWareTypeMap
            };
        case customerResWareCredentialConstant.GET_ALL_RESWARE_TYPE_MAP_FAILURE:
            return {};
        default:
            return state
    }
}


//resware credentials  by id  reducer
export function resWareTypeMapByIdReducers(state = {}, action) {
    switch (action.type) {
        case customerResWareCredentialConstant.GET_RESWARE_TYPE_MAP_BY_ID_REQUEST:
            return {
                loading: true,
                loggingIn: true,
            };
        case customerResWareCredentialConstant.GET_RESWARE_TYPE_MAP_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.resWareTypeMap
            };
        case customerResWareCredentialConstant.GET_RESWARE_TYPE_MAP_BY_ID_FAILURE:
            return {};
        default:
            return state
    }

}







/**************** Reducers ends here. ***************************/

export async  function getAllResWareCredential(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/get-all-resWare-Credentials?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export async function submitResWareCredential(customerId,data) {
    return axiosInstance.post(`/ajax/create/resWare-Credential/${customerId}`, data).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error)
    })
}

export async function getResWareCredentialById(customerId,id){
    return axiosInstance.get(`/ajax/get-resWare-Credentials-by-id/${customerId}/${id}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export function updateResWareCredential(obj, id) {
    return axiosInstance.put(`/ajax/update/resware-credential/${id}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function deleteResWareCredById(customerId,id){
    return axiosInstance.delete(`/ajax/delete/resWare-Credential/${customerId}/${id}`).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error)
    })
}


export async  function getAllResWareTypeMap(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/get-all-resWare-type-map?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export async function createResWareTypeMap(customerId,data) {
    return axiosInstance.post(`/ajax/create/resWare-type-map/${customerId}`, data).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error)
    })
}

export async function getResWareTypeMapById(customerId,id){
    return axiosInstance.get(`/ajax/get-resWare-type-map-by-id/${customerId}/${id}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export function updateResWareTypeMap(obj, id) {
    return axiosInstance.put(`/ajax/update/resware-type-map/${id}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


export function deleteResWareTypeMapById(customerId,id){
    return axiosInstance.delete(`/ajax/delete/resWare-type-map/${customerId}/${id}`).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error)
    })
}



