import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {alertActions} from "../../alert/alert";
import {showSuccessToast} from "../../../common/components/Toast";
import {serialize} from "../../../common/helpers/utils";
import axiosInstance from "../../../axiosInstance";

//export actions
export const memberActions = {
    getAllData,
    addMemberAction,
    deleteMemberAction

}


//export constants
export const memberConstants = {
    GET_ALL_MEMBER_REQUEST: 'GET_ALL_MEMBER_REQUEST',
    GET_ALL_MEMBER_SUCCESS: 'GET_ALL_MEMBER_SUCCESS',
    GET_ALL_MEMBER_FAILURE: 'GET_ALL_MEMBER_FAILURE',

    ADD_MEMBER_REQUEST: 'ADD_MEMBER_REQUEST',
    ADD_TEAM_RULE_SUCCESS: 'ADD_TEAM_RULE_SUCCESS',
    ADD_TEAM_RULE_FAILURE: 'ADD_TEAM_RULE_FAILURE',

    DELETE_MEMBER_REQUEST: 'DELETE_TEAM_RULE_REQUEST',
    DELETE_TEAM_RULE_SUCCESS: 'DELETE_TEAM_RULE_SUCCESS',
    DELETE_TEAM_RULE_FAILURE: 'DELETE_TEAM_RULE_FAILURE',

}


//export service
export const memberService = {
    getAllMember,
    addTeamMember,
    deleteMember

};

{/*********** Actions started here. ******************/
}


//get all users-members Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());
        memberService.getAllMember(query)
            .then(
                members => dispatch(success(members)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: memberConstants.GET_ALL_MEMBER_REQUEST}
    }

    function success(members) {
        return {type: memberConstants.GET_ALL_MEMBER_SUCCESS, members}
    }

    function failure(error) {
        return {type: memberConstants.GET_ALL_MEMBER_FAILURE, error}
    }
}


//ADD Team Member Action
function addMemberAction(userId, teamId, setDisable, customFilter, setShow) {
    return dispatch => {
        dispatch(request({teamId}));
        memberService.addTeamMember(userId, teamId)
            .then(
                teamMember => {
                    if (teamMember['errorCode'] === 200) {
                        showSuccessToast("Team Member added successfully!");
                        dispatch(success(teamMember));
                        dispatch(getAllData(customFilter));
                        setShow(false);
                    } else {
                        responseDTOExceptionHandler(teamMember);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    setDisable(false);
                }
            );
    };

    function request(teamName) {
        return {type: memberConstants.ADD_MEMBER_REQUEST, teamName}
    }

    function success(teamMember) {
        return {type: memberConstants.ADD_TEAM_RULE_SUCCESS, teamMember}
    }

    function failure(error) {
        return {type: memberConstants.ADD_TEAM_RULE_FAILURE, error}
    }
}


// delete Team Rule Action
function deleteMemberAction(userId, teamId, setDisable, setShowConfirmation, customFilter) {
    return dispatch => {
        dispatch(request({teamId}));
        memberService.deleteMember(userId, teamId)
            .then(
                teamMember => {
                    if (teamMember['errorCode'] === 200) {
                        showSuccessToast("Team Member deleted successfully!");
                        dispatch(success(teamMember));
                        dispatch(getAllData(customFilter));
                    } else {
                        responseDTOExceptionHandler(teamMember);
                    }
                    setDisable(false);
                    setShowConfirmation(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: memberConstants.DELETE_MEMBER_REQUEST}
    }

    function success(teamMember) {
        return {type: memberConstants.DELETE_TEAM_RULE_SUCCESS, teamMember}
    }

    function failure(error) {
        return {type: memberConstants.DELETE_TEAM_RULE_FAILURE, error}
    }
}


/*********** Actions ended here. ******************/


/*********** Reducers started here. ******************/

export function getAllMemberReducer(state = {}, action) {
    switch (action.type) {
        case memberConstants.GET_ALL_MEMBER_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case memberConstants.GET_ALL_MEMBER_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.members
            };
        case memberConstants.GET_ALL_MEMBER_FAILURE:
            return {};
        default:
            return state
    }
}


/*********** Reducers ended here. ******************/


/*********** Services started here. ******************/

//get teamRuleMember by team id service
export function getAllMember(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/get-team-member?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

// add new team member
export function addTeamMember(userId, teamId) {
    return axiosInstance.post(`/ajax/add/team/member/${userId}/${teamId}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            console.log(error)
        })
}


// //delete team member by userId and teamName
export function deleteMember(userId, teamId) {
    return axiosInstance.delete(`/ajax/delete-team-member-by-id/${userId}/${teamId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}




/*********** Services ended here. ******************/


