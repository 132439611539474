import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";
import axiosInstance from "../../../axiosInstance";

export const accountTransAction = {
    getAllData,
    submitAccountTransAction,
    updateAccountTransAction,
    getAccountTranslationByIdAction,
    exportData,
    deleteAccountTranslationByIdAction

}

export const accountTransService = {
    getAllTrans,
    submitAccountTrans,
    updateAccountTrans,
    getAccountTranslationById,
    exportAccountTransData,
    deleteAccountTranslationById
}

export const accountTransConstant = {
    GET_ALL_ACCOUNT_TRANSACTION_REQUEST: 'GET_ALL_ACCOUNT_TRANSACTION_REQUEST',
    GET_ALL_ACCOUNT_TRANSACTION_SUCCESS: 'GET_ALL_ACCOUNT_TRANSACTION_SUCCESS',
    GET_ALL_ACCOUNT_TRANSACTION_FAILURE: 'GET_ALL_ACCOUNT_TRANSACTION_FAILURE',

    CREATE_ACCOUNT_TRANSACTION_REQUEST: 'CREATE_ACCOUNT_TRANSACTION_REQUEST',
    CREATE_ACCOUNT_TRANSACTION_SUCCESS: 'CREATE_ACCOUNT_TRANSACTION_SUCCESS',
    CREATE_ACCOUNT_TRANSACTION_FAILURE: 'CREATE_ACCOUNT_TRANSACTION_FAILURE',

    UPDATE_ACCOUNT_TRANSACTION_REQUEST: 'UPDATE_ACCOUNT_TRANSACTION_REQUEST',
    UPDATE_ACCOUNT_TRANSACTION_SUCCESS: 'UPDATE_ACCOUNT_TRANSACTION_SUCCESS',
    UPDATE_ACCOUNT_TRANSACTION_FAILURE: 'UPDATE_ACCOUNT_TRANSACTION_FAILURE',


    GET_ACCOUNT_TRANSACTION_BY_ID_REQUEST: 'GET_ACCOUNT_TRANSACTION_BY_ID_REQUEST',
    GET_ACCOUNT_TRANSACTION_BY_ID_SUCCESS: 'GET_ACCOUNT_TRANSACTION_BY_ID_SUCCESS',
    GET_ACCOUNT_TRANSACTION_BY_ID_FAILURE: 'GET_ACCOUNT_TRANSACTION_BY_ID_FAILURE',

    EXPORT_ACCOUNT_TRANSACTION_REQUEST: 'EXPORT_ACCOUNT_TRANSACTION_REQUEST',
    EXPORT_ACCOUNT_TRANSACTION_SUCCESS: 'EXPORT_ACCOUNT_TRANSACTION_SUCCESS',
    EXPORT_ACCOUNT_TRANSACTION_FAILURE: 'EXPORT_ACCOUNT_TRANSACTION_FAILURE',


    DELETE_ACCOUNT_TRANSACTION_REQUEST: 'DELETE_ACCOUNT_TRANSACTION_REQUEST',
    DELETE_ACCOUNT_TRANSACTION_SUCCESS: 'DELETE_ACCOUNT_TRANSACTION_SUCCESS',
    DELETE_ACCOUNT_TRANSACTION_FAILURE: 'DELETE_ACCOUNT_TRANSACTION_FAILURE',


}


function getAllData(query) {
    return dispatch => {
        dispatch(request());
        accountTransService.getAllTrans(query)
            .then(
                transaction => dispatch(success(transaction)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: accountTransConstant.GET_ALL_ACCOUNT_TRANSACTION_REQUEST}
    }

    function success(transaction) {
        return {type: accountTransConstant.GET_ALL_ACCOUNT_TRANSACTION_SUCCESS, transaction}
    }

    function failure(error) {
        return {type: accountTransConstant.GET_ALL_ACCOUNT_TRANSACTION_FAILURE, error}
    }
}




//create account trans Action
function submitAccountTransAction(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        accountTransService.submitAccountTrans(obj)
            .then(
                acct => {
                    if (acct['errorCode'] === 200) {
                        showSuccessToast("Account Translation Created Successfully!");
                        dispatch(success(acct));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(acct);
                    }
                    setDisable(false)
                },
                error => {
                    setDisable(false);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(acct) {
        return {type: accountTransConstant.CREATE_ACCOUNT_TRANSACTION_REQUEST, acct}
    }

    function success(acct) {
        return {type: accountTransConstant.CREATE_ACCOUNT_TRANSACTION_SUCCESS, acct}
    }

    function failure(error) {
        return {type: accountTransConstant.CREATE_ACCOUNT_TRANSACTION_FAILURE, error}
    }
}


//update account translation Action
function updateAccountTransAction(obj, id, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        accountTransService.updateAccountTrans(obj, id)
            .then(
                acct => {
                    if (acct['errorCode'] === 200) {
                        showSuccessToast("Account Translation Updated Successfully!");
                        dispatch(success(acct));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(acct);
                    }
                    setDisable(false)
                },
                error => {
                    setDisable(false);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(acct) {
        return {type: accountTransConstant.UPDATE_ACCOUNT_TRANSACTION_REQUEST, acct}
    }

    function success(acct) {
        return {type: accountTransConstant.UPDATE_ACCOUNT_TRANSACTION_SUCCESS, acct}
    }

    function failure(error) {
        return {type: accountTransConstant.UPDATE_ACCOUNT_TRANSACTION_FAILURE, error}
    }

}



function getAccountTranslationByIdAction(acctTransId) {
    return dispatch => {
        dispatch(request());
        accountTransService.getAccountTranslationById(acctTransId)
            .then(
                acct => dispatch(success(acct)),
                error => dispatch(failure(error.toString()))
            );

    };

    function request() {
        return {type: accountTransConstant.GET_ACCOUNT_TRANSACTION_BY_ID_REQUEST}
    }

    function success(acct) {
        return {type: accountTransConstant.GET_ACCOUNT_TRANSACTION_BY_ID_SUCCESS, acct}
    }

    function failure(error) {
        return {type: accountTransConstant.GET_ACCOUNT_TRANSACTION_BY_ID_FAILURE, error}
    }
}



//export account Trans Data
function exportData(query) {
    return dispatch => {
        dispatch(request());
        accountTransService.exportAccountTransData(query)
            .then(
                exportData => dispatch(success(exportData)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() {return {type: accountTransConstant.EXPORT_ACCOUNT_TRANSACTION_REQUEST}}
    function success(exportData) {query.setExportBtn(false); return {type: accountTransConstant.EXPORT_ACCOUNT_TRANSACTION_SUCCESS, exportData}}
    function failure(error) {query.setExportBtn(false); return {type: accountTransConstant.EXPORT_ACCOUNT_TRANSACTION_FAILURE, error}}


}


//delete code Action
function deleteAccountTranslationByIdAction(deleteQuery) {
    return dispatch => {
        dispatch(request({deleteQuery}));

        accountTransService.deleteAccountTranslationById(deleteQuery.deleteReference)
            .then(
                accountTrans => {
                    if (accountTrans['errorCode'] === 200) {
                        showSuccessToast("Account Translation Deleted Successfully!");
                        dispatch(success(accountTrans));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(accountTrans);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };


    function request(id) {
        return {type: accountTransConstant.DELETE_ACCOUNT_TRANSACTION_REQUEST, id}
    }
    function success(accountTrans) {
        return {type: accountTransConstant.DELETE_ACCOUNT_TRANSACTION_SUCCESS, accountTrans}
    }
    function failure(error) {
        return {type: accountTransConstant.DELETE_ACCOUNT_TRANSACTION_FAILURE, error}
    }

}


export function getAllAccountTransReducer(state = {}, action) {
    switch (action.type) {
        case accountTransConstant.GET_ALL_ACCOUNT_TRANSACTION_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case accountTransConstant.GET_ALL_ACCOUNT_TRANSACTION_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.transaction
            };
        case  accountTransConstant.GET_ALL_ACCOUNT_TRANSACTION_FAILURE:
            return {};
        default:
            return state
    }
}



export function getAccountTranslationByIdReducer(state = {}, action) {
    switch (action.type) {
        case accountTransConstant.GET_ACCOUNT_TRANSACTION_BY_ID_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case accountTransConstant.GET_ACCOUNT_TRANSACTION_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.acct
            };
        case  accountTransConstant.GET_ACCOUNT_TRANSACTION_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}



export function getAllTrans(query){
    return axiosInstance.post(`/ajax/get-all-transaction`, query).then(
        (response) => {
            return exceptionHandler(response);
        })
}




// create new Account Translation
export function submitAccountTrans(obj) {
    return axiosInstance.post(`/ajax/create/account-translation`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


// update Account Translation
export function updateAccountTrans(obj, id) {
    return axiosInstance.put(`/ajax/update/account-translation/${id}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


//get translation by id
export function getAccountTranslationById(acctTransId) {
    return axiosInstance.get(`/ajax/get-translation-by-id/${acctTransId}`).then(
        (response) => {
            return response.data;
        })
}


//export customer Data
export async function exportAccountTransData(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/account-translation?${paramData}`,null,
        {
            headers:
                {'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "AccountTranslation");
            }
        })
        .catch(handleExportError);
}

// delete account translation
export function deleteAccountTranslationById(accountId) {
    return axiosInstance.delete(`/ajax/delete/account-tran/${accountId}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

