import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import {showErrorToast, showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";
import axiosInstance from "../../../axiosInstance";

//export actions
export const userActions = {
    getAllData,
    getAllUserRolesAction,
    getRoleListByCustomerIdAction,
    submitUserAction,
    getUserByUserIdAction,
    getUserByTenantAction,
    resetUserPasswordAction,
    resetUserPasswordFromProfileAction,
    updateUserAction,
    updateLockedUserAction,
    exportData,
    getAllUsersListAction,
    deleteUserByIdAction,
    getUserProfileAction,
    getAuditableInfoAction
}

//export constants
export const userConstants = {
    GET_ALL_USER_REQUEST: 'GET_ALL_USER_REQUEST',
    GET_ALL_USER_SUCCESS: 'GET_ALL_USER_SUCCESS',
    GET_ALL_USER_FAILURE: 'GET_ALL_USER_FAILURE',

    CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',

    //roles by customer level
    GET_ALL_USER_ROLES_REQUEST: 'GET_ALL_USER_ROLES_REQUEST',
    GET_ALL_USER_ROLES_SUCCESS: 'GET_ALL_USER_ROLES_SUCCESS',
    GET_ALL_USER_ROLES_FAILURE: 'GET_ALL_USER_ROLES_FAILURE',

    GET_ALL_CUSTOMERS_NUMBER_REQUEST: 'GET_ALL_CUSTOMERS_NUMBER_REQUEST',
    GET_ALL_CUSTOMERS_NUMBER_SUCCESS: 'GET_ALL_CUSTOMERS_NUMBER_SUCCESS',
    GET_ALL_CUSTOMERS_NUMBER_FAILURE: 'GET_ALL_CUSTOMERS_NUMBER_FAILURE',

    GET_USER_BY_ID_REQUEST: 'GET_USER_BY_ID_REQUEST',
    GET_USER_BY_ID_SUCCESS: 'GET_USER_BY_ID_SUCCESS',
    GET_USER_BY_ID_FAILURE: 'GET_USER_BY_ID_FAILURE',

    GET_USER_BY_TENANT_REQUEST: 'GET_USER_BY_TENANT_REQUEST',
    GET_USER_BY_TENANT_SUCCESS: 'GET_USER_BY_TENANT_SUCCESS',
    GET_USER_BY_TENANT_FAILURE: 'GET_USER_BY_TENANT_FAILURE',

    //get all roles by user request
    GET_ALL_ROLES_REQUEST: 'GET_ALL_ROLES_REQUEST',
    GET_ALL_ROLES_SUCCESS: 'GET_ALL_ROLES_SUCCESS',
    GET_ALL_ROLES_FAILURE: 'GET_ALL_ROLES_FAILURE',


    GET_ALL_USER_LIST_REQUEST: 'GET_ALL_USER_LIST_REQUEST',
    GET_ALL_USER_LIST_SUCCESS: 'GET_ALL_USER_LIST_SUCCESS',
    GET_ALL_USER_LIST_FAILURE: 'GET_ALL_USER_LIST_FAILURE',

    UPDATE_RESET_PASSWORD_REQUEST: 'UPDATE_RESET_PASSWORD_REQUEST',
    UPDATE_RESET_PASSWORD_SUCCESS: 'UPDATE_RESET_PASSWORD_SUCCESS',
    UPDATE_RESET_PASSWORD_FAILURE: 'UPDATE_RESET_PASSWORD_FAILURE',

    UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

    UPDATE_LOCK_USER_REQUEST: 'UPDATE_LOCK_USER_REQUEST',
    UPDATE_LOCK_USER_SUCCESS: 'UPDATE_LOCK_USER_SUCCESS',
    UPDATE_LOCK_USER_FAILURE: 'UPDATE_LOCK_USER_FAILURE',

    EXPORT_USER_DATA_REQUEST: 'EXPORT_USER_DATA_REQUEST',
    EXPORT_USER_DATA_SUCCESS: 'EXPORT_USER_DATA_SUCCESS',
    EXPORT_USER_DATA_FAILURE: 'EXPORT_USER_DATA_FAILURE',

    GET_ALL_USER_ROLES_BY_CUSTOMER_ID_REQUEST: 'GET_ALL_USER_ROLES_BY_CUSTOMER_ID_REQUEST',
    GET_ALL_USER_ROLES_BY_CUSTOMER_ID_SUCCESS: 'GET_ALL_USER_ROLES_BY_CUSTOMER_ID_SUCCESS',
    GET_ALL_USER_ROLES_BY_CUSTOMER_ID_FAILURE: 'GET_ALL_USER_ROLES_BY_CUSTOMER_ID_FAILURE',

    GET_ALL_TEAM_LIST_REQUEST: 'GET_ALL_TEAM_LIST_REQUEST',
    GET_ALL_TEAM_LIST_SUCCESS: 'GET_ALL_TEAM_LIST_SUCCESS',
    GET_ALL_TEAM_LIST_FAILURE: 'GET_ALL_TEAM_LIST_FAILURE',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

    GET_USER_PROFILE_REQUEST: 'GET_USER_PROFILE_REQUEST',
    GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
    GET_USER_PROFILE_FAILURE: 'GET_USER_PROFILE_FAILURE',

    GET_USER_AUDITABLE_REQUEST: 'GET_USER_AUDITABLE_REQUEST',
    GET_USER_AUDITABLE_SUCCESS: 'GET_USER_AUDITABLE_SUCCESS',
    GET_USER_AUDITABLE_FAILURE: 'GET_USER_AUDITABLE_FAILURE',
}


//export service
export const usersService = {
    getAllUsers,
    getUserRole,
    getRoleListByCustomerId,
    submitUser,
    getUserByUserId,
    getUserByTenantName,
    resetUserPassword,
    resetUserPasswordFromProfile,
    updateUser,
    exportUserData,
    userLockedUserServices,
    getAllUsersList,
    deleteUserByIdService,
    getUserProfileService,
    getAuditableInfoService
};

{/*********** Actions started here. ******************/
}

//update resware Type Map action
function deleteUserByIdAction(deleteQuery) {
    return dispatch => {
        dispatch(request());
        usersService.deleteUserByIdService(deleteQuery.deleteReference)
            .then(
                customer => {
                    if (customer['errorCode'] === 200) {
                        showSuccessToast("User deleted successfully!");
                        dispatch(success(users));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(users);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: userConstants.DELETE_USER_REQUEST}
    }

    function success(users) {
        return {type: userConstants.DELETE_USER_SUCCESS, users}
    }

    function failure(error) {
        return {type: userConstants.DELETE_USER_FAILURE, error}
    }
}


//get all user action
function getAllData(query) {
    return dispatch => {
        dispatch(request());

        usersService.getAllUsers(query)
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GET_ALL_USER_REQUEST}
    }

    function success(users) {
        return {type: userConstants.GET_ALL_USER_SUCCESS, users}
    }

    function failure(error) {
        return {type: userConstants.GET_ALL_USER_FAILURE, error}
    }

}


//getUserRolAction by level action used in multiple places
function getAllUserRolesAction() {
    return dispatch => {
        dispatch(request());

        usersService.getUserRole()
            .then(
                roles => dispatch(success(roles)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GET_ALL_USER_ROLES_REQUEST}
    }

    function success(roles) {
        return {type: userConstants.GET_ALL_USER_ROLES_SUCCESS, roles}
    }

    function failure(error) {
        return {type: userConstants.GET_ALL_USER_ROLES_FAILURE, error}
    }
}

function getRoleListByCustomerIdAction(customerId) {
    return dispatch => {
        dispatch(request());

        usersService.getRoleListByCustomerId(customerId)
            .then(
                roles => dispatch(success(roles)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GET_ALL_USER_ROLES_BY_CUSTOMER_ID_REQUEST}
    }

    function success(roles) {
        return {type: userConstants.GET_ALL_USER_ROLES_BY_CUSTOMER_ID_SUCCESS, roles}
    }

    function failure(error) {
        return {type: userConstants.GET_ALL_USER_ROLES_BY_CUSTOMER_ID_FAILURE, error}
    }
}

function getAuditableInfoAction(userIds) {
    return dispatch => {
        dispatch(request());

        usersService.getAuditableInfoService(userIds)
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GET_USER_AUDITABLE_REQUEST}
    }

    function success(users) {
        return {type: userConstants.GET_USER_AUDITABLE_SUCCESS, users}
    }

    function failure(error) {
        return {type: userConstants.GET_USER_AUDITABLE_FAILURE, error}
    }
}


//submit user Data
function submitUserAction(username, email, customerNumber, roles, team, enabled, branch, from, setDisable, inTraining) {
    return dispatch => {
        dispatch(request({roles}));
        usersService.submitUser(username, email, customerNumber, roles, team, enabled, branch, inTraining)
            .then(
                user => {
                    if (user['errorCode'] === 200) {
                        showSuccessToast("User added successfully!");
                        dispatch(success(user));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(user);
                    }
                    setDisable(false);
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(roles) {
        return {type: userConstants.CREATE_USER_REQUEST, roles}
    }

    function success(username) {
        return {type: userConstants.CREATE_USER_SUCCESS, username}
    }

    function failure(error) {
        return {type: userConstants.CREATE_USER_FAILURE, error}
    }
}

function getUserByUserIdAction(userId) {
    return dispatch => {
        dispatch(request());
        usersService.getUserByUserId(userId)
            .then(
                user => dispatch(success(user)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GET_USER_BY_ID_REQUEST}
    }

    function success(user) {
        return {type: userConstants.GET_USER_BY_ID_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.GET_USER_BY_ID_SUCCESS, error}
    }

}

function getUserByTenantAction(tenantName) {
    return dispatch => {
        dispatch(request());

        usersService.getUserByTenantName(tenantName)
            .then(
                user => dispatch(success(user)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GET_USER_BY_TENANT_REQUEST}
    }

    function success(user) {
        return {type: userConstants.GET_USER_BY_TENANT_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.GET_USER_BY_TENANT_SUCCESS, error}
    }

}

// update User  action
function updateUserAction(id, username, email, customerNumber, roles, unAssignTeams, team, branch, tenant, enabled, accountNonLocked, credentialsNonExpired, from, setDisable, inTraining) {
    return dispatch => {
        dispatch(request());

        usersService.updateUser(id, username, email, customerNumber, roles, unAssignTeams, team, branch, enabled, accountNonLocked, credentialsNonExpired, tenant, inTraining)
            .then(
                data => {
                    if (data['errorCode'] === 200) {
                        showSuccessToast("User updated successfully!");
                        dispatch(success(data));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(data);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: userConstants.UPDATE_USER_REQUEST, email}
    }

    function success(data) {
        return {type: userConstants.UPDATE_USER_SUCCESS, data}
    }

    function failure(error) {
        return {type: userConstants.UPDATE_USER_FAILURE, error}
    }
}


function updateLockedUserAction(userId, setDisable, setShowConfirmation, query) {
    return dispatch => {
        dispatch(request());
        usersService.userLockedUserServices(userId)
            .then(
                data => {
                    if (data['errorCode'] === 200) {
                        showSuccessToast("User Unlocked successfully!");
                        dispatch(success(data));
                        setShowConfirmation(false)
                        dispatch(getAllData(query))
                    } else {
                        responseDTOExceptionHandler(data);
                    }
                    setDisable(false)

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: userConstants.UPDATE_LOCK_USER_REQUEST,}
    }

    function success(data) {
        return {type: userConstants.UPDATE_LOCK_USER_SUCCESS, data}
    }

    function failure(error) {
        return {type: userConstants.UPDATE_LOCK_USER_FAILURE, error}
    }

}

// update user action
function resetUserPasswordAction(obj, setDisable, setShow, password) {
    return dispatch => {
        dispatch(request());

        usersService.resetUserPassword(obj)
            .then(
                data => {
                    if (data !== undefined && data['errorCode'] === 200) {
                        showSuccessToast("Password reset successfully!");
                        password['oldPassword'] = ' ';
                        password['newPassword'] = ' ';
                        password['confirmPassword'] = ' ';

                        setDisable(false);
                        setShow(false);
                        dispatch(success(data));
                    } else {
                        setDisable(false);
                        responseDTOExceptionHandler(data);
                    }
                    setDisable(false);

                },
                error => {
                    setDisable(false);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: userConstants.UPDATE_RESET_PASSWORD_REQUEST}
    }

    function success(data) {
        return {type: userConstants.UPDATE_RESET_PASSWORD_SUCCESS, data}
    }

    function failure(error) {
        return {type: userConstants.UPDATE_RESET_PASSWORD_FAILURE, error}
    }
}


function resetUserPasswordFromProfileAction(obj, setDisable, setShow, password) {
    return dispatch => {
        dispatch(request());

        usersService.resetUserPasswordFromProfile(obj)
            .then(
                data => {
                    if (data !== undefined && data['errorCode'] === 200) {
                        showSuccessToast("Password reset successfully!");
                        localStorage.clear();
                        sessionStorage.clear();
                        history.push("/login");
                    } else {
                        setDisable(false);
                        responseDTOExceptionHandler(data);
                    }
                    setDisable(false);
                },
                error => {
                    setDisable(false);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: userConstants.UPDATE_RESET_PASSWORD_REQUEST}
    }

    function success(data) {
        return {type: userConstants.UPDATE_RESET_PASSWORD_SUCCESS, data}
    }

    function failure(error) {
        return {type: userConstants.UPDATE_RESET_PASSWORD_FAILURE, error}
    }
}

function getAllUsersListAction() {
    return dispatch => {
        dispatch(request());

        usersService.getAllUsersList()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GET_ALL_USER_LIST_REQUEST}
    }

    function success(users) {
        return {type: userConstants.GET_ALL_USER_LIST_SUCCESS, users}
    }

    function failure(error) {
        return {type: userConstants.GET_ALL_USER_LIST_FAILURE, error}
    }
}

//export user Data
function exportData(query) {
    return dispatch => {
        dispatch(request());
        usersService.exportUserData(query)
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.EXPORT_USER_DATA_REQUEST}
    }

    function success(userData) {
        query.setExportBtn(false);
        return {type: userConstants.EXPORT_USER_DATA_SUCCESS, userData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: userConstants.EXPORT_USER_DATA_FAILURE, error}
    }

}

function getUserProfileAction() {
    return dispatch => {
        dispatch(request());

        usersService.getUserProfileService()
            .then(
                user => dispatch(success(user)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GET_USER_PROFILE_REQUEST}
    }

    function success(user) {
        return {type: userConstants.GET_USER_PROFILE_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.GET_USER_PROFILE_FAILURE, error}
    }

}

/*********** Actions ended here. ******************/


/*********** Reducers started here. ******************/

//get all user reducer
export function users(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_ALL_USER_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case userConstants.GET_ALL_USER_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.users
            };
        case userConstants.GET_ALL_USER_FAILURE:
            return {};
        default:
            return state
    }
}


//get all roles for logged in user reducer
export function getAllRolesReducer(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_ALL_USER_ROLES_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };

        case userConstants.GET_ALL_USER_ROLES_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.roles
            };

        case userConstants.GET_ALL_USER_ROLES_FAILURE:
            return {};

        default:
            return state
    }
}

export function getAllRolesByCustomerIdReducer(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_ALL_USER_ROLES_BY_CUSTOMER_ID_REQUEST:
            return {...state};

        case userConstants.GET_ALL_USER_ROLES_BY_CUSTOMER_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.roles
            };
        case userConstants.GET_ALL_USER_ROLES_BY_CUSTOMER_ID_FAILURE:
            return {};

        default:
            return state
    }
}

export function getAuditableInfoReducer(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_USER_AUDITABLE_REQUEST:
            return {...state};

        case userConstants.GET_USER_AUDITABLE_SUCCESS:
            return {
                loggedIn: true,
                items: action.users
            };
        case userConstants.GET_USER_AUDITABLE_FAILURE:
            return {};

        default:
            return state
    }
}


export function getAllTeamListReducer(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_ALL_TEAM_LIST_REQUEST:
            return {...state};

        case userConstants.GET_ALL_TEAM_LIST_SUCCESS:
            return {
                loggedIn: true,
                items: action.teams
            };
        case userConstants.GET_ALL_TEAM_LIST_FAILURE:
            return {};

        default:
            return state
    }
}

export function getUserByUserIdReducer(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_USER_BY_ID_REQUEST:
            return {};

        case userConstants.GET_USER_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.user
            };
        case userConstants.GET_USER_BY_ID_FAILURE:
            return {};

        default:
            return state
    }
}

export function getUserByTenantReducer(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_USER_BY_TENANT_REQUEST:
            return {};

        case userConstants.GET_USER_BY_TENANT_SUCCESS:
            return {
                loggedIn: true,
                items: action.user
            };
        case userConstants.GET_USER_BY_TENANT_FAILURE:
            return {};

        default:
            return state
    }
}


export function getAllUsersListReducer(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_ALL_USER_LIST_REQUEST:
            return {
                loggingIn: true,
            };
        case userConstants.GET_ALL_USER_LIST_SUCCESS:
            return {
                loggedIn: true,
                items: action.users
            };
        case userConstants.GET_ALL_USER_LIST_FAILURE:
            return {};
        default:
            return state
    }
}

export function getUserProfileReducer(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_USER_PROFILE_REQUEST:
            return {};

        case userConstants.GET_USER_PROFILE_SUCCESS:
            return {
                loggedIn: true,
                items: action.user
            };
        case userConstants.GET_USER_PROFILE_FAILURE:
            return {};

        default:
            return state
    }
}


/*********** Reducers ended here. ******************/


/*********** Services started here. ******************/

export async function getAllUsers(query) {
    let paramData = serialize(query)
    if (query.roles !== '' || query.roles !== null) {
        let role = [];
        role.push(query.roles);
        query['roles'] = role;
    }
    return axiosInstance.post(`/ajax/users?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


//get all roles
export function getUserRole() {
    return axiosInstance.get(`/ajax/all-roles`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export function getRoleListByCustomerId(customerId) {
    return axiosInstance.get(`/ajax/role-by-customer-id/${customerId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

// submit user data
export function submitUser(username, email, customerNumber, roles, team, enabled, branch, inTraining) {
    return axiosInstance.get(`/ajax/customer/${customerNumber}`)
        .then((response) => {
            const submitUserData =
                {
                    userDTO: {
                        username: username,
                        email: email,
                        customer: response.data,
                        roles: roles,
                        enabled: parseInt(enabled),
                        inTraining: parseInt(inTraining),
                        branch: {
                            id: branch
                        },
                        accountNonExpired: true,
                        accountNonLocked: true,
                        credentialsNonExpired: true
                    },
                    teams: team,
                }

            return axiosInstance.post(`/ajax/submit/user`, submitUserData)
                .then((response) => {
                    return response.data;
                }).catch((error) => {
                    return Promise.reject(error);
                })
        })
}


//get userByUserName service
export function getUserByUserId(userId) {
    return axiosInstance.get(`/ajax/get-user-by-userId/${userId}`).then(
        (response) => {
            return response.data;
        })
}



export function getUserByTenantName(tenantName) {
    return axiosInstance.get(`/ajax/get-user-by-tenant-name/${tenantName}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

//reset password service api
export function resetUserPassword(obj) {
    return axiosInstance.put(`/ajax/reset-password-from-user-detail`, obj).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error);
    })
}

export function resetUserPasswordFromProfile(obj) {
    return axiosInstance.put(`/ajax/reset-password-from-profile`,obj).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error);
    })
}


// update user data
export function updateUser(id, username, email, customerNumber, roles, unAssignTeams, team, branch, enabled, accountNonLocked, credentialsNonExpired, tenant, inTraining) {

    return axiosInstance.get(`/ajax/customer/${customerNumber}`)
        .then((response) => {
            const updateUserData =
                {
                    userDTO: {
                        id: id,
                        username: username,
                        email: email,
                        customer: response.data,
                        roles: roles,
                        enabled: parseInt(enabled),
                        accountNonLocked: parseInt(accountNonLocked),
                        credentialsNonExpired: parseInt(credentialsNonExpired),
                        inTraining: parseInt(inTraining),
                        tenant: tenant,
                        branch: {
                            id: branch,
                            customerId: id
                        }
                    },
                    unAssignTeams: unAssignTeams,
                    teams: team,
                }

            return axiosInstance.put(`/ajax/user/update`, updateUserData)
                .then((response) => {
                    return response.data;
                }).catch((error) => {
                    return Promise.reject(error);
                })
        })
}


export async function userLockedUserServices(userId) {
    return axiosInstance.put(`/ajax/user/updateLockedUser/${userId}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


//export order Data
export async function exportUserData(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/user?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "userData");
            }
        })
        .catch(handleExportError);
}

export async function getAllUsersList() {
    return await axiosInstance.get(`/ajax/get-all-user-list`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function deleteUserByIdService(userId) {
    return axiosInstance.delete(`/ajax/deleteUserById/${userId}`).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error)
    })
}

export function getUserProfileService() {
    return axiosInstance.get(`/ajax/get-user-profile`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export function getAuditableInfoService(userIds){
    let paramData = serialize({userIds: userIds})

    return axiosInstance.get(`/ajax/get-user-auditable-info?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}
/*********** Services ended here. ******************/


