import React, {KeyboardEventHandler, useState} from 'react';
import CreatableSelect from 'react-select/creatable';
import {OnChangeValue} from 'react-select';
import {getPrimitiveArray} from "../helpers/utils";


const components = {
    DropdownIndicator: null,
};

interface internalOption {
    readonly label: string;
    readonly value: string;
}

const tagOption = (label: string) => ({
    label: label,
    value: label,
});


export default function CommonChosenInput({
                                              placeholder,
                                              state,
                                              setState,
                                              isRequired,
                                              contactFormType = "default",
                                              isDisable = false,
                                              isReadOnly = false,
                                              setEditable = null,
                                              callBackFn = null
                                          }) {

    const [stateValue, setStateValue] = useState('');

    const handleChange = (value: OnChangeValue<internalOption, true>) => {
            if(callBackFn!==null){
                callBackFn(value);
                return;
            }
        if (contactFormType === "association") {
            setState(getPrimitiveArray(value, "value"));
        } else {
            setState(value);
            if (setEditable != null) {
                setEditable(true);
            }
        }
    };


    const handleInputChange = (inputValue: string) => {
        if (contactFormType === "association") {
            return "";
        }
        setStateValue(inputValue);
        if (setEditable != null) {
            setEditable(true);
        }
    };


    function handleRequiredTag(e) {
        if (e.key !== 'Enter') return
        const value = e.target.value;
        if (value.trim()) {
            setState([...state, tagOption(value)]);
        }

        e.preventDefault();
    }

    function handleOnBlur(e) {
        const value = e.target.value;
        if (value.trim()) {
            setState([...state, tagOption(value)]);
        }
    }


    function saveValue() {
        if (!stateValue) return;
        setState([...state, tagOption(stateValue)]);
        setStateValue('');
    }


    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (!stateValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab': {
                if (callBackFn !== null) {
                    callBackFn(tagOption(stateValue))
                    setStateValue('')
                } else {
                    saveValue()
                }

                event.preventDefault();
            }

        }

        if (setEditable != null) {
            setEditable(true);
        }
    };


    return (
        <>
            {isRequired && state?.length === 0
                ?
                <input className="form-control"
                       type="text"
                       onKeyDown={(e) => handleRequiredTag(e)}
                       onBlur={(e) => handleOnBlur(e)}
                       placeholder={placeholder}
                       required={true} readOnly={isReadOnly}
                />

                :
                <CreatableSelect
                    components={components}
                    inputValue={stateValue}
                    autoFocus={state?.length !== 0}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onMenuClose={saveValue}
                    placeholder={placeholder}
                    value={state}
                    isDisabled={isDisable || isReadOnly === true}
                />
            }
        </>

    );
}

