import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showSuccessToast} from "../Toast";
import {alertActions} from "../../../features/alert/alert";
import axiosInstance from "../../../axiosInstance";


//export actions
export const userSettingsAction = {
    findUserSettingsByGroupNameActions,
    findTeamUserSettingsByGroupNameActions,
    updateUserSettingsActions

};

//export constants
export const userSettingsConstants = {

    GET_USER_SETTINGS_BY_GROUP_NAME_REQUEST: 'GET_USER_SETTINGS_BY_GROUP_NAME_REQUEST',
    GET_USER_SETTINGS_BY_GROUP_NAME_SUCCESS: 'GET_USER_SETTINGS_BY_GROUP_NAME_SUCCESS',
    GET_USER_SETTINGS_BY_GROUP_NAME_FAILURE: 'GET_USER_SETTINGS_BY_GROUP_NAME_FAILURE',

    UPDATE_USER_SETTINGS_REQUEST: 'UPDATE_USER_SETTINGS_REQUEST',
    UPDATE_USER_SETTINGS_SUCCESS: 'UPDATE_USER_SETTINGS_SUCCESS',
    UPDATE_USER_SETTINGS_FAILURE: 'UPDATE_USER_SETTINGS_FAILURE',

    GET_TEAM_USER_SETTINGS_BY_GROUP_NAME_REQUEST: 'GET_TEAM_USER_SETTINGS_BY_GROUP_NAME_REQUEST',
    GET_TEAM_USER_SETTINGS_BY_GROUP_NAME_SUCCESS: 'GET_TEAM_USER_SETTINGS_BY_GROUP_NAME_SUCCESS',
    GET_TEAM_USER_SETTINGS_BY_GROUP_NAME_FAILURE: 'GET_TEAM_USER_SETTINGS_BY_GROUP_NAME_FAILURE'

};


//export service
export const userSettingsService = {
    findUserSettingsByGroupName,
    updateUserSettings
};


{/******************************** user settings Actions Starts here  ****************************************/
}

//get all user settings by group Action
function findUserSettingsByGroupNameActions(groupName) {
    return dispatch => {
        dispatch(request());
        userSettingsService.findUserSettingsByGroupName(groupName)
            .then(
                settings => dispatch(success(settings)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userSettingsConstants.GET_USER_SETTINGS_BY_GROUP_NAME_REQUEST}
    }

    function success(settings) {
        return {type: userSettingsConstants.GET_USER_SETTINGS_BY_GROUP_NAME_SUCCESS, settings}
    }

    function failure(error) {
        return {type: userSettingsConstants.GET_USER_SETTINGS_BY_GROUP_NAME_FAILURE, error}
    }
}

//get all user settings by group Action
function findTeamUserSettingsByGroupNameActions(query) {
    return dispatch => {
        dispatch(request());
        userSettingsService.findUserSettingsByGroupName(query.userSettingsGroup)
            .then(
                settings => dispatch(success(settings)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userSettingsConstants.GET_TEAM_USER_SETTINGS_BY_GROUP_NAME_REQUEST}
    }

    function success(settings) {
        return {type: userSettingsConstants.GET_TEAM_USER_SETTINGS_BY_GROUP_NAME_SUCCESS, settings}
    }

    function failure(error) {
        return {type: userSettingsConstants.GET_TEAM_USER_SETTINGS_BY_GROUP_NAME_FAILURE, error}
    }
}

// update USER SETTINGS Action
function updateUserSettingsActions(obj, setDisable, setShow) {
    return dispatch => {
        dispatch(request({obj}));
        userSettingsService.updateUserSettings(obj)
            .then(
                userSetting => {
                    if (userSetting['errorCode'] === 200) {
                        showSuccessToast("Table settings updated successfully!");
                        dispatch(success(userSetting));
                        if (obj[0].group === 'Team Work') {
                            let query = {
                                userSettingsGroup: obj[0].group
                            }
                            dispatch(findTeamUserSettingsByGroupNameActions(query));
                        } else {
                            dispatch(findUserSettingsByGroupNameActions(obj[0].group));
                        }
                        setDisable !== null && setDisable(false);
                        setShow !== null && setShow(false);
                    } else {
                        responseDTOExceptionHandler(userSetting);
                        setDisable !== null && setDisable(false)
                    }
                    setDisable !== null && setDisable(false)
                },
                error => {
                    setDisable !== null && setDisable(false);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(userSetting) {
        return {type: userSettingsConstants.UPDATE_USER_SETTINGS_REQUEST, userSetting}
    }

    function success(userSetting) {
        return {type: userSettingsConstants.UPDATE_USER_SETTINGS_SUCCESS, userSetting}
    }

    function failure(error) {
        return {type: userSettingsConstants.UPDATE_USER_SETTINGS_FAILURE, error}
    }
}


{/************************************* jurisdictions Actions Ends here ********************************************/
}


{/************************** Reducers starts here. **************************************/
}

//get all jurisdiction reducer
export function findUserSettingsByGroupNameReducer(state = {}, action) {
    switch (action.type) {
        case userSettingsConstants.GET_USER_SETTINGS_BY_GROUP_NAME_REQUEST:
            return {
                loading: true,
                loggingIn: true,
            };
        case userSettingsConstants.GET_USER_SETTINGS_BY_GROUP_NAME_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.settings
            };
        case userSettingsConstants.GET_USER_SETTINGS_BY_GROUP_NAME_FAILURE:
            return {};
        default:
            return state
    }
}

export function findTeamUserSettingsByGroupNameReducer(state = {}, action) {
    switch (action.type) {
        case userSettingsConstants.GET_TEAM_USER_SETTINGS_BY_GROUP_NAME_REQUEST:
            return {
                loading: true,
                loggingIn: true,
            };
        case userSettingsConstants.GET_TEAM_USER_SETTINGS_BY_GROUP_NAME_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.settings
            };
        case userSettingsConstants.GET_TEAM_USER_SETTINGS_BY_GROUP_NAME_FAILURE:
            return {};
        default:
            return state
    }
}


{/*************************** Reducers ends here. **************************************/
}

{/******************************Jurisdiction Services starts here  ****************************************/
}

//get all jurisdictions
export function findUserSettingsByGroupName(groupName) {
    return axiosInstance.get(`/ajax/get-user-settings-by-group/${groupName}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


// create new Metro Area
export function updateUserSettings(userSettingObj) {
    return axiosInstance.put(`/ajax/update-user-settings-by-id`, userSettingObj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}




{/******************************Jurisdiction Services ends here  ****************************************/
}



