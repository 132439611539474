import {combineReducers} from 'redux';

import {
    getMyOrdersListReducer,
    getOrdersCountReducer,
    fetchAuthoritiesReducer,
    getTeamOrdersListReducer,
    getUnAssignedUsersTeamOrdersListReducer,
    getAllTgxFilesReducer,
    uploadMultipleDocumentReducer, utilDataReducer, getOrderCompletionPercentageReducer
} from "../features/home/home";
import {authentication} from '../features/authentication/authentication';
import {alert} from '../features/alert/alert'
import {
    getAllRolesReducer,
    users,
    getAllRolesByCustomerIdReducer,
    getAllUsersListReducer,
    getUserByUserIdReducer,
    getUserByTenantReducer,
    getUserProfileReducer,
    getAuditableInfoReducer
} from '../features/user/users/users'
import {
    branchesReducer,
    getBranchByIdReducer,
    getBranchByNameReducer, getBranchContactByIdReducer, getBranchContactsReducer
} from "../features/customer/branch";
import {
    getAllCountiesReducer,
    getCountyByIdReducer, getCountyByStateAbbrReducer,
    getAllCountiesByStateIdReducer, getAllCountiesByStateIdsReducer
} from "../features/data/county/county";
import {getAllPagedStateReducer, getStateByIdReducer} from "../features/data/state/state";
import {validateTokenReducer} from "../features/authentication/forgotPassword/forgotPassword";
import {
    customers,
    getAllCustomerByCustomerLevelAndTenantReducer,
    getAllCustomerByTenantName,
    getAllFulfillmentCustomerByTenantName,
    getAllParentCustomerByTenantNameReducer,
    getAllParentCustomersReducer,
    getAllRegularCustomerReducer,
    getAllStateReducer,
    getAllTenantReducer,
    getCustomerByIdReducer,
    getPdfImageByNameReducer, getPdfSettingByCustomerIdReducer,
} from "../features/customer/customer";
import {
    getAllSystemPropertyByIdReducer,
    getAllSystemPropertyReducer
} from "../features/admin/systemProperty/systemProperty";
import {
    getAllCollectorNotesByCollectorIdReducer,
    getAllCollectorReducer,
    getCollectorByIdReducer,
    getCollectorDocumentListReducer,
    getCollectorConfigListReducer,
    getCollectorLogReducer,
} from "../features/data/collector/collector";
import {
    computeOrderStatusReducer,
    getAllDocumentReducerNew,
    getAllDocumentReducerOld,
    getAllOrdersReducer,
    getHoaOrderResultByOrderIdReducer,
    getOrderAssignmentLogsReducer,
    getOrderByIdReducer,
    getOrderDocumentListReducer,
    getOrderListByCustomerIdReducer,
    getOrderLogsReducer,
    getOrderNotesByTypeReducer,
    getOrderNotesReducer,
    getOrderResultByOrderIdReducer,
    getProductQcLogicResultReducer,
    getProductTaskListReducer,
    getSimilarHoaAccountReducer,
    getSimilarTaxAccountReducer,
    getTaxSuitCollectorListReducer,
    populateCadInformationReducer,
    getOrderToDoListReducer,
    propertyPickerReducer,
    getAllDocumentReducer,
    isPdfExistReducer,
    getContactEmailReducer,
    getCertificateOptionsByOrderIdReducer,
    getOrderResultHistoryByProductIdReducer,
    getCloneAccountsReducer,
    getLinkedAccountsReducer,
    getAllOrderSelectedDocumentReducer,
    getAllDocumentForCombineReducer,
    getCustomerUploadedDocumentsReducer
} from "../features/order/orders";

import {
    getAllJurisdictionReducer,
    getJurisdictionByIdReducer, getJurisdictionNextMonthDueReducer, getJurisdictionsByCollectorReducer,
    getJurisdictionTaxRatesReducer
} from "../features/data/jurisdiction/jurisdiction";
import {getAllPermissionsReducer} from "../features/user/role/permissions";
import {getRoleByIdReducer} from "../features/user/role/roles";
import {
    getAllTaxRollReducer,
    getTaxRollByIdReducer
} from "../features/data/taxRoll/taxRoll";
import {
    getAllSubDivisionReducer,
    getSubdivisionByIdReducer,
    getAllSubdivisionNotesBySubdivisionIdReducer,
    getSubdivisionDocumentListReducer,
    getSubdivisionByNameAndCountyReducer,
    getSubdivisionNameByIdReducer,
    getAllSubDivisionByManagmentCompanyReducer
} from "../features/data/subDivision/subDivision";
import {getParentAccountByIdReducer} from "../features/data/subDivision/ParentAccount";
import {getAllChildAccountReducer, getChildAccountByIdReducer} from "../features/data/subDivision/ChildAccount";
import {vendorPricingReducer} from "../features/accounting/vendor";
import {partnerPricingReducer} from "../features/accounting/partner";
import {customerPricingReducer} from "../features/accounting/customer";
import {
    getAllAppraisalRollReducer,
    getAppraisalRollByIdReducer,
    getPrimaryCollectorByStateAndCountyReducer
} from "../features/data/appraisalRoll/appraisalRoll";
import {getOrdersListByOrderNumberReducer} from "../features/layout/template/modal/searchOrderModalRedux";
import {
    getAllMetroAreaReducer,
    getMetroAreaByIdReducer,
    getMetroAreaByStateIdReducer,
    getMetroAreaByStateAbbrReducer, getMetroAreasByStateIdsReducer
} from "../features/data/msa/metroArea";
import {
    getAllManagementCompanyReducer,
    getManagementCompanyByIdReducer,
    getAllManagementCompanyNotesByManagementIdReducer,
} from "../features/Hoa/managementCompany/managementCompany";
import {
    getAllAssociationsReducer,
    getAssociationByIdReducer,
    getAssociationLogsListReducer,
    getAssociationDocumentListReducer,
    getAllSubdivisionByAssocIdReducer
} from "../features/Hoa/association/association";
import {getAllAssociationsFeeReducer, getAssociationsFeeByIdReducer} from "../features/Hoa/association/associationFee";
import {getAllTeamReducer, getTeamByIdReducer, getTeamsManagersReducer, getTeamsByTenantReducer} from "../features/user/team/teams";
import {
    getAllTeamRuleByTeamIdReducer,
    getTeamRuleByIdReducer,
    getPreviewOrderListReducer
} from "../features/user/team/teamRule";
import {getAllMemberReducer} from "../features/user/team/members";
import {getAllManagerReducer} from "../features/user/team/manager";
import {
    getAllAssignToMemberReducer,
    isUserManager
} from "../features/order/AssignTo";
import {fetchEnumsReducer} from "../features/home/home";
import {getAllImportVersionsReducer} from "../features/admin/importVersion/importVersion";
import {getAllAutoHoaReducer, getAutoHoaIdReducer} from "../features/Hoa/auto-hoa/autoHoa";
import {
    resWareCredentialReducers,
    resWareCredentialByIdReducers,
    resWareTypeMapReducers,
    resWareTypeMapByIdReducers
} from "../features/customer/customerResWare";
import {
    getAllNoteLogicReducer,
    getNoteLogicByIdReducer,
} from "../features/admin/noteLogic/note";
import {getAllCodeReducer, getCodeByIdReducer, getCodeListReducer} from "../features/admin/code/code";
import {getAllQCRuleReducer} from "../features/admin/qcLogic/qcRule";
import {
    getQcRuleByIdReducer,
} from "../features/admin/qcLogic/qcRule";
import {getRegisteredScrapersReducer} from "../features/admin/workerScraper/workerScraper";

import {
    findTeamUserSettingsByGroupNameReducer,
    findUserSettingsByGroupNameReducer
} from "../common/components/userSettingsModal/userSettings";
import {getPagedPaymentsReducer} from "../features/accounting/payment";
import{getRevenueEventsReducer} from "../features/accounting/revenueEvent";
import {getBranchByCustomerIdReducer,
    getBranchByCustomerIdsReducer } from "../features/customer/branch";
import {getPagedOrderEventsReducer} from "../features/reports/orderEvents/orderEvent";
import {getAllCustomersListReducer} from "../features/customer/customer";
import {getAllScrapersModuleReducer} from "../features/admin/scraperModule/scraperModule";
import {getScraperModuleByIdReducer} from "../features/admin/scraperModule/scraperModule";
import {getAllScrapersModuleByListReducer} from "../features/admin/scraperModule/scraperModule"
import {getAllAccountTransReducer, getAccountTranslationByIdReducer} from "../features/admin/accountTrans/accountTrans";
import {
    getAllReportsReducer,
    getRevenueReportReducer,
    getTurnAroundReportsReducer,
    getCountyReportReducer,
    getUserEfficiencyReportReducer,
    getAutoManualReportReducer, getDetailedUserEfficiencyReportReducer, getAccountsReceivableReducer, getFulfillmentCandidateReportReducer , getFulfillmentCustomerReportReducer, getProductAssignmentReportReducer
} from "../features/reports/reports/reports";
import {getAllTransactionsReducer} from "../features/accounting/transaction";
import {getAllReportHistoryReducer} from "../features/reports/report-history/reportHistory";
import {getErrorLogsReducer} from "../features/admin/error/errorLogs";
import {getAllCannedNotesReducer, getCannedNoteByIdReducer} from "../features/data/cannedNote/cannedNotes";
import {
    getAllFollowUpTypeReducer,
    getFollowUpTypeByIdReducer,
    getFollowUpTypeListReducer,
    getFollowUpTypeListByTenantReducer
} from "../features/admin/followUpType/followUp";
import {getAllContactsByRelationIdReducer, getContactByIdReducer} from "../common/components/contactModals/contact";
import {getAllLinkedAccountReducer, getLinkedAccountByIdReducer} from "../features/data/linkedAccount/linkedAccount";
import {
    getAllPagedBillReleaseReducer,
    getBillReleaseByIdReducer
} from "../features/data/billRelease/billRelease";
import {
    getExternalPageBillReleaseReducer,
    getExternalPageCountyByStateAbbrReducer,
    getExternalPageStateReducer
} from "../features/external/billRelease/external";
import {getAllCollectorLinkReducer, getCollectorLinkByIdReducer} from "../features/data/collectorLink/collectoLinks";
import {getAllFeeTypesReducer} from "../features/Hoa/sortHoaFeeType/sortHoaFeeType";
import {fulfillmentPricingReducer} from "../features/accounting/fulfillment";
import {getAllPagedStateCodeReducer, getStateCodeByIdReducer} from "../features/data/stateCode/stateCode";
import {
    getAllRecordAuditReducer,
    getRecordAuditByIdReducer,
    getRecordAuditEntityListReducer
} from "../features/admin/recordAudit/recordAudit";
import {
    getAllPagedAliasByStateCodeIdAction,
    getStateCodeAliasByIdReducer
} from "../features/data/stateCode/stateCodeAlias";
import {
    getAllPagedHighRiskAccountVerifiedReducer,
} from "../features/data/highRiskAccountVerified/highRiskAccountVerified";

import {
    getAllThirdPartyProviderNotesByProviderIdReducer, getAllThirdPartyProviderReducer,
    getThirdPartyProviderByIdReducer
} from "../features/Hoa/thirdPartyProvider/thirdPartyProvider";

import {getAllPagedHighRiskAccountReducer} from "../features/data/highRiskAccount/highRiskAccount";
import {
    getAllTaxRoundingConfigReducer,
    getTaxRoundingConfigByIdReducer
} from "../features/data/taxRoundingConfig/taxRoundingConfig";
import {getRegionByIdReducer, getRegionsByCustomerIdReducer, regionsReducer} from "../features/customer/region";
import {getAllCertificateNotesByRelationIdReducer} from "../common/components/certificateNotes/certificateNotes";

const webReducer = combineReducers({
    alert,
    authentication,
    validateTokenReducer,
    fetchAuthoritiesReducer,
    getOrderCompletionPercentageReducer,
    getOrdersCountReducer,
    getMyOrdersListReducer,

    users,
    getUserByUserIdReducer,
    getAllRolesReducer,
    getAllRolesByCustomerIdReducer,
    getUserByTenantReducer,
    getAllStateReducer,
    getOrderNotesByTypeReducer,
    getAuditableInfoReducer,
    getAllPermissionsReducer,
    customers,
    getAllTenantReducer,
    getAllCustomerByTenantName,
    getCustomerByIdReducer,
    getPdfSettingByCustomerIdReducer,
    getAllRegularCustomerReducer,
    getRoleByIdReducer,

    vendorPricing: vendorPricingReducer,
    partnerPricing: partnerPricingReducer,
    customerPricing: customerPricingReducer,

    branches: branchesReducer,
    getBranchByIdReducer,
    getBranchByNameReducer,
    getBranchContactsReducer,

    getAllCountiesReducer,
    getAllPagedStateReducer,

    getAllOrdersReducer,
    getAllDocumentReducerNew,
    getAllDocumentReducerOld,
    getOrderByIdReducer,
    getOrderResultByOrderIdReducer,
    getHoaOrderResultByOrderIdReducer,
    getOrderNotesReducer,
    getOrderLogsReducer,
    getOrderAssignmentLogsReducer,
    getOrderListByCustomerIdReducer,
    getOrderDocumentListReducer,
    getSubdivisionByNameAndCountyReducer,
    getSimilarTaxAccountReducer,
    getSimilarHoaAccountReducer,
    getCountyByStateAbbrReducer,
    getAllCountiesByStateIdsReducer,
    getMetroAreasByStateIdsReducer,
    getCountyByIdReducer,
    getStateByIdReducer,
    getPdfImageByNameReducer,
    getAllCollectorReducer,
    getCollectorByIdReducer,
    getCollectorConfigListReducer,
    getCollectorDocumentListReducer,

    getAllCollectorNotesByCollectorIdReducer,
    getCollectorLogReducer,
    getAllJurisdictionReducer,
    getJurisdictionByIdReducer,
    getAllTaxRollReducer,
    getTaxRollByIdReducer,
    getAllCountiesByStateIdReducer,

    getAllSubDivisionReducer,
    getSubdivisionByIdReducer,
    getAllSubdivisionNotesBySubdivisionIdReducer,
    getSubdivisionDocumentListReducer,

    getParentAccountByIdReducer,

    getAllChildAccountReducer,
    getChildAccountByIdReducer,

    getAllSystemPropertyReducer,
    getAllSystemPropertyByIdReducer,

    getAllScrapersModuleReducer,

    getAllAppraisalRollReducer,
    getAppraisalRollByIdReducer,
    getOrdersListByOrderNumberReducer,
    getAllMetroAreaReducer,
    getMetroAreaByIdReducer,
    getMetroAreaByStateIdReducer,
    getAllManagementCompanyReducer,
    getManagementCompanyByIdReducer,
    getAllManagementCompanyNotesByManagementIdReducer,
    getAllAssociationsReducer,
    getAssociationByIdReducer,
    getAllAssociationsFeeReducer,
    getAssociationLogsListReducer,
    getAssociationsFeeByIdReducer,
    getAssociationDocumentListReducer,
    getAllAutoHoaReducer,
    getAutoHoaIdReducer,
    getAllTeamReducer,
    getTeamByIdReducer,
    getAllTeamRuleByTeamIdReducer,
    getTeamRuleByIdReducer,

    getAllMemberReducer,
    getTaxSuitCollectorListReducer,

    getAllSubdivisionByAssocIdReducer,
    getContactByIdReducer,

    getPreviewOrderListReducer,
    getMetroAreaByStateAbbrReducer,
    getAllManagerReducer,
    getAllAssignToMemberReducer,
    isUserManager,
    fetchEnumsReducer,
    getAllImportVersionsReducer,
    resWareCredentialReducers,
    resWareCredentialByIdReducers,
    resWareTypeMapReducers,
    resWareTypeMapByIdReducers,
    getAllCodeReducer,
    getCodeByIdReducer,
    getAllNoteLogicReducer,
    getNoteLogicByIdReducer,
    getAllQCRuleReducer,
    getCodeListReducer,
    getQcRuleByIdReducer,
    getRegisteredScrapersReducer,
    findUserSettingsByGroupNameReducer,
    findTeamUserSettingsByGroupNameReducer,
    getPagedPaymentsReducer,
    getRevenueEventsReducer,
    getBranchByCustomerIdReducer,
    getBranchByCustomerIdsReducer,
    getPagedOrderEventsReducer,
    getAllCustomersListReducer,
    getScraperModuleByIdReducer,
    getAllScrapersModuleByListReducer,
    getAllAccountTransReducer,
    getAccountTranslationByIdReducer,
    getTeamOrdersListReducer,
    getJurisdictionTaxRatesReducer,
    getAllReportsReducer,
    getAllTransactionsReducer,
    getAllUsersListReducer,
    computeOrderStatusReducer,
    getAllReportHistoryReducer,
    getProductQcLogicResultReducer,
    getAllCannedNotesReducer,
    getErrorLogsReducer,
    propertyPickerReducer,
    populateCadInformationReducer,
    getOrderToDoListReducer,
    getProductTaskListReducer,
    getPrimaryCollectorByStateAndCountyReducer,
    getAllDocumentReducer,
    isPdfExistReducer,
    getSubdivisionNameByIdReducer,
    getTeamsManagersReducer,
    getTurnAroundReportsReducer,
    getAllFollowUpTypeReducer,
    getFollowUpTypeByIdReducer,
    getFollowUpTypeListReducer,
    getContactEmailReducer,
    getBranchContactByIdReducer,
    getCannedNoteByIdReducer,
    uploadMultipleDocumentReducer,
    getCertificateOptionsByOrderIdReducer,
    getAllCustomerByCustomerLevelAndTenantReducer,
    getOrderResultHistoryByProductIdReducer,
    getCloneAccountsReducer,
    getRevenueReportReducer,
    getCountyReportReducer,
    getAllLinkedAccountReducer,
    getLinkedAccountByIdReducer,
    getLinkedAccountsReducer,
    utilDataReducer,
    getUserEfficiencyReportReducer,
    getDetailedUserEfficiencyReportReducer,
    getAutoManualReportReducer,
    getJurisdictionNextMonthDueReducer,
    getAllPagedBillReleaseReducer,
    getBillReleaseByIdReducer,
    getExternalPageStateReducer,
    getExternalPageCountyByStateAbbrReducer,
    getExternalPageBillReleaseReducer,
    getAccountsReceivableReducer,
    getCollectorLinkByIdReducer,
    getAllCollectorLinkReducer,
    fulfillmentPricingReducer,
    getAllFeeTypesReducer: getAllFeeTypesReducer,
    getAllFulfillmentCustomerByTenantName,
    getAllOrderSelectedDocumentReducer,
    getAllContactsByRelationIdReducer,
    getStateCodeByIdReducer,
    getAllPagedStateCodeReducer,
    getAllRecordAuditReducer,
    getRecordAuditByIdReducer,
    getRecordAuditEntityListReducer,
    getAllPagedAliasByStateCodeIdAction,
    getStateCodeAliasByIdReducer,
    getJurisdictionsByCollectorReducer,
    getAllPagedHighRiskAccountVerifiedReducer,
    getAllThirdPartyProviderNotesByProviderIdReducer,
    getThirdPartyProviderByIdReducer,
    getAllTaxRoundingConfigReducer,
    getTaxRoundingConfigByIdReducer,
    getAllThirdPartyProviderReducer,
    getUserProfileReducer,
    getAllPagedHighRiskAccountReducer,
    getAllDocumentForCombineReducer,
    getAllParentCustomerByTenantNameReducer,
    getAllParentCustomersReducer,
    regionsReducer,
    getRegionByIdReducer,
    getRegionsByCustomerIdReducer,
    getAllCertificateNotesByRelationIdReducer,
    getAllSubDivisionByManagmentCompanyReducer,
    getUnAssignedUsersTeamOrdersListReducer,
    getAllTgxFilesReducer,
    getFulfillmentCandidateReportReducer,
    getFulfillmentCustomerReportReducer,
    getProductAssignmentReportReducer,
    getTeamsByTenantReducer,
    getFollowUpTypeListByTenantReducer,
    getCustomerUploadedDocumentsReducer
});


const rootReducer = (state, action) => {

    if (action.type === 'USERS_LOGOUT') {
        return webReducer(state = undefined, action);
    }

    if (action.type === 'RESET_REDUCER') {
        action.reducers.map(curReducer => {
            state[curReducer] = {}
        })
        return webReducer(state, action)
    }

    return webReducer(state, action)
}


export default rootReducer;
