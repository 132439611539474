
/*****All constants PATTERN and TITLE messages for forms input fields **********/
export const postalTitle = "*Postal code must follow the ZIP or ZIP+4 format.";
export const postalPattern = "^[0-9]{5}(?:-[0-9]{4})?$";
let date = new Date(), y = date.getFullYear(), m = date.getMonth();
let currentTaxYear = new Date().getMonth() < 9 ? new Date().getFullYear() - 1 : new Date().getFullYear();

export const currentMonthLastDate = new Date(y, m + 1, 0);
export const nextMonthLastDate = new Date(y, m + 2, 0);

export const phoneTitle = "";
export const phonePattern = "^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$";

export const passwordMatch = "Password and Confirm Password must match!";
export const minYear = 1900
export const maxYear = currentTaxYear + 2
export const remitDefault = "TitleLogix Solutions ATTN: 3rd Floor 1401 S Boulder Ave Tulsa, OK 74119 918-250-1641";

//homeDashboard matrices constants to reset form.
export let booleanChecks = [{value: 0, label: "Disabled"}, {value: 1, label: "Enabled"}]

export let booleanArray = [
    {value: false, label: "Disabled"},
    {value: true, label: "Enabled"}
]

export let booleanDrop = [
    {value: true, label: "True"},
    {value: false, label: "False"}
]

export let categories = [
    {value: "Low", label: "Low"},
    {value: "Medium", label: "Medium"},
    {value: "High", label: "High"},
    {value: "Extreme", label: "Extreme"}
]


export let feeListEnabledEnumsRegex = /ASSESSMENT|MAINTENANCE|MAINT_DV_BD_PRO_RATE|MAINT_HO_PRO_RATE|GATE_DV_BD_PRO_RATE|GATE_HO_PRO_RATE/;

export let quickFilterParams = {"quickFilterKey": "quickFilters", "resetQuickFilterKey": "resetQuickFilter"};


export let addNewDefaultSelectorValue = {id: "defaultId", sequence: "", addressLine1: "Add New"};


export let computeOrderResultSortedKeyValue = {
    "jurisdictions": "jurisdictionName",
    "assessments": "taxYear",
    "cadInformation": "jurisdictionName",
    "notes": "comment",
    "levies": "billYear",
    "certificateNotes": "comment",
    "fees":"type",
    "bondList":"jurisdictionName",
    "bonds":"bondsApproved"

};
export const customerPermittedOrderStatusEnums= ["AWAITING_PARTNER", "AWAITING_LOCATE", "NEW", "ISSUE", "AWAITING_FULFILLMENT"];
