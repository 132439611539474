import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {alertActions} from "../../alert/alert";
import {showErrorToast, showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";
import axiosInstance from "../../../axiosInstance";

//export actions
export const jurisdictionAction = {
    getAllData,
    submitJurisdictionAction,
    getJurisdictionByIdAction,
    updateJurisdictionAction,
    getJurisdictionTaxRatesAction,
    getJurisdictionNextMonthDueAction,
    exportData,
    deleteJurisdictionActions,
    getJurisdictionsByCollectorIdAction,
    updateJurisdictionsCollectorAction
};

//export constants
export const jurisdictionConstants = {
    GET_ALL_JURISDICTION_REQUEST: 'GET_ALL_JURISDICTION_REQUEST',
    GET_ALL_JURISDICTION_SUCCESS: 'GET_ALL_JURISDICTION_SUCCESS',
    GET_ALL_JURISDICTION_FAILURE: 'GET_ALL_JURISDICTION_FAILURE',

    GET_JURISDICTION_TAX_LIST_REQUEST: 'GET_JURISDICTION_TAX_LIST_REQUEST',
    GET_JURISDICTION_TAX_LIST_SUCCESS: 'GET_JURISDICTION_TAX_LIST_SUCCESS',
    GET_JURISDICTION_TAX_LIST_FAILURE: 'GET_JURISDICTION_TAX_LIST_FAILURE',

    GET_JURISDICTION_NEXT_MONTH_DUE_REQUEST: 'GET_JURISDICTION_NEXT_MONTH_DUE_REQUEST',
    GET_JURISDICTION_NEXT_MONTH_DUE_SUCCESS: 'GET_JURISDICTION_NEXT_MONTH_DUET_SUCCESS',
    GET_JURISDICTION_NEXT_MONTH_DUE_FAILURE: 'GET_JURISDICTION_NEXT_MONTH_DUE_FAILURE',

    CREATE_JURISDICTION_REQUEST: 'CREATE_JURISDICTION_REQUEST',
    CREATE_JURISDICTION_SUCCESS: 'CREATE_JURISDICTION_SUCCESS',
    CREATE_JURISDICTION_FAILURE: 'CREATE_JURISDICTION_FAILURE',

    GET_JURISDICTION_BY_ID_REQUEST: 'GET_JURISDICTION_BY_ID_REQUEST',
    GET_JURISDICTION_BY_ID_SUCCESS: 'GET_JURISDICTION_BY_ID_SUCCESS',
    GET_JURISDICTION_BY_ID_FAILURE: 'GET_JURISDICTION_BY_ID_FAILURE',

    UPDATE_JURISDICTION_REQUEST: 'UPDATE_JURISDICTION_REQUEST',
    UPDATE_JURISDICTION_SUCCESS: 'UPDATE_JURISDICTION_SUCCESS',
    UPDATE_JURISDICTION_FAILURE: 'UPDATE_JURISDICTION_FAILURE',

    EXPORT_JURISDICTION_DATA_REQUEST: 'EXPORT_JURISDICTION_DATA_REQUEST',
    EXPORT_JURISDICTION_DATA_SUCCESS: 'EXPORT_JURISDICTION_DATA_SUCCESS',
    EXPORT_JURISDICTION_DATA_FAILURE: 'EXPORT_JURISDICTION_DATA_FAILURE',

    DELETE_JURISDICTION_REQUEST: 'DELETE_JURISDICTION_REQUEST',
    DELETE_JURISDICTION_SUCCESS: 'DELETE_JURISDICTION_SUCCESS',
    DELETE_JURISDICTION_FAILURE: 'DELETE_JURISDICTION_FAILURE',

    GET_ALL_JURISDICTION_BY_COLLECTOR_ID_REQUEST: 'GET_ALL_JURISDICTION_BY_COLLECTOR_ID_REQUEST',
    GET_ALL_JURISDICTION_BY_COLLECTOR_ID_SUCCESS: 'GET_ALL_JURISDICTION_BY_COLLECTOR_ID_SUCCESS',
    GET_ALL_JURISDICTION_BY_COLLECTOR_ID_FAILURE: 'GET_ALL_JURISDICTION_BY_COLLECTOR_ID_FAILURE',

    UPDATE_JURISDICTIONS_COLLECTOR_REQUEST: 'UPDATE_JURISDICTIONS_COLLECTOR_REQUEST',
    UPDATE_JURISDICTIONS_COLLECTOR_SUCCESS: 'UPDATE_JURISDICTIONS_COLLECTOR_SUCCESS',
    UPDATE_JURISDICTIONS_COLLECTOR_FAILURE: 'UPDATE_JURISDICTIONS_COLLECTOR_FAILURE',
};


//export service
export const jurisdictionService = {
    getAllJurisdiction,
    createJurisdiction,
    getJurisdictionById,
    getJurisdictionByTaxList,
    updateJurisdiction,
    exportJurisdictionData,
    deleteJurisdiction,
    getJurisdictionsNextMonthDues,
    getJurisdictionsByCollectorId,
    updateJurisdictionsCollector
};


{/******************************** jurisdictions Actions Starts here  ****************************************/
}

//get all Jurisdiction Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());

        jurisdictionService.getAllJurisdiction(query)
            .then(
                jurisdictions => dispatch(success(jurisdictions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: jurisdictionConstants.GET_ALL_JURISDICTION_REQUEST}
    }

    function success(jurisdictions) {
        return {type: jurisdictionConstants.GET_ALL_JURISDICTION_SUCCESS, jurisdictions}
    }

    function failure(error) {
        return {type: jurisdictionConstants.GET_ALL_JURISDICTION_FAILURE, error}
    }
}


//create city Action
function submitJurisdictionAction(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        jurisdictionService.createJurisdiction(obj)
            .then(
                jurisdiction => {
                    if (jurisdiction['errorCode'] === 200) {
                        showSuccessToast("Jurisdiction added successfully!");
                        dispatch(success(jurisdiction));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(jurisdiction);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(jurisdiction) {
        return {type: jurisdictionConstants.CREATE_JURISDICTION_REQUEST, jurisdiction}
    }

    function success(jurisdiction) {
        return {type: jurisdictionConstants.CREATE_JURISDICTION_SUCCESS, jurisdiction}
    }

    function failure(error) {
        return {type: jurisdictionConstants.CREATE_JURISDICTION_FAILURE, error}
    }
}


//get jurisdiction by id
function getJurisdictionByIdAction(jurisdictionId) {
    return dispatch => {
        dispatch(request());
        jurisdictionService.getJurisdictionById(jurisdictionId)
            .then(
                jurisdiction => dispatch(success(jurisdiction)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: jurisdictionConstants.GET_JURISDICTION_BY_ID_REQUEST}
    }

    function success(jurisdiction) {
        return {type: jurisdictionConstants.GET_JURISDICTION_BY_ID_SUCCESS, jurisdiction}
    }

    function failure(error) {
        return {type: jurisdictionConstants.GET_JURISDICTION_BY_ID_FAILURE, error}
    }
}


function getJurisdictionsByCollectorIdAction(collectorId) {
    return dispatch => {
        dispatch(request());
        jurisdictionService.getJurisdictionsByCollectorId(collectorId)
            .then(
                jurisdictions => dispatch(success(jurisdictions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: jurisdictionConstants.GET_ALL_JURISDICTION_BY_COLLECTOR_ID_REQUEST}
    }

    function success(jurisdictions) {
        return {type: jurisdictionConstants.GET_ALL_JURISDICTION_BY_COLLECTOR_ID_SUCCESS, jurisdictions}
    }

    function failure(error) {
        return {type: jurisdictionConstants.GET_ALL_JURISDICTION_BY_COLLECTOR_ID_FAILURE, error}
    }
}


//get jurisdiction tax by id
function getJurisdictionTaxRatesAction(jurisdictionIds, setReloadJurisdictionList) {
    return dispatch => {
        dispatch(request());
        jurisdictionService.getJurisdictionByTaxList(jurisdictionIds)
            .then(
                jurisdiction => {
                    if (jurisdiction['errorCode'] === 200) {
                        showSuccessToast("Tax Rate Updated Successfully!");
                        dispatch(success(jurisdiction['taxRateList']))
                        setReloadJurisdictionList(false)
                    } else {
                        responseDTOExceptionHandler(jurisdiction);
                        setReloadJurisdictionList(false)
                    }
                    setReloadJurisdictionList(false)
                },
                error => {
                    showErrorToast(error.toString());
                    setReloadJurisdictionList(false)
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: jurisdictionConstants.GET_JURISDICTION_TAX_LIST_REQUEST}
    }

    function success(jurisdiction) {
        return {type: jurisdictionConstants.GET_JURISDICTION_TAX_LIST_SUCCESS, jurisdiction}
    }

    function failure(error) {
        return {type: jurisdictionConstants.GET_JURISDICTION_TAX_LIST_FAILURE, error}
    }
}

function getJurisdictionNextMonthDueAction(jurisdictions, hasSuite, setReloadJurisdictionNextMonthDue) {
    return dispatch => {
        dispatch(request());
        jurisdictionService.getJurisdictionsNextMonthDues(jurisdictions, hasSuite)
            .then(
                jurisdiction => {
                    if (jurisdiction['errorCode'] === 200) {
                        showSuccessToast("Next Month Dues Updated Successfully!");
                        dispatch(success(jurisdiction['jurisdictions']));
                        setReloadJurisdictionNextMonthDue(false)
                    } else {
                        responseDTOExceptionHandler(jurisdiction);
                        setReloadJurisdictionNextMonthDue(false)
                    }
                    setReloadJurisdictionNextMonthDue(false)
                },
                error => {
                    showErrorToast(error.toString());
                    setReloadJurisdictionNextMonthDue(false)
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: jurisdictionConstants.GET_JURISDICTION_NEXT_MONTH_DUE_REQUEST}
    }

    function success(jurisdiction) {
        return {type: jurisdictionConstants.GET_JURISDICTION_NEXT_MONTH_DUE_SUCCESS, jurisdiction}
    }

    function failure(error) {
        return {type: jurisdictionConstants.GET_JURISDICTION_NEXT_MONTH_DUE_FAILURE, error}
    }
}


// update Jurisdiction Action
function updateJurisdictionAction(obj, setDisable, setEditable, from) {
    return dispatch => {
        dispatch(request({obj}));
        jurisdictionService.updateJurisdiction(obj)
            .then(
                jurisdiction => {
                    if (jurisdiction['errorCode'] === 200) {
                        showSuccessToast("Jurisdiction updated successfully!");
                        dispatch(success(jurisdiction));
                        history.push(from.pathname);
                    } else {
                        responseDTOExceptionHandler(jurisdiction);
                    }
                    setDisable(false);
                    setEditable(false);
                    dispatch(getJurisdictionById(obj.id));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(jurisdiction) {
        return {type: jurisdictionConstants.UPDATE_JURISDICTION_REQUEST, jurisdiction}
    }

    function success(jurisdiction) {
        return {type: jurisdictionConstants.UPDATE_JURISDICTION_SUCCESS, jurisdiction}
    }

    function failure(error) {
        return {type: jurisdictionConstants.UPDATE_JURISDICTION_FAILURE, error}
    }
}


function updateJurisdictionsCollectorAction(jurisdictionIds, setDisable, collectorId, from , deleteCollectorId) {
    return dispatch => {
        dispatch(request({jurisdictionIds}));
        jurisdictionService.updateJurisdictionsCollector(jurisdictionIds, collectorId,deleteCollectorId)
            .then(
                jurisdiction => {
                    if (jurisdiction['errorCode'] === 200) {
                        showSuccessToast("Collector deleted and Jurisdiction updated successfully!");
                        dispatch(success(jurisdiction));
                        history.push(from.pathname);
                    } else {
                        responseDTOExceptionHandler(jurisdiction);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(jurisdiction) {
        return {type: jurisdictionConstants.UPDATE_JURISDICTIONS_COLLECTOR_REQUEST, jurisdiction}
    }

    function success(jurisdiction) {
        return {type: jurisdictionConstants.UPDATE_JURISDICTIONS_COLLECTOR_SUCCESS, jurisdiction}
    }

    function failure(error) {
        return {type: jurisdictionConstants.UPDATE_JURISDICTIONS_COLLECTOR_FAILURE, error}
    }
}


//export Jurisdiction Data
function exportData(query) {
    return dispatch => {
        dispatch(request());
        jurisdictionService.exportJurisdictionData(query)
            .then(
                jurisdictionData => dispatch(success(jurisdictionData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: jurisdictionConstants.EXPORT_JURISDICTION_DATA_REQUEST}
    }

    function success(jurisdictionData) {
        query.setExportBtn(false);
        return {type: jurisdictionConstants.EXPORT_JURISDICTION_DATA_SUCCESS, jurisdictionData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: jurisdictionConstants.EXPORT_JURISDICTION_DATA_FAILURE, error}
    }
}


//delete jurisdiction Action
function deleteJurisdictionActions(deleteQuery) {
    return dispatch => {
        dispatch(request());
        jurisdictionService.deleteJurisdiction(deleteQuery.deleteReference)
            .then(
                juri => {
                    if (juri['errorCode'] === 200) {
                        showSuccessToast("Jurisdiction deleted successfully!");
                        dispatch(success(juri));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(juri);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: jurisdictionConstants.DELETE_JURISDICTION_REQUEST,}
    }

    function success(juri) {
        return {type: jurisdictionConstants.DELETE_JURISDICTION_SUCCESS, juri}
    }

    function failure(error) {
        return {type: jurisdictionConstants.DELETE_JURISDICTION_FAILURE, error}
    }

}


{/************************************* jurisdictions Actions Ends here ********************************************/
}


{/************************** Reducers starts here. **************************************/
}

//get all jurisdiction reducer
export function getAllJurisdictionReducer(state = {}, action) {
    switch (action.type) {
        case jurisdictionConstants.GET_ALL_JURISDICTION_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case jurisdictionConstants.GET_ALL_JURISDICTION_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.jurisdictions
            };
        case jurisdictionConstants.GET_ALL_JURISDICTION_FAILURE:
            return {};
        default:
            return state
    }
}


//get all jurisdiction reducer
export function getJurisdictionByIdReducer(state = {}, action) {
    switch (action.type) {
        case jurisdictionConstants.GET_JURISDICTION_BY_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case jurisdictionConstants.GET_JURISDICTION_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.jurisdiction
            };
        case jurisdictionConstants.GET_JURISDICTION_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}

//get all jurisdiction reducer
export function getJurisdictionTaxRatesReducer(state = {}, action) {
    switch (action.type) {
        case jurisdictionConstants.GET_JURISDICTION_TAX_LIST_REQUEST:
            return {
                loggingIn: true,
            };
        case jurisdictionConstants.GET_JURISDICTION_TAX_LIST_SUCCESS:
            return {
                loggedIn: true,
                items: action.jurisdiction
            };
        case jurisdictionConstants.GET_JURISDICTION_TAX_LIST_FAILURE:
            return {};
        default:
            return state
    }
}

export function getJurisdictionsByCollectorReducer(state = {}, action) {
    switch (action.type) {
        case jurisdictionConstants.GET_ALL_JURISDICTION_BY_COLLECTOR_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case jurisdictionConstants.GET_ALL_JURISDICTION_BY_COLLECTOR_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.jurisdictions
            };
        case jurisdictionConstants.GET_ALL_JURISDICTION_BY_COLLECTOR_ID_FAILURE:
            return {};
        default:
            return state
    }
}

export function getJurisdictionNextMonthDueReducer(state = {}, action) {
    switch (action.type) {
        case jurisdictionConstants.GET_JURISDICTION_NEXT_MONTH_DUE_REQUEST:
            return {
                loggingIn: true,
            };
        case jurisdictionConstants.GET_JURISDICTION_NEXT_MONTH_DUE_SUCCESS:
            return {
                loggedIn: true,
                items: action.jurisdiction
            };
        case jurisdictionConstants.GET_JURISDICTION_NEXT_MONTH_DUE_FAILURE:
            return {};
        default:
            return state
    }
}

{/*************************** Reducers ends here. **************************************/
}

{/******************************Jurisdiction Services starts here  ****************************************/
}

//get all jurisdictions
export function getAllJurisdiction(query) {
    return axiosInstance.post(`/ajax/jurisdictions`, query).then(
        (response) => {
            return exceptionHandler(response);
        })
}


// create new createJurisdiction
export function createJurisdiction(obj) {
    return axiosInstance.post(`/ajax/create/jurisdiction`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

//get jurisdiction By Id
export function getJurisdictionById(jurisdictionId) {
    return axiosInstance.get(`/ajax/get-jurisdiction-by-id/${jurisdictionId}`).then(
        (response) => {
            return response.data;
        })
}

export function getJurisdictionsByCollectorId(collectorId) {
    return axiosInstance.get(`/ajax/get-jurisdictions-by-collector-id/${collectorId}`).then(
        (response) => {
            return response.data;
        })
}


//update jurisdiction
export function updateJurisdiction(obj) {
    return axiosInstance.put(`/ajax/update/jurisdiction/${obj.id}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function updateJurisdictionsCollector(jurisdictionIds, collectorId,deleteCollectorId) {
    let paramData = serialize({jurisdictionIds: jurisdictionIds, collectorId: collectorId,deleteCollectorId:deleteCollectorId})

    return axiosInstance.put(`/ajax/update-jurisdictions-collector?${paramData}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

//export Jurisdiction Data Service
export async function exportJurisdictionData(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/jurisdiction?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "jurisdictionData");
            }
        })
        .catch(handleExportError);
}


export async function deleteJurisdiction(id) {
    return axiosInstance.delete(`/ajax/delete-jurisdiction-by-id/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export async function getJurisdictionByTaxList(jurisdictionIds) {
    let paramData = serialize({jurisdictionIds: jurisdictionIds})

    return axiosInstance.post(`/ajax/get-jurisdiction-tax-rate?${paramData}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export async function getJurisdictionsNextMonthDues(jurisdictions, hasSuite) {

    return axiosInstance.post(`/ajax/get-jurisdiction-next-month-due`, jurisdictions, hasSuite)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


{/******************************Jurisdiction Services ends here  ****************************************/
}



