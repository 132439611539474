import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import {serialize} from "../../../common/helpers/utils";
import axiosInstance from "../../../axiosInstance";

export const followUpActions = {
    getAllData,
    createFollowUpTypeAction,
    updateFollowUpTypeAction,
    getFollowUpTypeByIdAction,
    deleteFollowUpTypeAction,
    getFollowUpList,
    getFollowUpTypeByTenantAction
}

export const followUpService = {
    getAllDataService,
    createFollowUpType,
    updateFollowUpType,
    deleteFollowUpType,
    getFollowUpTypeById,
    getFollowUpListService,
    getFollowUpTypeByTenantService
}


export const followUpConstant = {

    GET_ALL_FOLLOW_UP_TYPE_REQUEST: 'GET_ALL_FOLLOW_UP_TYPE_REQUEST',
    GET_ALL_FOLLOW_UP_TYPE_SUCCESS: 'GET_ALL_FOLLOW_UP_TYPE_SUCCESS',
    GET_ALL_FOLLOW_UP_TYPE_FAILURE: 'GET_ALL_FOLLOW_UP_TYPE_FAILURE',

    CREATE_FOLLOW_UP_TYPE_REQUEST: 'CREATE_FOLLOW_UP_TYPE_REQUEST',
    CREATE_FOLLOW_UP_TYPE_SUCCESS: 'CREATE_FOLLOW_UP_TYPE_SUCCESS',
    CREATE_FOLLOW_UP_TYPE_FAILURE: 'CREATE_FOLLOW_UP_TYPE_FAILURE',

    UPDATE_FOLLOW_UP_TYPE_REQUEST: 'UPDATE_FOLLOW_UP_TYPE_REQUEST',
    UPDATE_FOLLOW_UP_TYPE_SUCCESS: 'UPDATE_FOLLOW_UP_TYPE_SUCCESS',
    UPDATE_FOLLOW_UP_TYPE_FAILURE: 'UPDATE_FOLLOW_UP_TYPE_FAILURE',

    DELETE_FOLLOW_UP_TYPE_REQUEST: 'DELETE_FOLLOW_UP_TYPE_REQUEST',
    DELETE_FOLLOW_UP_TYPE_SUCCESS: 'DELETE_FOLLOW_UP_TYPE_SUCCESS',
    DELETE_FOLLOW_UP_TYPE_FAILURE: 'DELETE_FOLLOW_UP_TYPE_FAILURE',


    GET_FOLLOW_UP_TYPE_BY_ID_SUCCESS: 'GET_FOLLOW_UP_TYPE_BY_ID_SUCCESS',
    GET_FOLLOW_UP_TYPE_BY_ID_FAILURE: 'GET_FOLLOW_UP_TYPE_BY_ID_FAILURE',
    GET_FOLLOW_UP_TYPE_BY_ID_REQUEST: 'GET_FOLLOW_UP_TYPE_BY_ID_REQUEST',

    GET_FOLLOW_UP_TYPE_LIST_REQUEST: 'GET_FOLLOW_UP_TYPE_LIST_REQUEST',
    GET_FOLLOW_UP_TYPE_LIST_SUCCESS: 'GET_FOLLOW_UP_TYPE_LIST_SUCCESS',
    GET_FOLLOW_UP_TYPE_LIST_FAILURE: 'GET_FOLLOW_UP_TYPE_LIST_FAILURE',

    GET_ALL_FOLLOW_UP_TYPE_LIST_BY_TENANT_REQUEST: 'GET_ALL_FOLLOW_UP_TYPE_LIST_BY_TENANT_REQUEST',
    GET_ALL_FOLLOW_UP_TYPE_LIST_BY_TENANT_SUCCESS: 'GET_ALL_FOLLOW_UP_TYPE_LIST_BY_TENANT_SUCCESS',
    GET_ALL_FOLLOW_UP_TYPE_LIST_BY_TENANT_FAILURE: 'GET_ALL_FOLLOW_UP_TYPE_LIST_BY_TENANT_FAILURE',

}

function getAllData(query) {
    return dispatch => {
        dispatch(request());
        followUpService.getAllDataService(query)
            .then(
                followUp => dispatch(success(followUp)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: followUpConstant.GET_ALL_FOLLOW_UP_TYPE_REQUEST}
    }

    function success(followUp) {
        return {type: followUpConstant.GET_ALL_FOLLOW_UP_TYPE_SUCCESS, followUp}
    }

    function failure(error) {
        return {type: followUpConstant.GET_ALL_FOLLOW_UP_TYPE_FAILURE, error}
    }
}


function getFollowUpList() {
    return dispatch => {
        dispatch(request());
        followUpService.getFollowUpListService()
            .then(
                list => dispatch(success(list)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: followUpConstant.GET_FOLLOW_UP_TYPE_LIST_REQUEST}
    }

    function success(list) {
        return {type: followUpConstant.GET_FOLLOW_UP_TYPE_LIST_SUCCESS, list}
    }

    function failure(error) {
        return {type: followUpConstant.GET_FOLLOW_UP_TYPE_LIST_FAILURE, error}
    }
}


//create Follow up type Action
function createFollowUpTypeAction(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        followUpService.createFollowUpType(obj)
            .then(
                followUpType => {
                    if (followUpType['errorCode'] === 200) {
                        showSuccessToast("Follow Up Type Created Successfully!");
                        dispatch(success(followUpType));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(followUpType);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(followUpType) {
        return {type: followUpConstant.CREATE_FOLLOW_UP_TYPE_REQUEST, followUpType}
    }

    function success(followUpType) {
        return {type: followUpConstant.CREATE_FOLLOW_UP_TYPE_SUCCESS, followUpType}
    }

    function failure(error) {
        return {type: followUpConstant.CREATE_FOLLOW_UP_TYPE_FAILURE, error}
    }
}


function getFollowUpTypeByIdAction(typeId) {
    return dispatch => {
        dispatch(request());
        followUpService.getFollowUpTypeById(typeId)
            .then(
                followType => dispatch(success(followType)),
                error => dispatch(failure(error.toString()))
            );

    };

    function request() {
        return {type: followUpConstant.GET_FOLLOW_UP_TYPE_BY_ID_REQUEST}
    }

    function success(followType) {
        return {type: followUpConstant.GET_FOLLOW_UP_TYPE_BY_ID_SUCCESS, followType}
    }

    function failure(error) {
        return {type: followUpConstant.GET_FOLLOW_UP_TYPE_BY_ID_FAILURE, error}
    }
}


function deleteFollowUpTypeAction(deleteQuery) {
    return dispatch => {
        dispatch(request(deleteQuery));

        followUpService.deleteFollowUpType(deleteQuery.deleteReference)
            .then(
                data => {
                    if (data['errorCode'] === 200) {
                        showSuccessToast("Follow Up Type deleted Successfully!");
                        dispatch(success(data));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(data);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(id) {
        return {type: followUpConstant.DELETE_FOLLOW_UP_TYPE_REQUEST, id}
    }

    function success(data) {
        return {type: followUpConstant.DELETE_FOLLOW_UP_TYPE_SUCCESS, data}
    }

    function failure(error) {
        return {type: followUpConstant.DELETE_FOLLOW_UP_TYPE_FAILURE, error}
    }

}


function updateFollowUpTypeAction(obj, id, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        followUpService.updateFollowUpType(obj, id)
            .then(
                typeData => {
                    if (typeData['errorCode'] === 200) {
                        showSuccessToast("Follow Up Type Updated Successfully!");
                        dispatch(success(typeData));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(typeData);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(typeData) {
        return {type: followUpConstant.UPDATE_FOLLOW_UP_TYPE_REQUEST, typeData}
    }

    function success(typeData) {
        return {type: followUpConstant.UPDATE_FOLLOW_UP_TYPE_SUCCESS, typeData}
    }

    function failure(error) {
        return {type: followUpConstant.UPDATE_FOLLOW_UP_TYPE_FAILURE, error}
    }
}

function getFollowUpTypeByTenantAction(tenant) {
    return dispatch => {
        dispatch(request());
        followUpService.getFollowUpTypeByTenantService(tenant)
            .then(
                followUp => dispatch(success(followUp)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: followUpConstant.GET_ALL_FOLLOW_UP_TYPE_LIST_BY_TENANT_REQUEST}
    }

    function success(followUp) {
        return {type: followUpConstant.GET_ALL_FOLLOW_UP_TYPE_LIST_BY_TENANT_SUCCESS, followUp}
    }

    function failure(error) {
        return {type: followUpConstant.GET_ALL_FOLLOW_UP_TYPE_LIST_BY_TENANT_FAILURE, error}
    }
}


export function getAllFollowUpTypeReducer(state = {}, action) {
    switch (action.type) {
        case followUpConstant.GET_ALL_FOLLOW_UP_TYPE_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case followUpConstant.GET_ALL_FOLLOW_UP_TYPE_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.followUp
            };
        case  followUpConstant.GET_ALL_FOLLOW_UP_TYPE_FAILURE:
            return {};
        default:
            return state
    }
}


export function getFollowUpTypeListReducer(state = {}, action) {
    switch (action.type) {
        case followUpConstant.GET_FOLLOW_UP_TYPE_LIST_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case followUpConstant.GET_FOLLOW_UP_TYPE_LIST_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.list
            };
        case  followUpConstant.GET_FOLLOW_UP_TYPE_LIST_FAILURE:
            return {};
        default:
            return state
    }
}


export function getFollowUpTypeByIdReducer(state = {}, action) {
    switch (action.type) {
        case followUpConstant.GET_FOLLOW_UP_TYPE_BY_ID_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case followUpConstant.GET_FOLLOW_UP_TYPE_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.followType
            };
        case  followUpConstant.GET_FOLLOW_UP_TYPE_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}

export function getFollowUpTypeListByTenantReducer(state = {}, action) {
    switch (action.type) {
        case followUpConstant.GET_ALL_FOLLOW_UP_TYPE_LIST_BY_TENANT_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case followUpConstant.GET_ALL_FOLLOW_UP_TYPE_LIST_BY_TENANT_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.followUp
            };
        case  followUpConstant.GET_ALL_FOLLOW_UP_TYPE_LIST_BY_TENANT_FAILURE:
            return {};
        default:
            return state
    }
}


export function getAllDataService(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/ajax/get-all-follow-up-types?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export function createFollowUpType(obj) {
    return axiosInstance.post(`/ajax/create/follow-up-type`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function updateFollowUpType(obj, id) {
    return axiosInstance.put(`/ajax/update/follow-up-type/${id}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function deleteFollowUpType(id) {
    return axiosInstance.delete(`/ajax/delete/follow-up-type/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function getFollowUpTypeById(codeId) {
    return axiosInstance.get(`/ajax/get-follow-up-type-by-id/${codeId}`).then(
        (response) => {
            return response.data;
        })
}

export function getFollowUpListService() {
    return axiosInstance.get(`/ajax/get-all-follow-up`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export function getFollowUpTypeByTenantService(tenant) {
    return axiosInstance.get(`/ajax/get-all-follow-up-by-tenant/${tenant}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


