import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showErrorToast, showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";
import axiosInstance from "../../../axiosInstance";

//export actions
export const associationActions = {
    getAllData,
    exportData,
    submitAssociationAction,
    getAssociationByIdAction,
    updateAssociationAction,
    createAssociationNoteAction,
    getAssociationLogListAction,
    getAssociationDocumentListAction,
    getSubdivisionByIdAction,
    deleteAssociationByIdAction,
    deleteAssociationNoteByIdAction,
    deleteBulkAssociationAction
};

//export constants
export const associationConstants = {
    GET_ALL_ASSOCIATION_REQUEST: 'GET_ALL_ASSOCIATION_REQUEST',
    GET_ALL_ASSOCIATION_SUCCESS: 'GET_ALL_ASSOCIATION_SUCCESS',
    GET_ALL_ASSOCIATION_FAILURE: 'GET_ALL_ASSOCIATION_FAILURE',

    CREATE_ASSOCIATION_REQUEST: 'CREATE_ASSOCIATION_REQUEST',
    CREATE_ASSOCIATION_SUCCESS: 'CREATE_ASSOCIATION_SUCCESS',
    CREATE_ASSOCIATION_FAILURE: 'CREATE_ASSOCIATION_FAILURE',

    GET_ASSOCIATION_BY_ID_REQUEST: 'GET_ASSOCIATION_BY_ID_REQUEST',
    GET_ASSOCIATION_BY_ID_SUCCESS: 'GET_ASSOCIATION_BY_ID_SUCCESS',
    GET_ASSOCIATION_BY_ID_FAILURE: 'GET_ASSOCIATION_BY_ID_FAILURE',
    GET_ASSOCIATION_BY_ID_REST: 'GET_ASSOCIATION_BY_ID_REST',

    UPDATE_ASSOCIATION_REQUEST: 'UPDATE_ASSOCIATION_REQUEST',
    UPDATE_ASSOCIATION_SUCCESS: 'UPDATE_ASSOCIATION_SUCCESS',
    UPDATE_ASSOCIATION_FAILURE: 'UPDATE_ASSOCIATION_FAILURE',

    CREATE_ASSOCIATION_NOTE_REQUEST: 'CREATE_ASSOCIATION_NOTE_REQUEST',
    CREATE_ASSOCIATION_NOTE_SUCCESS: 'CREATE_ASSOCIATION_NOTE_SUCCESS',
    CREATE_ASSOCIATION_NOTE_FAILURE: 'CREATE_ASSOCIATION_NOTE_FAILURE',

    GET_ASSOCIATION_LOGS_REQUEST: 'GET_ASSOCIATION_LOGS_REQUEST',
    GET_ASSOCIATION_LOGS_SUCCESS: 'GET_ASSOCIATION_LOGS_SUCCESS',
    GET_ASSOCIATION_LOGS_FAILURE: 'GET_ASSOCIATION_LOGS_FAILURE',

    GET_ASSOCIATION_DOCUMENT_LIST_REQUEST: 'GET_ASSOCIATION_DOCUMENT_LIST_REQUEST',
    GET_ASSOCIATION_DOCUMENT_LIST_SUCCESS: 'GET_ASSOCIATION_DOCUMENT_LIST_SUCCESS',
    GET_ASSOCIATION_DOCUMENT_LIST_FAILURE: 'GET_ASSOCIATION_DOCUMENT_LIST_FAILURE',

    GET_SUB_DIVISION_BY_ASSOC_ID_REQUEST: 'GET_SUB_DIVISION_BY_ASSOC_ID_REQUEST',
    GET_SUB_DIVISION_BY_ASSOC_ID_SUCCESS: 'GET_SUB_DIVISION_BY_ASSOC_ID_SUCCESS',
    GET_SUB_DIVISION_BY_ASSOC_ID_FAILURE: 'GET_SUB_DIVISION_BY_ASSOC_ID_FAILURE',

    EXPORT_ASSOCIATION_DATA_REQUEST: 'EXPORT_ASSOCIATION_DATA_REQUEST',
    EXPORT_ASSOCIATION_DATA_SUCCESS: 'EXPORT_ASSOCIATION_DATA_SUCCESS',
    EXPORT_ASSOCIATION_DATA_FAILURE: 'EXPORT_ASSOCIATION_DATA_FAILURE',

    DELETE_ASSOCIATION_REQUEST: 'DELETE_ASSOCIATION_REQUEST',
    DELETE_ASSOCIATION_SUCCESS: 'DELETE_ASSOCIATION_SUCCESS',
    DELETE_ASSOCIATION_FAILURE: 'DELETE_ASSOCIATION_FAILURE',

    DELETE_ASSOCIATION_NOTE_REQUEST: 'DELETE_ASSOCIATION_NOTE_REQUEST',
    DELETE_ASSOCIATION_NOTE_SUCCESS: 'DELETE_ASSOCIATION_NOTE_SUCCESS',
    DELETE_ASSOCIATION_NOTE_FAILURE: 'DELETE_ASSOCIATION_NOTE_FAILURE',

    DELETE_BULK_ASSOCIATION_REQUEST: 'DELETE_BULK_ASSOCIATION_REQUEST',
    DELETE_BULK_ASSOCIATION_SUCCESS: 'DELETE_BULK_ASSOCIATION_SUCCESS',
    DELETE_BULK_ASSOCIATION_FAILURE: 'DELETE_BULK_ASSOCIATION_FAILURE'

};

//export service
export const associationService = {
    getAllAssociations,
    submitAssociation,
    getAssociationById,
    updateAssociation,
    createAssociationNote,
    getAssociationLogList,
    getAssociationDocuments,
    exportAssociation,
    getSubdivisionById,
    deleteAssociationById,
    deleteAssociationNoteById,
    deleteBulkAssociation
};


{/******************************* Association Actions Starts here  ***************************************/
}


function deleteBulkAssociationAction(query) {
    return dispatch => {
        dispatch(request({query}));
        associationService.deleteBulkAssociation(query?.deleteList)
            .then(
                association => {
                    if (association['errorCode'] === 200) {
                        showSuccessToast("Associations deleted successfully!");
                        dispatch(success(association));
                        dispatch(getAllData(query));
                        query.setDeleteItemList([]);
                    } else {
                        responseDTOExceptionHandler(association);
                    }
                    query.setShowBulkDeleteConfirmation(false);
                    query.setDisableDelete(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    query.setShowBulkDeleteConfirmation(false);
                    query.setDisableDelete(false);
                }
            );
    };

    function request(associationIds) {
        return {type: associationConstants.DELETE_BULK_ASSOCIATION_REQUEST, associationIds}
    }

    function success(associationIds) {
        return {type: associationConstants.DELETE_BULK_ASSOCIATION_SUCCESS, associationIds}
    }

    function failure(error) {
        return {type: associationConstants.DELETE_BULK_ASSOCIATION_FAILURE, error}
    }

}
//delete association note Action
function deleteAssociationNoteByIdAction(noteId, setDisable, setShowDeleteConfirm, associationId) {
    return dispatch => {
        dispatch(request());
        associationService.deleteAssociationNoteById(noteId)
            .then(
                association => {
                    if (association['errorCode'] === 200) {
                        dispatch(associationActions.getAssociationByIdAction(associationId));
                        showSuccessToast("Association note deleted successfully!");
                        dispatch(success(association));
                        setShowDeleteConfirm(false);
                    } else {
                        responseDTOExceptionHandler(association);
                    }
                    setDisable(false)
                },
                error => {
                    setDisable(false)
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: associationConstants.DELETE_ASSOCIATION_NOTE_REQUEST}
    }

    function success(association) {
        return {type: associationConstants.DELETE_ASSOCIATION_NOTE_SUCCESS, association}
    }

    function failure(error) {
        return {type: associationConstants.DELETE_ASSOCIATION_NOTE_FAILURE, error}
    }
}


//get all County Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());

        associationService.getAllAssociations(query)
            .then(
                associations => dispatch(success(associations)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: associationConstants.GET_ALL_ASSOCIATION_REQUEST}
    }

    function success(associations) {
        return {type: associationConstants.GET_ALL_ASSOCIATION_SUCCESS, associations}
    }

    function failure(error) {
        return {type: associationConstants.GET_ALL_ASSOCIATION_FAILURE, error}
    }
}


function exportData(query) {
    return dispatch => {
        dispatch(request());
        associationService.exportAssociation(query)
            .then(
                associationData => dispatch(success(associationData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: associationConstants.EXPORT_ASSOCIATION_DATA_REQUEST}
    }

    function success(exportData) {
        query.setExportBtn(false);
        return {type: associationConstants.EXPORT_ASSOCIATION_DATA_SUCCESS, exportData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: associationConstants.EXPORT_ASSOCIATION_DATA_FAILURE, error}
    }

}

//create association Action
function submitAssociationAction(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        associationService.submitAssociation(obj)
            .then(
                association => {
                    if (association['errorCode'] === 200) {
                        showSuccessToast("Association added successfully!");
                        dispatch(success(association));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(association);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(association) {
        return {type: associationConstants.CREATE_ASSOCIATION_REQUEST, association}
    }

    function success(association) {
        return {type: associationConstants.CREATE_ASSOCIATION_SUCCESS, association}
    }

    function failure(error) {
        return {type: associationConstants.CREATE_ASSOCIATION_FAILURE, error}
    }
}


//get Association by id
function getAssociationByIdAction(associationId, isReset = false) {

    return dispatch => {
        if (isReset === false) {
            dispatch(request());

            associationService.getAssociationById(associationId)
                .then(
                    association => dispatch(success(association)),
                    error => dispatch(failure(error.toString()))
                );
        } else {
            dispatch(rest());
        }
    };


    function request() {
        return {type: associationConstants.GET_ASSOCIATION_BY_ID_REQUEST}
    }

    function success(association) {
        return {type: associationConstants.GET_ASSOCIATION_BY_ID_SUCCESS, association}
    }

    function failure(error) {
        return {type: associationConstants.GET_ASSOCIATION_BY_ID_FAILURE, error}
    }

    function rest() {
        return {type: associationConstants.GET_ASSOCIATION_BY_ID_REST}
    }
}


// update Association Action
function updateAssociationAction(obj, id, setDisable, setEditable, setShowVerifiedByModal) {
    return dispatch => {
        dispatch(request({obj}));

        associationService.updateAssociation(obj, id)
            .then(
                association => {
                    if (association['errorCode'] === 200) {
                        showSuccessToast("Association updated successfully!");
                        dispatch(success(association));
                        setEditable(false)
                        setShowVerifiedByModal(false)
                        dispatch(getAssociationByIdAction(id));
                    } else {
                        setDisable(false)
                        setShowVerifiedByModal(false)
                        responseDTOExceptionHandler(association);
                    }
                },
                error => {
                    setDisable(false)
                    setDisable(false)
                    setShowVerifiedByModal(false)
                    dispatch(getAssociationByIdAction(id));
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(association) {
        return {type: associationConstants.UPDATE_ASSOCIATION_REQUEST, association}
    }

    function success(association) {
        return {type: associationConstants.UPDATE_ASSOCIATION_SUCCESS, association}
    }

    function failure(error) {
        return {type: associationConstants.UPDATE_ASSOCIATION_FAILURE, error}
    }

}


// create Add Note
function createAssociationNoteAction(associationId, obj, setDisable, setShow) {
    return dispatch => {
        dispatch(request(obj));
        associationService.createAssociationNote(associationId, obj)
            .then(
                note => {
                    if (note['errorCode'] === 200) {
                        showSuccessToast("Note created successfully!");
                        dispatch(success(note));
                        setShow(false)
                        dispatch(getAssociationByIdAction(associationId));
                    } else {
                        responseDTOExceptionHandler(note);
                    }
                    setDisable(false)
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(note) {
        return {type: associationConstants.CREATE_ASSOCIATION_NOTE_REQUEST, note}
    }

    function success(note) {
        return {type: associationConstants.CREATE_ASSOCIATION_NOTE_SUCCESS, note}
    }

    function failure(error) {
        return {type: associationConstants.CREATE_ASSOCIATION_NOTE_FAILURE, error}
    }
}


//get Association note by id
function getAssociationLogListAction(associationId) {
    return dispatch => {
        dispatch(request());

        associationService.getAssociationLogList(associationId)
            .then(
                associationLogsList => dispatch(success(associationLogsList)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: associationConstants.GET_ASSOCIATION_LOGS_REQUEST}
    }

    function success(associationLogsList) {
        return {type: associationConstants.GET_ASSOCIATION_LOGS_SUCCESS, associationLogsList}
    }

    function failure(error) {
        return {type: associationConstants.GET_ASSOCIATION_LOGS_FAILURE, error}
    }
}


//get Association Certificate note by id


//get association documents action
function getAssociationDocumentListAction(associationId) {
    return dispatch => {
        dispatch(request());
        associationService.getAssociationDocuments(associationId)
            .then(
                docs => dispatch(success(docs)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: associationConstants.GET_ASSOCIATION_DOCUMENT_LIST_REQUEST}
    }

    function success(docs) {
        return {type: associationConstants.GET_ASSOCIATION_DOCUMENT_LIST_SUCCESS, docs}
    }

    function failure(error) {
        return {type: associationConstants.GET_ASSOCIATION_DOCUMENT_LIST_FAILURE, error}
    }
}

//get Association by id
function getSubdivisionByIdAction(associationId) {
    return dispatch => {
        dispatch(request());

        associationService.getSubdivisionById(associationId)
            .then(
                subdivisions => dispatch(success(subdivisions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: associationConstants.GET_SUB_DIVISION_BY_ASSOC_ID_REQUEST}
    }

    function success(subdivisions) {
        return {type: associationConstants.GET_SUB_DIVISION_BY_ASSOC_ID_SUCCESS, subdivisions}
    }

    function failure(error) {
        return {type: associationConstants.GET_SUB_DIVISION_BY_ASSOC_ID_FAILURE, error}
    }
}

//delete association Action
function deleteAssociationByIdAction(deleteQuery) {
    return dispatch => {
        dispatch(request());
        associationService.deleteAssociationById(deleteQuery.deleteReference)
            .then(
                association => {
                    if (association['errorCode'] === 200) {
                        showSuccessToast("Association deleted successfully!");
                        dispatch(success(association));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(association);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    deleteQuery.setDisable(false)
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: associationConstants.DELETE_ASSOCIATION_REQUEST}
    }

    function success(association) {
        return {type: associationConstants.DELETE_ASSOCIATION_SUCCESS, association}
    }

    function failure(error) {
        return {type: associationConstants.DELETE_ASSOCIATION_FAILURE, error}
    }
}

{/************************************ Association Actions Ends here *******************************************/
}


{/************************* Reducers starts here. *************************************/
}

//get all city reducer
export function getAllAssociationsReducer(state = {}, action) {
    switch (action.type) {
        case associationConstants.GET_ALL_ASSOCIATION_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case associationConstants.GET_ALL_ASSOCIATION_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.associations
            };
        case associationConstants.GET_ALL_ASSOCIATION_FAILURE:
            return {};
        default:
            return state
    }
}


//get all ASSOCIATION  BY ID reducer
export function getAssociationByIdReducer(state = {}, action) {
    switch (action.type) {
        case associationConstants.GET_ASSOCIATION_BY_ID_REQUEST:
            return {
                loading: true,
                loggingIn: true,
            };
        case associationConstants.GET_ASSOCIATION_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.association
            };
        case associationConstants.GET_ASSOCIATION_BY_ID_FAILURE:
            return {};
        case associationConstants.GET_ASSOCIATION_BY_ID_REST:
            return {};
        default:
            return state
    }
}


export function getAssociationDocumentListReducer(state = {}, action) {
    switch (action.type) {
        case associationConstants.GET_ASSOCIATION_DOCUMENT_LIST_REQUEST:
            return {};

        case associationConstants.GET_ASSOCIATION_DOCUMENT_LIST_SUCCESS:
            return {
                loggedIn: true,
                items: action.docs
            };
        case associationConstants.GET_ASSOCIATION_DOCUMENT_LIST_FAILURE:
            return {};

        default:
            return state
    }
}


//get all LOGS reducer
export function getAssociationLogsListReducer(state = {}, action) {
    switch (action.type) {
        case associationConstants.GET_ASSOCIATION_LOGS_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case associationConstants.GET_ASSOCIATION_LOGS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.associationLogsList
            };
        case associationConstants.GET_ASSOCIATION_LOGS_FAILURE:
            return {};
        default:
            return state
    }
}

//get subdivision by  city reducer
export function getAllSubdivisionByAssocIdReducer(state = {}, action) {
    switch (action.type) {
        case associationConstants.GET_SUB_DIVISION_BY_ASSOC_ID_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case associationConstants.GET_SUB_DIVISION_BY_ASSOC_ID_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.subdivisions
            };
        case associationConstants.GET_SUB_DIVISION_BY_ASSOC_ID_FAILURE:
            return {
                loading: false
            };
        default:
            return state
    }
}


{/************************** Reducers ends here. *************************************/
}

{/******************************Association Services starts here  ****************************************/
}

//get all Associations
export function getAllAssociations(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/ajax/getAllAssociations?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function exportAssociation(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/association?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "AssociationData");
            }
        })
        .catch(handleExportError);
}


// create new Association
export function submitAssociation(obj) {
    return axiosInstance.post(`/ajax/create/association`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function getAssociationById(id) {
    return axiosInstance.get(`/ajax/get-association-by-id/${id}`).then(
        (response) => {
            return response.data;
        })
}

export function updateAssociation(obj, id) {
    return axiosInstance.put(`/ajax/update/association/${id}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


// create Note
export function createAssociationNote(associationId, obj) {
    return axiosInstance.post(`/ajax/create-association-note/${associationId}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function getAssociationLogList(associationId) {
    return axiosInstance.get(`/ajax/get-association-logs/${associationId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export function getAssociationDocuments(associationId) {
    return axiosInstance.get(`/ajax/get-association-document-list/${associationId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


//get subdivisions by association id
export function getSubdivisionById(associationId) {
    return axiosInstance.get(`/ajax/get-sub-divisions-by-association-id/${associationId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export async function deleteAssociationById(id) {
    return axiosInstance.delete(`/ajax/delete-association-by-id/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export async function deleteAssociationNoteById(id) {
    return axiosInstance.delete(`/ajax/deleteAssociationNote/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


export function deleteBulkAssociation(associationIds) {

    let associationIdsList = {associationIds: associationIds};
    let paramData = serialize(associationIdsList)
    return axiosInstance.delete(`/ajax/delete/bulk/association?${paramData}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            console.log(error)
        })
}



{/******************************Association Services ends here  ****************************************/
}

