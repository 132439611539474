import {alertActions} from "../alert/alert";
import {history} from "../../common/helpers/history";
import exceptionHandler, {responseDTOExceptionHandler} from "../../common/helpers/exceptionHandler";
import {homeActions} from "../home/home";
import {serialize} from "../../common/helpers/utils";
import axiosInstance from "../../axiosInstance";


//export actions
export const authenticationActions = {
    loginActions,
    authenticateImpersonatorUserAction
};

//export constants
export const authenticationConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',

    IMPERSONATE_REQUEST: 'IMPERSONATE_REQUEST',
    IMPERSONATE_SUCCESS: 'IMPERSONATE_SUCCESS',
    IMPERSONATE_FAILURE: 'IMPERSONATE_FAILURE',
};

//export service
export const authenticationService = {
    login,
    authenticateImpersonatorUser,
};

{/********************** Actions started here. *****************************/
}

function loginActions(username, password, from, setShowChangePasswordConfirmationModal) {
    return dispatch => {

        dispatch(request(username));

        authenticationService.login(username, password)
            .then(
                result => {
                    if(result['errorCode'] === 200){
                        if(result.userForcePasswordResetStatus){
                            setShowChangePasswordConfirmationModal(true);
                        }else{

                            dispatch(success(result));
                            localStorage.setItem('user', "loggedIn");
                            localStorage.setItem("daysToExpirePassword", JSON.stringify(result['daysToExpirePassword']));
                            const token = result?.customAuthTokenDTO?.token;
                            if (token) {
                                localStorage.setItem('authToken', token);  // Store token with a key like 'authToken'
                            } else {
                                console.warn("Token not found in the response.");
                            }
                            if (result['customAuthTokenDTO']['isUserHaveTeam'] !== null) {
                                localStorage.setItem("isUserHaveTeam", JSON.stringify(result['customAuthTokenDTO']['userHaveTeam']));

                            }
                            localStorage.setItem("isUserManager", JSON.stringify(result['customAuthTokenDTO']['isUserManager']));
                            history.push(from);

                            dispatch(success(result));
                            dispatch(homeActions.fetchAuthoritiesAction());

                        }
                    } else {
                        responseDTOExceptionHandler(result);
                        dispatch(failure(result));
                    }
                },
                error => {
                    localStorage.clear();
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };


    function request(user) {
        return {type: authenticationConstants.LOGIN_REQUEST, user}
    }

    function success(result) {
        return {type: authenticationConstants.LOGIN_SUCCESS, result}
    }

    function failure(error) {
        return {type: authenticationConstants.LOGIN_FAILURE, error}
    }
}

function authenticateImpersonatorUserAction(username, from) {
    return dispatch => {

        dispatch(request(username));

        authenticationService.authenticateImpersonatorUser(username)
            .then(
                result => {
                    if (result['errorCode'] === 200) {
                        dispatch(homeActions.resetReducerAction("fetchAuthoritiesReducer"));
                        localStorage.setItem('user', "loggedIn");
                        localStorage.setItem("daysToExpirePassword", JSON.stringify(result['daysToExpirePassword']));

                        if (result['customAuthTokenDTO']['isUserHaveTeam'] !== null) {
                            localStorage.setItem("isUserHaveTeam", JSON.stringify(result['customAuthTokenDTO']['userHaveTeam']));

                        }
                        localStorage.setItem("isUserManager", JSON.stringify(result['customAuthTokenDTO']['isUserManager']));
                        history.push(from);
                        dispatch(success(result));
                        window.location.reload();
                    } else {
                        responseDTOExceptionHandler(result);
                    }
                },
                error => {
                    localStorage.clear();
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };


    function request(user) {
        return {type: authenticationConstants.IMPERSONATE_REQUEST, user}
    }

    function success(result) {
        return {type: authenticationConstants.IMPERSONATE_SUCCESS, result}
    }

    function failure(error) {
        return {type: authenticationConstants.IMPERSONATE_FAILURE, error}
    }
}

{/********************** Actions ended here. *****************************/
}

{/********************** Reducers started here. *****************************/
}

//reducer
const initialState = {};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case authenticationConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
            };
        case authenticationConstants.LOGIN_SUCCESS:
            return {
                loggedIn: false,
                user: action.result
            };
        case authenticationConstants.LOGIN_FAILURE:
            return {
                loggingIn: false,

            };
        case authenticationConstants.LOGOUT:
            return {};
        default:
            return state
    }
}


{/********************************* Reducers ended here. ********************************************/
}


{/******************************* Services started here. ***************************************************/
}

function login(username, password) {
    const requestOptions = {username: username, password: password}
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.replaceAll("/", "&");

    return axiosInstance.post(`/ajax/authenticate/${timeZone}`, requestOptions).then(
        (response) => {
            return exceptionHandler(response);
        })
}

async function authenticateImpersonatorUser(username) {
    const requestOptions = { username };
    const paramData = serialize(requestOptions);

    try {
        const response = await axiosInstance.post(`/ajax/authenticate-impersonator?${paramData}`);
        const handledResponse = exceptionHandler(response);
        if (handledResponse?.impersonateUserToken) {
            localStorage.setItem("authToken", handledResponse.impersonateUserToken);
        }
        return handledResponse;
    } catch (error) {
        console.error("Authentication failed:", error);
        throw error;  
    }
}


{/**************************** Services ended here. ***************************/
}



