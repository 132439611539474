    import { serialize} from "../../../common/helpers/utils";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";

import {showSuccessToast} from "../../../common/components/Toast";
import {alertActions} from "../../alert/alert";
import {history} from "../../../common/helpers/history";
import axiosInstance from "../../../axiosInstance";

//export actions
export const highRiskAccountActions = {
    getAllHighRiskAccountAction,
    verifyHighRiskAccountByIdAction

};

//export constants
export const highRiskAccountConstants = {
    GET_ALL_HIGH_RISK_ACCOUNT_REQUEST: 'GET_ALL_HIGH_RISK_ACCOUNT_REQUEST',
    GET_ALL_HIGH_RISK_ACCOUNT_SUCCESS: 'GET_ALL_HIGH_RISK_ACCOUNT_SUCCESS',
    GET_ALL_HIGH_RISK_ACCOUNT_FAILURE: 'GET_ALL_HIGH_RISK_ACCOUNT_FAILURE',

    VERIFY_HIGH_RISK_ACCOUNT_REQUEST: 'VERIFY_HIGH_RISK_ACCOUNT_REQUEST',
    VERIFY_HIGH_RISK_ACCOUNT_SUCCESS: 'VERIFY_HIGH_RISK_ACCOUNT_SUCCESS',
    VERIFY_HIGH_RISK_ACCOUNT_FAILURE: 'VERIFY_HIGH_RISK_ACCOUNT_FAILURE'
};

//export service
export const highRiskAccountService = {
    getAllHighRiskAccount,
    verifyHighRiskAccountByIdService
};

{/************************************* High Risk Account Actions Starts here  ******************************************/
}

function getAllHighRiskAccountAction(query) {
    return dispatch => {
        dispatch(request());

        highRiskAccountService.getAllHighRiskAccount(query)

            .then(
                highRiskAccounts => dispatch(success(highRiskAccounts)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: highRiskAccountConstants.GET_ALL_HIGH_RISK_ACCOUNT_REQUEST}
    }

    function success(highRiskAccounts) {
        return {type: highRiskAccountConstants.GET_ALL_HIGH_RISK_ACCOUNT_SUCCESS, highRiskAccounts}
    }

    function failure(error) {
        return {type: highRiskAccountConstants.GET_ALL_HIGH_RISK_ACCOUNT_FAILURE, error}
    }
}

function verifyHighRiskAccountByIdAction(obj, from, setDisable, id, setShow, query) {
    return dispatch => {
        dispatch(request({obj}));

        highRiskAccountService.verifyHighRiskAccountByIdService(obj,id)
            .then(
                highRiskAccount => {
                    if (highRiskAccount['errorCode'] === 200) {
                        showSuccessToast("High Risk Account Verified successfully!");
                        dispatch(success(highRiskAccount));
                        dispatch(highRiskAccountActions.getAllHighRiskAccountAction(query));
                        setShow(false)
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(highRiskAccount);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() {
        return {type: highRiskAccountConstants.VERIFY_HIGH_RISK_ACCOUNT_REQUEST,}
    }

    function success(highRiskAccount) {
        return {type: highRiskAccountConstants.VERIFY_HIGH_RISK_ACCOUNT_SUCCESS, highRiskAccount}
    }

    function failure(error) {
        return {type: highRiskAccountConstants.VERIFY_HIGH_RISK_ACCOUNT_FAILURE, error}
    }

}

{/***************************** High Risk Account Actions Ends here **********************************/
}


{/****************** Reducers starts here. **************************************/
}

export function getAllPagedHighRiskAccountReducer(highRiskAccount = {}, action) {
    switch (action.type) {
        case highRiskAccountConstants.GET_ALL_HIGH_RISK_ACCOUNT_REQUEST:
            return {
                ...highRiskAccount,
                loading: true,
                loggingIn: true,
            };
        case highRiskAccountConstants.GET_ALL_HIGH_RISK_ACCOUNT_SUCCESS:
            return {
                loading: false,
                loggingIn: true,
                items: action.highRiskAccounts
            };
        case highRiskAccountConstants.GET_ALL_HIGH_RISK_ACCOUNT_FAILURE:
            return {};
        default:
            return highRiskAccount
    }
}

{/*************************** Reducers ends here. **************************************/
}

{/******************************High Risk Account Services starts here  ****************************************/
}
export async function getAllHighRiskAccount(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/ajax/high-risk-account/search?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function verifyHighRiskAccountByIdService(obj,highRiskAccountId) {
    return axiosInstance.post(`/ajax/high-risk-account/verify/${highRiskAccountId}`, obj)
        .then((response) => {
            return response.data
        }).catch((error) => {
            return Promise.reject(error);
        })
}
{/******************************High Risk Account Services ends here  ****************************************/
}




