import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {alertActions} from "../../alert/alert";
import {showSuccessToast} from "../../../common/components/Toast";
import {serialize} from "../../../common/helpers/utils";
import axiosInstance from "../../../axiosInstance";

//export actions
export const managerActions = {
    getAllData,
    addManagerAction,
    deleteManagerAction
}


//export constants
export const managerConstants = {
    GET_ALL_MANAGER_REQUEST: 'GET_ALL_MANAGER_REQUEST',
    GET_ALL_MANAGER_SUCCESS: 'GET_ALL_MANAGER_SUCCESS',
    GET_ALL_MANAGER_FAILURE: 'GET_ALL_MANAGER_FAILURE',

    ADD_MANAGER_REQUEST: 'ADD_MANAGER_REQUEST',
    ADD_MANAGER_SUCCESS: 'ADD_MANAGER_SUCCESS',
    ADD_MANAGER_FAILURE: 'ADD_MANAGER_FAILURE',

    DELETE_MANAGER_REQUEST: 'DELETE_MANAGER_REQUEST',
    DELETE_MANAGER_SUCCESS: 'DELETE_MANAGER_SUCCESS',
    DELETE_MANAGER_FAILURE: 'DELETE_MANAGER_FAILURE',

}


//export service
export const managerService = {
    getAllMember,
    addManager,
    deleteManager

};

{/*********** Actions started here. ******************/
}


//get all users-manager Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());
        managerService.getAllMember(query)
            .then(
                managers => dispatch(success(managers)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: managerConstants.GET_ALL_MANAGER_REQUEST}
    }

    function success(managers) {
        return {type: managerConstants.GET_ALL_MANAGER_SUCCESS, managers}
    }

    function failure(error) {
        return {type: managerConstants.GET_ALL_MANAGER_FAILURE, error}
    }
}


//ADD Team Manager Action
function addManagerAction(userId, teamId, setDisable, customFilter, setShow) {
    return dispatch => {
        dispatch(request({teamId}));
        managerService.addManager(userId, teamId)
            .then(
                teamMember => {
                    if (teamMember['errorCode'] === 200) {
                        showSuccessToast("Team Leader added successfully!");
                        dispatch(success(teamMember));
                        dispatch(getAllData(customFilter));
                        setShow(false);
                    } else {
                        responseDTOExceptionHandler(teamMember);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    setDisable(false);
                }
            );
    };

    function request(teamName) {
        return {type: managerConstants.ADD_MANAGER_REQUEST, teamName}
    }

    function success(teamMember) {
        return {type: managerConstants.ADD_MANAGER_SUCCESS, teamMember}
    }

    function failure(error) {
        return {type: managerConstants.ADD_MANAGER_FAILURE, error}
    }
}


// delete Team Rule Action
function deleteManagerAction(userId, teamId, setDisable, setShowConfirmation, customFilter) {
    return dispatch => {
        dispatch(request({teamId}));
        managerService.deleteManager(userId, teamId)
            .then(
                teamMember => {
                    if (teamMember['errorCode'] === 200) {
                        showSuccessToast("Team Leader removed successfully!");
                        dispatch(success(teamMember));
                        dispatch(getAllData(customFilter));
                    } else {
                        responseDTOExceptionHandler(teamMember);
                    }
                    setDisable(false);
                    setShowConfirmation(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: managerConstants.DELETE_MANAGER_REQUEST}
    }

    function success(teamMember) {
        return {type: managerConstants.DELETE_MANAGER_SUCCESS, teamMember}
    }

    function failure(error) {
        return {type: managerConstants.DELETE_MANAGER_FAILURE, error}
    }
}
/*********** Actions ended here. ******************/


/*********** Reducers started here. ******************/

export function getAllManagerReducer(state = {}, action) {
    switch (action.type) {
        case managerConstants.GET_ALL_MANAGER_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case managerConstants.GET_ALL_MANAGER_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.managers
            };
        case managerConstants.GET_ALL_MANAGER_FAILURE:
            return {};
        default:
            return state
    }
}


/*********** Reducers ended here. ******************/


/*********** Services started here. ******************/

//get teamRuleMember by team id service
export function getAllMember(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/get-team-manager?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

// add new team member
export function addManager(userId, teamId) {
    return axiosInstance.post(`/ajax/add/team/manager/${userId}/${teamId}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            console.log(error)
        })
}


// //delete team member by userId and teamName
export function deleteManager(userId, teamId) {
    return axiosInstance.delete(`/ajax/delete-team-manager-by-id/${userId}/${teamId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}




/*********** Services ended here. ******************/


