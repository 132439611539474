import Draggable from "react-draggable";
import {
    createFormattedDate,
    loadPdf,
    onDraggableStart,
    selectDocument
} from "../helpers/utils";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {orderActions} from "../../features/order/orders";
import {associationActions} from "../../features/Hoa/association/association";
import {collectorActions} from "../../features/data/collector/collector";
import {subdivisionAction} from "../../features/data/subDivision/subDivision";
import DataTable from "./DataTable";
import "../../features/order/templates/Editor/Editor.css";
import {homeActions} from "../../features/home/home";
import CommonChosenInput from "./CommonChosenInput";
import {Form} from "./Form";
import {fetchPdf, handleFileAction} from "../../common/helpers/utils";
import {useParams} from "react-router";


export default function MultiSelectDocModal({
                                                show,
                                                setShow,
                                                authorities,
                                                documentTypeList,
                                                headContent,
                                                path
                                            }) {

    const [bounds, setBounds] = useState({left: 0, right: 0});
    const draggleRef = useRef(null);
    const [docList, setDocList] = useState([]);
    const [date, setDate] = useState("");
    const [data, setData] = useState([]);
    const [props, setProps] = useState(null);
    const dispatch = useDispatch();
    const [fetchAction, setFetchAction] = useState(null);

    const selector = (state) => {
        if (headContent === "Documents") {
            return state.getAllDocumentReducer;
        } else {
            return state.getAllTgxFilesReducer;
        }

    };


    const documentList = useSelector(selector);
    const [handleClick, setHandleClick] = useState(false);
    const [disable, setDisable] = useState(false);
    const [nonViewable, setNonViewable] = useState(false);
    let { collectorId, orderId, associationId, subdivisionId } = useParams();
    let relationId = collectorId || orderId || associationId || subdivisionId;

    const customFilter = {
        "sortOrder": "desc",
        "orderId": relationId,
        "orderFileType": documentTypeList,
        "relationalId" : relationId
    };

    function dismissModal(e) {
        e.preventDefault();
        setShow(false);
    }

    const viewableFileExtensions = ['.pdf', '.jpg', '.jpeg', '.png', '.txt'];

    const isViewableFile = (fileName) => {
        const fileExtension = fileName.slice(fileName.lastIndexOf('.')).toLowerCase();
        return viewableFileExtensions.includes(fileExtension);
    };

    useEffect(() => {
        if (documentList.items && documentList.items.data && documentList.items.data.length > 0) {
            const firstFile = documentList.items.data[0];
            setProps(firstFile.id);
            // Check if the file is viewable
            if (isViewableFile(firstFile.filename)) {
                loadPdf(firstFile.id); // loadPdf should set the iframe src
            } else {
                setNonViewable(true); // Indicate the file isn't viewable
            }
        }
    }, [documentList]);


    const downloadAction = () => {
        if (docList.length > 0) {
            setDisable(true);
            try {
                for (const doc of docList) {
                    handleFileAction(doc.id, path, true, doc.filename, doc.tgxFileType, doc.fileSize);
                }
                setShow(false);
            } finally {
                setDisable(false);
            }
        }
    }


    let columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "id",
                disableSortBy: true,
                Cell: (props) => {
                    return <>
                        <input type="checkbox" id={props.row.original.id} onChange={(e) => {
                            if (docList.length !== 0) {
                                const result = docList.map(e => e.id).includes(props.row.original.id);
                                if (result) {
                                    const k = docList.map(e => e.id).indexOf(props.row.original.id);
                                    if (k > -1) {
                                        docList.splice(k, 1);
                                    }
                                } else {
                                    docList.push(props.row.original);
                                }
                            } else {
                                docList.push(props.row.original);
                            }
                            setDate(props.row.original.id + Date.now());
                        }} onClick={(e) => {
                            setData(props.data);
                            setProps(props.row.original.id);
                            loadPdf(props.row.original.id);
                            setDate(props.row.original.id + Date.now())
                        }}
                               defaultChecked={docList.map(e => e.id).indexOf(props.row.original.id) > -1}/>
                    </>

                }
            },
            {
                Header: "Name", accessor: "filename",
                Cell: (props) => {
                    return selectDocument(props, setData, setProps);
                }
            },
            {
                Header: "Type", accessor: headContent == "Documents"?"fileType":"tgxFileType",
                Cell: (props) => {
                    return selectDocument(props, setData, setProps);
                }
            }
        ],
        [date, docList]
    );

    return (
        <>
            <div className={show === false ? "modal fade" : "modal fade show customModal"} id="noteAddModal"
                 tabIndex="-1"
                 role="dialog" style={show === false ? {display: 'none'} : {display: 'block'}}
                 aria-labelledby="noteAddModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">

                <Draggable handle=".handler"
                           bounds={bounds}
                           onStart={(event, uiData) => onDraggableStart(event, uiData, draggleRef, bounds, setBounds)}>

                    <div className="modal-dialog delivery-modal-dialog doc_delivery_modal" role="document"
                         ref={draggleRef}>
                        <div className="modal-content group_main_form">
                            <div className={"modal-header handler"}>
                                <h4 className="modal-title" id="customerModalLabel">{"Download Documents"}</h4>
                                <i className="fa fa-arrows ml-3 mt-1  modal_drag_context_icon"/>
                                <button type="button" className="close" onClick={dismissModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>


                            <div className="modal-body">

                                <p className="ml-1">{"Select the documents you would like to download"}</p>

                                <div className={"row"}>
                                    <div className={"col-6"}>
                                        <div
                                            className={"table_data"}>
                                            <div className="table-responsive jurisdictions_selector">

                                                <DataTable columns={columns}
                                                           fetchAction={headContent == "Documents" ? orderActions.getAllFileData : homeActions.getAllTgxFileByRelationIdAction}
                                                           selector={selector} exportAction={null}
                                                           defaultPageSize={15}
                                                           formCheckForModals={true}
                                                           handleClick={handleClick}
                                                           setHandleClick={setHandleClick}
                                                           customFilter={customFilter}
                                                           appendUrl={false}
                                                />
                                            </div>
                                        </div>

                                    </div>


                                    <div className={"col-6"}>
                                        {nonViewable ? (
                                            <div style={{
                                                width: '100%',
                                                minHeight: '500px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: '1px solid #ddd'
                                            }}>
                                                <p>This file type cannot be displayed. Please download to view.</p>
                                            </div>
                                        ) : (
                                            <iframe src=""
                                                    style={{
                                                        width: '100%',
                                                        minHeight: '500px',
                                                        overflow: 'visible'
                                                    }}
                                                    width="100%"
                                                    height="100%"
                                                    scrolling="no"
                                                    frameBorder="0"
                                            />)}
                                    </div>
                                </div>


                            </div>

                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                if (docList.length > 0) {
                                    downloadAction();
                                }
                            }} fields={<div className="d-flex justify-content-between modal-footer">
                                <div>
                                    <button className="cm_btn cm_bg" onClick={dismissModal}>Close</button>

                                    {disable === true ?
                                        <button disabled={true}
                                                className={"cm_btn btn_success_spinner ml-0"}>
                                            <>
                                                Downloading...
                                                <span className="spinner"/>
                                            </>
                                        </button>
                                        :
                                        <>
                                            <button
                                                className={docList.length > 0 ? "cm_btn ml-0" : "cm_btn ml-0 cm_bg disable_add_btn"}>Download
                                            </button>

                                        </>

                                    }
                                </div>
                            </div>}>

                            </Form>

                        </div>
                    </div>
                </Draggable>
            </div>
        </>
    )
}