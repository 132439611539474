import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import {serialize} from "../../../common/helpers/utils";
import axiosInstance from "../../../axiosInstance";


export const noteLogicActions = {
    getAllData,
    submitNoteLogicAction,
    getNoteLogicByIdAction,
    updateNoteLogicAction,
    deleteNoteLogicAction
};

export const noteLogicService = {
    getAllNoteLogic,
    submitNoteLogic,
    getNoteLogicById,
    updateNoteLogic,
    deleteNoteLogic
};

export const noteLogicConstant = {

    GET_ALL_NOTE_LOGIC_REQUEST: 'GET_ALL_NOTE_LOGIC_REQUEST',
    GET_ALL_NOTE_LOGIC_SUCCESS: 'GET_ALL_NOTE_LOGIC_SUCCESS',
    GET_ALL_NOTE_LOGIC_FAILURE: 'GET_ALL_NOTE_LOGIC_FAILURE',

    CREATE_NOTE_LOGIC_REQUEST: 'CREATE_NOTE_LOGIC_REQUEST',
    CREATE_NOTE_LOGIC_SUCCESS: 'CREATE_NOTE_LOGIC_SUCCESS',
    CREATE_NOTE_LOGIC_FAILURE: 'CREATE_NOTE_LOGIC_FAILURE',


    GET_NOTE_LOGIC_BY_ID_REQUEST: 'GET_NOTE_LOGIC_BY_ID_REQUEST',
    GET_NOTE_LOGIC_BY_ID_SUCCESS: 'GET_NOTE_LOGIC_BY_ID_SUCCESS',
    GET_NOTE_LOGIC_BY_ID_FAILURE: 'GET_NOTE_LOGIC_BY_ID_FAILURE',

    UPDATE_NOTE_LOGIC_REQUEST: 'CREATE_NOTE_LOGIC_REQUEST',
    UPDATE_NOTE_LOGIC_SUCCESS: 'CREATE_NOTE_LOGIC_SUCCESS',
    UPDATE_NOTE_LOGIC_FAILURE: 'CREATE_NOTE_LOGIC_FAILURE',


    DELETE_NOTE_LOGIC_REQUEST: 'DELETE_NOTE_LOGIC_REQUEST',
    DELETE_NOTE_LOGIC_SUCCESS: 'DELETE_NOTE_LOGIC_SUCCESS',
    DELETE_NOTE_LOGIC_FAILURE: 'DELETE_NOTE_LOGIC_FAILURE',
};


{/*************************action starts here *************************************/
}

function getAllData(query) {
    return dispatch => {
        dispatch(request());
        noteLogicService.getAllNoteLogic(query)
            .then(
                noteRule => dispatch(success(noteRule)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: noteLogicConstant.GET_ALL_NOTE_LOGIC_REQUEST}
    }

    function success(noteLogic) {
        return {type: noteLogicConstant.GET_ALL_NOTE_LOGIC_SUCCESS, noteLogic}
    }

    function failure(error) {
        return {type: noteLogicConstant.GET_ALL_NOTE_LOGIC_FAILURE, error}
    }
}


//create NoteRule Action
function submitNoteLogicAction(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        noteLogicService.submitNoteLogic(obj)
            .then(
                noteRule => {
                    if (noteRule['errorCode'] === 200) {
                        showSuccessToast("Note Logic Created Successfully!");
                        dispatch(success(noteRule));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(noteRule);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(noteLogic) {
        return {type: noteLogicConstant.CREATE_NOTE_LOGIC_REQUEST, noteLogic}
    }

    function success(noteLogic) {
        return {type: noteLogicConstant.CREATE_NOTE_LOGIC_SUCCESS, noteLogic}
    }

    function failure(error) {
        return {type: noteLogicConstant.CREATE_NOTE_LOGIC_FAILURE, error}
    }
}


function getNoteLogicByIdAction(codeId) {
    return dispatch => {
        dispatch(request());
        noteLogicService.getNoteLogicById(codeId)
            .then(
                noteRule => dispatch(success(noteRule)),
                error => dispatch(failure(error.toString()))
            );

    };

    function request() {
        return {type: noteLogicConstant.GET_NOTE_LOGIC_BY_ID_REQUEST}
    }

    function success(noteLogic) {
        return {type: noteLogicConstant.GET_NOTE_LOGIC_BY_ID_SUCCESS, noteLogic}
    }

    function failure(error) {
        return {type: noteLogicConstant.GET_NOTE_LOGIC_BY_ID_FAILURE, error}
    }
}


//create msa Action
function updateNoteLogicAction(obj, id, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        noteLogicService.updateNoteLogic(obj, id)
            .then(
                noteRule => {
                    if (noteRule['errorCode'] === 200) {
                        showSuccessToast("Note Logic Updated Successfully!");
                        dispatch(success(noteRule));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(noteRule);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(noteLogic) {
        return {type: noteLogicConstant.UPDATE_NOTE_LOGIC_REQUEST, noteLogic}
    }

    function success(noteLogic) {
        return {type: noteLogicConstant.UPDATE_NOTE_LOGIC_SUCCESS, noteLogic}
    }

    function failure(error) {
        return {type: noteLogicConstant.UPDATE_NOTE_LOGIC_FAILURE, error}
    }
}

//delete note logic
function deleteNoteLogicAction(deleteQuery) {
    return dispatch => {
        dispatch(request(deleteQuery));

        noteLogicService.deleteNoteLogic(deleteQuery.deleteReference)
            .then(
                noteRule => {
                    if (noteRule['errorCode'] === 200) {
                        showSuccessToast("Note Logic deleted Successfully!");
                        dispatch(success(noteRule));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(noteRule);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(id) {
        return {type: noteLogicConstant.DELETE_NOTE_LOGIC_REQUEST, id}
    }

    function success(noteLogic) {
        return {type: noteLogicConstant.DELETE_NOTE_LOGIC_SUCCESS, noteLogic}
    }

    function failure(error) {
        return {type: noteLogicConstant.DELETE_NOTE_LOGIC_FAILURE, error}
    }

}


{/*************************action ends here *************************************/
}


{/************************* Reducers starts here. *************************************/
}


export function getAllNoteLogicReducer(state = {}, action) {
    switch (action.type) {
        case noteLogicConstant.GET_ALL_NOTE_LOGIC_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case noteLogicConstant.GET_ALL_NOTE_LOGIC_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.noteLogic
            };
        case  noteLogicConstant.GET_ALL_NOTE_LOGIC_FAILURE:
            return {};
        default:
            return state
    }
}

export function getNoteLogicByIdReducer(state = {}, action) {
    switch (action.type) {
        case noteLogicConstant.GET_NOTE_LOGIC_BY_ID_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case noteLogicConstant.GET_NOTE_LOGIC_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.noteLogic
            };
        case  noteLogicConstant.GET_NOTE_LOGIC_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}

{/************************* Reducers ends here. *************************************/
}

//get all noteRule
export function getAllNoteLogic(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/ajax/get-all-note-logics?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


// create new Metro Area
export function submitNoteLogic(obj) {
    return axiosInstance.post(`/ajax/create/note-logic`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


export function getNoteLogicById(codeId) {
    return axiosInstance.get(`/ajax/get-note-logic-by-id/${codeId}`).then(
        (response) => {
            return response.data;
        })
}

// update noteRule
export function updateNoteLogic(obj, id) {
    return axiosInstance.put(`/ajax/update/note-logic/${id}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


export function deleteNoteLogic(id) {
    return axiosInstance.delete(`/ajax/delete/note-logic/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}




{/******************************Msa Services ends here  ****************************************/
}