import {showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";
import axiosInstance from "../../../axiosInstance";

//export actions
export const stateCodeActions = {
    getAllStateCodesAction,
    submitStateCodeAction,
    getStateCodeByIdAction,
    updateStateCodeAction,
    exportStateCodeDataAction,
    deleteStateCodeByIdAction
};

//export constants
export const stateCodeConstants = {
    GET_ALL_STATE_CODE_REQUEST: 'GET_ALL_STATE_CODE_REQUEST',
    GET_ALL_STATE_CODE_SUCCESS: 'GET_ALL_STATE_CODE_SUCCESS',
    GET_ALL_STATE_CODE_FAILURE: 'GET_ALL_STATE_CODE_FAILURE',

    CREATE_STATE_CODE_REQUEST: 'CREATE_STATE_CODE_REQUEST',
    CREATE_STATE_CODE_SUCCESS: 'CREATE_STATE_CODE_SUCCESS',
    CREATE_STATE_CODE_FAILURE: 'CREATE_STATE_CODE_FAILURE',

    GET_STATE_CODE_BY_ID_REQUEST: 'GET_STATE_CODE_BY_ID_REQUEST',
    GET_STATE_CODE_BY_ID_SUCCESS: 'GET_STATE_CODE_BY_ID_SUCCESS',
    GET_STATE_CODE_BY_ID_FAILURE: 'GET_STATE_CODE_BY_ID_FAILURE',

    UPDATE_STATE_CODE_REQUEST: 'UPDATE_STATE_CODE_REQUEST',
    UPDATE_STATE_CODE_SUCCESS: 'UPDATE_STATE_CODE_SUCCESS',
    UPDATE_STATE_CODE_FAILURE: 'UPDATE_STATE_CODE_FAILURE',

    EXPORT_STATE_CODE_DATA_REQUEST: 'EXPORT_STATE_CODE_DATA_REQUEST',
    EXPORT_STATE_CODE_DATA_SUCCESS: 'EXPORT_STATE_CODE_DATA_SUCCESS',
    EXPORT_STATE_CODE_DATA_FAILURE: 'EXPORT_STATE_CODE_DATA_FAILURE',

    DELETE_STATE_CODE_REQUEST: 'DELETE_STATE_CODE_REQUEST',
    DELETE_STATE_CODE_SUCCESS: 'DELETE_STATE_CODE_SUCCESS',
    DELETE_STATE_CODE_FAILURE: 'DELETE_STATE_CODE_FAILURE'

};

//export service
export const stateCodeService = {
    getAllStateCodes,
    createStateCode,
    getStateCodeById,
    updateStateCode,
    exportStateCodeData,
    deleteStateCodeByIdService
};

{/************************************* stateCode Actions Starts here  ******************************************/
}

function getAllStateCodesAction(query) {
    return dispatch => {
        dispatch(request());

        stateCodeService.getAllStateCodes(query)

            .then(
                stateCodes => dispatch(success(stateCodes)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: stateCodeConstants.GET_ALL_STATE_CODE_REQUEST}
    }

    function success(stateCodes) {
        return {type: stateCodeConstants.GET_ALL_STATE_CODE_SUCCESS, stateCodes}
    }

    function failure(error) {
        return {type: stateCodeConstants.GET_ALL_STATE_CODE_FAILURE, error}
    }
}


//submit StateCode Action
function submitStateCodeAction(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        stateCodeService.createStateCode(obj)
            .then(
                stateCode => {
                    if (stateCode['errorCode'] === 200) {
                        showSuccessToast("State code added successfully!");
                        dispatch(success(stateCode));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(stateCode);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(stateCode) {
        return {type: stateCodeConstants.CREATE_STATE_CODE_REQUEST, stateCode}
    }

    function success(stateCode) {
        return {type: stateCodeConstants.CREATE_STATE_CODE_SUCCESS, stateCode}
    }

    function failure(error) {
        return {type: stateCodeConstants.CREATE_STATE_CODE_FAILURE, error}
    }
}


function getStateCodeByIdAction(id) {
    return dispatch => {
            dispatch(request());
            stateCodeService.getStateCodeById(id)
                .then(
                    stateCode => dispatch(success(stateCode)),
                    error => dispatch(failure(error.toString()))
                );

    };

    function request() {
        return {type: stateCodeConstants.GET_STATE_CODE_BY_ID_REQUEST}
    }

    function success(stateCode) {
        return {type: stateCodeConstants.GET_STATE_CODE_BY_ID_SUCCESS, stateCode}
    }

    function failure(error) {
        return {type: stateCodeConstants.GET_STATE_CODE_BY_ID_FAILURE, error}
    }
}


function updateStateCodeAction(obj, stateCodeId, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        stateCodeService.updateStateCode(obj, stateCodeId)
            .then(
                stateCode => {
                    if (stateCode['errorCode'] === 200) {
                        showSuccessToast("State code updated successfully!");
                        dispatch(success(stateCode));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(stateCode);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(stateCode) {
        return {type: stateCodeConstants.UPDATE_STATE_CODE_REQUEST, stateCode}
    }

    function success(stateCode) {
        return {type: stateCodeConstants.UPDATE_STATE_CODE_SUCCESS, stateCode}
    }

    function failure(error) {
        return {type: stateCodeConstants.UPDATE_STATE_CODE_FAILURE, error}
    }
}

function deleteStateCodeByIdAction(deleteQuery) {
    return dispatch => {
        dispatch(request());
        stateCodeService.deleteStateCodeByIdService(deleteQuery.deleteReference)
            .then(
                linkedAccount => {
                    if (linkedAccount['errorCode'] === 200) {
                        showSuccessToast("State Code deleted successfully!");
                        dispatch(success(linkedAccount));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(linkedAccount);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: stateCodeConstants.DELETE_STATE_CODE_REQUEST,}
    }

    function success(linkedAccount) {
        return {type: stateCodeConstants.DELETE_STATE_CODE_SUCCESS, linkedAccount}
    }

    function failure(error) {
        return {type: stateCodeConstants.DELETE_STATE_CODE_FAILURE, error}
    }

}



//export StateCode Data
function exportStateCodeDataAction(query) {
    return dispatch => {
        dispatch(request());
        stateCodeService.exportStateCodeData(query)
            .then(
                stateCodeData => dispatch(success(stateCodeData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: stateCodeConstants.EXPORT_STATE_CODE_DATA_REQUEST}
    }

    function success(stateCodeData) {
        query.setExportBtn(false);
        return {type: stateCodeConstants.EXPORT_STATE_CODE_DATA_SUCCESS, stateCodeData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: stateCodeConstants.EXPORT_STATE_CODE_DATA_FAILURE, error}
    }
}

{/***************************** stateCode Actions Ends here **********************************/
}


{/****************** Reducers starts here. **************************************/
}

//get all stateCodes reducer
export function getAllPagedStateCodeReducer(stateCode = {}, action) {
    switch (action.type) {
        case stateCodeConstants.GET_ALL_STATE_CODE_REQUEST:
            return {
                ...stateCode,
                loading: true,
                loggingIn: true,
            };
        case stateCodeConstants.GET_ALL_STATE_CODE_SUCCESS:
            return {
                loading: false,
                loggingIn: true,
                items: action.stateCodes
            };
        case stateCodeConstants.GET_ALL_STATE_CODE_FAILURE:
            return {};
        default:
            return stateCode
    }
}

//get stateCode by id
export function getStateCodeByIdReducer(stateCode = {}, action) {
    switch (action.type) {
        case stateCodeConstants.GET_STATE_CODE_BY_ID_REQUEST:
            return {};
        case stateCodeConstants.GET_STATE_CODE_BY_ID_SUCCESS:
            return {
                items: action.stateCode
            };
        case stateCodeConstants.GET_STATE_CODE_BY_ID_FAILURE:
            return {};
        default:
            return stateCode
    }
}

{/*************************** Reducers ends here. **************************************/
}

{/******************************StateCode Services starts here  ****************************************/
}

//get all counties
export async function getAllStateCodes(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/ajax/state-codes/search?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function getStateCodeById(id) {
    return axiosInstance.get(`/ajax/get-state-codes-by-id/${id}`).then(
        (response) => {
            return response.data;
        })
}

export async function createStateCode(obj) {
    return axiosInstance.post(`/ajax/state-codes/create`, obj)
        .then((response) => {
            return response.data
        })
}

export async function updateStateCode(obj, stateCodeId) {
    return axiosInstance.put(`/ajax/update-state-codes-by-id/${stateCodeId}`, obj)
        .then((response) => {
            return response.data
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export async function deleteStateCodeByIdService(id){
    return axiosInstance.delete(`/ajax/delete-state-code-by-id/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


//export StateCode Data Service
export async function exportStateCodeData(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/state-codes?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "stateCodeData");
            }
        })
        .catch(handleExportError);
}



{/******************************StateCode Services ends here  ****************************************/
}




