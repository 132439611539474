import exceptionHandler from "../../../common/helpers/exceptionHandler";
import {resourceToFile, serialize} from "../../../common/helpers/utils";
import {showInfoToast} from "../../../common/components/Toast";
import axiosInstance from "../../../axiosInstance";


export const importVersionActions = {
    getAllImportVersionsAction,
    exportData
};

export const importVersionService = {

    getAllImportVersionService,
    exportImportVersionService
};

export const importVersionConstants = {

    GET_ALL_IMPORT_VERSION_REQUEST: 'GET_ALL_IMPORT_VERSION_REQUEST',
    GET_ALL_IMPORT_VERSION_SUCCESS: 'GET_ALL_IMPORT_VERSION_SUCCESS',
    GET_ALL_IMPORT_VERSION_FAILURE: 'GET_ALL_IMPORT_VERSION_FAILURE',


    EXPORT_IMPORT_VERSION_REQUEST:'EXPORT_IMPORT_VERSION_REQUEST',
    EXPORT_IMPORT_VERSION_SUCCESS:'EXPORT_IMPORT_VERSION_SUCCESS',
    EXPORT_IMPORT_VERSION_FAILURE:'EXPORT_IMPORT_VERSION_FAILURE',

};

function getAllImportVersionsAction(query) {
    return dispatch => {
        dispatch(request());
        importVersionService.getAllImportVersionService(query)
            .then(
                importVersion => dispatch(success(importVersion)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: importVersionConstants.GET_ALL_IMPORT_VERSION_REQUEST}
    }

    function success(importVersion) {
        return {type: importVersionConstants.GET_ALL_IMPORT_VERSION_SUCCESS, importVersion}
    }

    function failure(error) {
        return {type: importVersionConstants.GET_ALL_IMPORT_VERSION_FAILURE, error}
    }
}





//export Customers Data
function exportData(query) {
    return dispatch => {
        dispatch(request());
        importVersionService.exportImportVersionService(query)
            .then(
                importVersion => dispatch(success(importVersion)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() {return {type: importVersionConstants.EXPORT_IMPORT_VERSION_REQUEST}}
    function success(importVersion) {query.setExportBtn(false); return {type: importVersionConstants.EXPORT_IMPORT_VERSION_SUCCESS, importVersion}}
    function failure(error) {query.setExportBtn(false); return {type: importVersionConstants.EXPORT_IMPORT_VERSION_FAILURE, error}}

}






export function  getAllImportVersionsReducer(state = {}, action) {
    switch (action.type) {
        case importVersionConstants.GET_ALL_IMPORT_VERSION_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case importVersionConstants.GET_ALL_IMPORT_VERSION_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.importVersion
            };
        case  importVersionConstants.GET_ALL_IMPORT_VERSION_FAILURE:
            return {};
        default:
            return state
    }
}

//get all SystemProperty
export function getAllImportVersionService(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/ajax/search-import-version?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function exportImportVersionService(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/import-version?${paramData}`,null,
        {
            headers:
                {'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "importVersionData");
            }
        })
        .catch((error) => console.log(error));
}





