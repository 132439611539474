import exceptionHandler from "../../../common/helpers/exceptionHandler";
import axiosInstance from "../../../axiosInstance";

export const permissionActions = {
    getAllPermissionsActions
}

export const permissionService = {
    getAllPermissions
}

export const permissionConstants = {
    GET_ALL_USER_PERMISSIONS_REQUEST: 'GET_ALL_USER_PERMISSIONS_REQUEST',
    GET_ALL_USER_PERMISSIONS_SUCCESS: 'GET_ALL_USER_PERMISSIONS_SUCCESS',
    GET_ALL_USER_PERMISSIONS_FAILURE: 'GET_ALL_USER_PERMISSIONS_FAILURE',

}

export function getAllPermissionsActions() {
    return dispatch => {
        dispatch(request());

        permissionService.getAllPermissions()
            .then(
                permissions => dispatch(success(permissions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: permissionConstants.GET_ALL_USER_PERMISSIONS_REQUEST}
    }

    function success(permissions) {
        return {type: permissionConstants.GET_ALL_USER_PERMISSIONS_SUCCESS, permissions}
    }

    function failure(error) {
        return {type: permissionConstants.GET_ALL_USER_PERMISSIONS_FAILURE, error}
    }
}

export function getAllPermissionsReducer(state = {}, action) {
    switch (action.type) {
        case permissionConstants.GET_ALL_USER_PERMISSIONS_REQUEST:
            return {...state};

        case permissionConstants.GET_ALL_USER_PERMISSIONS_SUCCESS:
            return {
                loggedIn: true,
                items: action.permissions
            };
        case permissionConstants.GET_ALL_USER_PERMISSIONS_FAILURE:
            return {};

        default:
            return state
    }
}


export function getAllPermissions() {
    return axiosInstance.get(`/ajax/all-permissions`).then(
        (response) => {
            return exceptionHandler(response);
        })
}