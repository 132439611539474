import exceptionHandler, {responseDTOExceptionHandler} from "../../common/helpers/exceptionHandler";
import {showErrorToast, showSuccessToast} from "../../common/components/Toast";
import {alertActions} from "../alert/alert";
import {
    serialize
} from "../../common/helpers/utils";
import axiosInstance from "../../axiosInstance";


//export actions
export const homeActions = {
    resetReducerAction,
    getOrdersCountAction,
    getMyOrdersAction,
    assignOrderAction,
    fetchEnumsAction,
    fetchAuthoritiesAction,
    getTeamOrderAction,
    markAsReadNotificationAction,
    markListAsUnReadNotificationAction,
    uploadMultipleDocumentAction,
    utilsDataAction,
    getOrderCompletionPercentageAction,
    getUnassignedTeamOrderAction,
    getAllTgxFileByRelationIdAction
}


//export constants
export const homeConstants = {
    RESET_REDUCER: 'RESET_REDUCER',
    GET_ORDERS_COUNT_REQUEST: 'GET_ORDERS_COUNT_REQUEST',
    GET_ORDERS_COUNT_SUCCESS: 'GET_ORDERS_COUNT_SUCCESS',
    GET_ORDERS_COUNT_FAILURE: 'GET_ORDERS_COUNT_FAILURE',

    GET_MY_ORDERS_LIST_REQUEST: 'GET_MY_ORDERS_LIST_REQUEST',
    GET_MY_ORDERS_LIST_SUCCESS: 'GET_MY_ORDERS_LIST_SUCCESS',
    GET_MY_ORDERS_LIST_FAILURE: 'GET_MY_ORDERS_LIST_FAILURE',

    GET_ASSIGN_ORDERS_REQUEST: 'GET_ASSIGN_ORDERS_REQUEST',
    GET_ASSIGN_ORDERS_SUCCESS: 'GET_ASSIGN_ORDERS_SUCCESS',
    GET_ASSIGN_ORDERS_FAILURE: 'GET_ASSIGN_ORDERS_FAILURE',


    GET_ENUMS_REQUEST: 'GET_ENUMS_REQUEST',
    GET_ENUMS_SUCCESS: 'GET_ENUMS_SUCCESS',
    GET_ENUMS_FAILURE: 'GET_ENUMS_FAILURE',

    GET_AUTHORITIES_REQUEST: 'GET_AUTHORITIES_REQUEST',
    GET_AUTHORITIES_SUCCESS: 'GET_AUTHORITIES_SUCCESS',
    GET_AUTHORITIES_FAILURE: 'GET_AUTHORITIES_FAILURE',


    GET_TEAM_ORDERS_LIST_REQUEST: 'GET_TEAM_ORDERS_LIST_REQUEST',
    GET_TEAM_ORDERS_LIST_SUCCESS: 'GET_TEAM_ORDERS_LIST_SUCCESS',
    GET_TEAM_ORDERS_LIST_FAILURE: 'GET_TEAM_ORDERS_LIST_FAILURE',

    GET_UNASSIGNED_TEAM_ORDERS_LIST_REQUEST: 'GET_UNASSIGNED_TEAM_ORDERS_LIST_REQUEST',
    GET_UNASSIGNED_TEAM_ORDERS_LIST_SUCCESS: 'GET_UNASSIGNED_TEAM_ORDERS_LIST_SUCCESS',
    GET_UNASSIGNED_TEAM_ORDERS_LIST_FAILURE: 'GET_UNASSIGNED_TEAM_ORDERS_LIST_FAILURE',

    MARK_AS_READ_REQUEST: 'MARK_AS_READ_REQUEST',
    MARK_AS_READ_SUCCESS: 'MARK_AS_READ_SUCCESS',
    MARK_AS_READ_FAILURE: 'MARK_AS_READ_FAILURE',

    MARK_LIST_AS_UNREAD_REQUEST: 'MARK_LIST_AS_UNREAD__REQUEST',
    MARK_LIST_AS_UNREAD__SUCCESS: 'MARK_LIST_AS_UNREAD__SUCCESS',
    MARK_LIST_AS_UNREAD__FAILURE: 'MARK_LIST_AS_UNREAD__FAILURE',

    UPLOAD_MULTIPLE_FILE_REQUEST: 'UPLOAD_MULTIPLE_FILE_REQUEST',
    UPLOAD_MULTIPLE_FILE_SUCCESS: 'UPLOAD_MULTIPLE_FILE_SUCCESS',
    UPLOAD_MULTIPLE_FILE_FAILURE: 'UPLOAD_MULTIPLE_FILE_FAILURE',
    UPLOAD_MULTIPLE_FILE_RESET: 'UPLOAD_MULTIPLE_FILE_RESET',

    UTILS_DATA_REQUEST: 'UTILS_DATA_REQUEST',
    UTILS_DATA_SUCCESS: 'UTILS_DATA_SUCCESS',
    UTILS_DATA_FAILURE: 'UTILS_DATA_FAILURE',
    UTILS_DATA_RESET: 'UTILS_DATA_RESET',

    ORDER_COMPLETION_PERCENTAGE_REQUEST: 'ORDER_COMPLETION_PERCENTAGE_REQUEST',
    ORDER_COMPLETION_PERCENTAGE_SUCCESS: 'ORDER_COMPLETION_PERCENTAGE_SUCCESS',
    ORDER_COMPLETION_PERCENTAGE_FAILURE: 'ORDER_COMPLETION_PERCENTAGE_FAILURE',
    ORDER_COMPLETION_PERCENTAGE_RESET: 'ORDER_COMPLETION_PERCENTAGE_RESET',

    GET_ALL_TGX_FILES_REQUEST: 'GET_ALL_TGX_FILES_REQUEST',
    GET_ALL_TGX_FILES_SUCCESS: 'GET_ALL_TGX_FILES_SUCCESS',
    GET_ALL_TGX_FILES_FAILURE: 'GET_ALL_TGX_FILES_FAILURE',
    GET_ALL_TGX_FILES_REST: 'GET_ALL_TGX_FILES_REST',

}


//export service
export const homeService = {
    getOrdersCount,
    getMyOrderList,
    getAssignOrder,
    fetchEnums,
    fetchAuthorities,
    getTeamOrders,
    getUnAssignedUserTeamsOrder,
    markAsReadNotification,
    markListAsUnReadNotification,
    uploadMultipleDocument,
    utilData,
    orderCompletionPercentage,
    getAllTgxFilesByRelationId
};

/*********** Actions started here. ******************/

function resetReducerAction(...reducers) {
    return dispatch => {
        dispatch(reset(reducers));
    };

    function reset(reducers) {
        return {type: homeConstants.RESET_REDUCER, reducers}
    }
}


function uploadMultipleDocumentAction(uploadFilesDto, relationId, setDisable, isReset = false, responseArray, setResponseArray) {
    return dispatch => {
        if (isReset) {
            dispatch(reset());
        } else {
            dispatch(request());
            homeService.uploadMultipleDocument(uploadFilesDto, relationId, responseArray, setResponseArray)
                .then(
                    multiUploadRes => {
                        if (multiUploadRes['errorCode'] === 200) {
                            dispatch(success(multiUploadRes));
                        } else {
                            responseDTOExceptionHandler(multiUploadRes);
                        }
                        setDisable(false)
                    },
                    error => {
                        setDisable(false)
                        showErrorToast(error['message']);
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                );
        }
    };

    function request() {
        return {type: homeConstants.UPLOAD_MULTIPLE_FILE_REQUEST}
    }

    function success(multiUploadRes) {
        return {type: homeConstants.UPLOAD_MULTIPLE_FILE_SUCCESS, multiUploadRes}
    }

    function failure(error) {
        return {type: homeConstants.UPLOAD_MULTIPLE_FILE_FAILURE, error}
    }

    function reset() {
        return {type: homeConstants.UPLOAD_MULTIPLE_FILE_RESET}
    }


}

function utilsDataAction(timeZone) {
    return (dispatch) => {
        dispatch(request());

        homeService.utilData(timeZone)
            .then(
                (data) => {
                    dispatch(success(data));
                },
                (error) => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: homeConstants.UTILS_DATA_REQUEST};
    }

    function success(data) {
        return {type: homeConstants.UTILS_DATA_SUCCESS, data};
    }

    function failure(error) {
        return {type: homeConstants.UTILS_DATA_FAILURE, error};
    }

    function reset() {
        return {type: homeConstants.UTILS_DATA_RESET};
    }

}

//get active orders count
function getOrdersCountAction() {
    return dispatch => {
        dispatch(request());

        homeService.getOrdersCount()
            .then(
                count => dispatch(success(count)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: homeConstants.GET_ORDERS_COUNT_REQUEST}
    }

    function success(count) {
        return {type: homeConstants.GET_ORDERS_COUNT_SUCCESS, count}
    }

    function failure(error) {
        return {type: homeConstants.GET_ORDERS_COUNT_FAILURE, error}
    }
}

function markAsReadNotificationAction(id, url) {
    return dispatch => {
        dispatch(request());

        homeService.markAsReadNotification(id)
            .then(
                data => {
                    dispatch(success(data));
                    if (url != null && url !== '') {
                        window.open(url, '_blank', 'noopener,noreferrer');
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: homeConstants.MARK_AS_READ_REQUEST}
    }

    function success(data) {
        return {type: homeConstants.MARK_AS_READ_SUCCESS, data}
    }

    function failure(error) {
        return {type: homeConstants.MARK_AS_READ_FAILURE, error}
    }
}

function markListAsUnReadNotificationAction(timeZone) {
    return dispatch => {
        dispatch(request());
        homeService.markListAsUnReadNotification()
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(homeActions.utilsDataAction(timeZone))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: homeConstants.MARK_LIST_AS_UNREAD_REQUEST}
    }

    function success(data) {
        return {type: homeConstants.MARK_LIST_AS_UNREAD__SUCCESS, data}
    }

    function failure(error) {
        return {type: homeConstants.MARK_LIST_AS_UNREAD__FAILURE, error}
    }
}

//get my orders list
function getMyOrdersAction(query) {
    return dispatch => {
        dispatch(request());

        homeService.getMyOrderList(query)
            .then(
                orders => dispatch(success(orders)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: homeConstants.GET_MY_ORDERS_LIST_REQUEST}
    }

    function success(orders) {
        return {type: homeConstants.GET_MY_ORDERS_LIST_SUCCESS, orders}
    }

    function failure(error) {
        return {type: homeConstants.GET_MY_ORDERS_LIST_FAILURE, error}
    }
}

function assignOrderAction(setDisable) {
    return dispatch => {
        dispatch(request());

        homeService.getAssignOrder()
            .then(
                data => {
                    if (data['errorCode'] === 200) {
                        showSuccessToast(data['message']);
                        if (data['message'] !== "No order found to assign!") {
                            let query = {
                                "sort": "assignedDate",
                                "sortOrder": "DESCENDING"
                            }
                            dispatch(homeActions.getMyOrdersAction(query));
                        }
                        dispatch(success(data));
                        setDisable(false);
                    } else {
                        responseDTOExceptionHandler(data);
                        setDisable(false);
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: homeConstants.GET_ASSIGN_ORDERS_REQUEST}
    }

    function success(orders) {
        return {type: homeConstants.GET_ASSIGN_ORDERS_SUCCESS, orders}
    }

    function failure(error) {
        return {type: homeConstants.GET_ASSIGN_ORDERS_FAILURE, error}
    }
}


function fetchEnumsAction() {

    return dispatch => {
        dispatch(request());

        homeService.fetchEnums()
            .then(
                enums => dispatch(success(enums)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: homeConstants.GET_ENUMS_REQUEST}
    }

    function success(enums) {
        return {type: homeConstants.GET_ENUMS_SUCCESS, enums}
    }

    function failure(error) {
        return {type: homeConstants.GET_ENUMS_FAILURE, error}
    }
}

function fetchAuthoritiesAction() {
    return dispatch => {

        dispatch(request());

        homeService.fetchAuthorities()
            .then(authorities => {
                console.log("authorities--->  ", authorities)
                dispatch(success(authorities));
            })
            .catch(error => {
                dispatch(failure(error.toString()));
            });
    };

    function request() {
        return {type: homeConstants.GET_AUTHORITIES_REQUEST};
    }

    function success(authorities) {
        return {type: homeConstants.GET_AUTHORITIES_SUCCESS, authorities};
    }

    function failure(error) {
        return {type: homeConstants.GET_AUTHORITIES_FAILURE, error};
    }
}


//get my orders list
function getTeamOrderAction(query) {
    return dispatch => {
        dispatch(request());

        homeService.getTeamOrders(query)
            .then(
                teamOrders => dispatch(success(teamOrders)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: homeConstants.GET_TEAM_ORDERS_LIST_REQUEST}
    }

    function success(teamOrders) {
        return {type: homeConstants.GET_TEAM_ORDERS_LIST_SUCCESS, teamOrders}
    }

    function failure(error) {
        return {type: homeConstants.GET_TEAM_ORDERS_LIST_FAILURE, error}
    }
}

function getUnassignedTeamOrderAction(query) {
    return dispatch => {
        dispatch(request());

        homeService.getUnAssignedUserTeamsOrder(query)
            .then(
                unassignedTeamOrders => dispatch(success(unassignedTeamOrders)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: homeConstants.GET_UNASSIGNED_TEAM_ORDERS_LIST_REQUEST}
    }

    function success(unassignedTeamOrders) {
        return {type: homeConstants.GET_UNASSIGNED_TEAM_ORDERS_LIST_SUCCESS, unassignedTeamOrders}
    }

    function failure(error) {
        return {type: homeConstants.GET_UNASSIGNED_TEAM_ORDERS_LIST_FAILURE, error}
    }
}

function getOrderCompletionPercentageAction() {
    return dispatch => {
        dispatch(request());

        homeService.orderCompletionPercentage()
            .then(
                record => {
                    let results = {};
                    results.originalTaxCertAutoPercentage = percentage(record['originalTaxCertAutoCompletes'], record['originalTaxCertOrders']);
                    results.reorderTaxCertAutoPercentage = percentage(record['reOrderedTaxCertAutoCompletes'], record['reOrderedTaxCertOrders']);
                    results.originalHoaAutoPercentage = percentage(record['originalHoaAutoCompletes'], record['originalHoaOrders']);
                    results.reorderHoaAutoPercentage = percentage(record['reOrderedHoaAutoCompletes'], record['reOrderedHoaOrders']);
                    dispatch(success(results))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: homeConstants.ORDER_COMPLETION_PERCENTAGE_REQUEST}
    }

    function success(record) {
        return {type: homeConstants.ORDER_COMPLETION_PERCENTAGE_SUCCESS, record}
    }

    function failure(error) {
        return {type: homeConstants.ORDER_COMPLETION_PERCENTAGE_FAILURE, error}
    }
}

function getAllTgxFileByRelationIdAction(query, isReset = false) {
    return dispatch => {
        if (isReset === false) {
            dispatch(request());

            // Assuming you have a service to get the TGX files based on the query
            homeService.getAllTgxFilesByRelationId(query)
                .then(files => {
                    dispatch(success(files));
                }, error => {
                    dispatch(failure(error.toString()));
                });
        } else {
            dispatch(rest());
        }
    };

    function request() {
        return { type: homeConstants.GET_ALL_TGX_FILES_REQUEST };
    }

    function success(files) {
        return { type: homeConstants.GET_ALL_TGX_FILES_SUCCESS, files };
    }

    function failure(error) {
        return { type: homeConstants.GET_ALL_TGX_FILES_FAILURE, error };
    }

    function rest() {
        return { type: homeConstants.GET_ALL_TGX_FILES_REST };
    }
}


/*********** Actions ended here. ******************/


/*********** Reducers started here. ******************/

//get all user reducer
export function getOrdersCountReducer(state = {}, action) {
    switch (action.type) {
        case homeConstants.GET_ORDERS_COUNT_REQUEST:
            return {
                loggingIn: true,
            };
        case homeConstants.GET_ORDERS_COUNT_SUCCESS:
            return {
                loggedIn: true,
                items: action.count
            };
        case homeConstants.GET_ORDERS_COUNT_FAILURE:
            return {};
        default:
            return state
    }
}

export function utilDataReducer(state = {}, action) {
    switch (action.type) {
        case homeConstants.UTILS_DATA_REQUEST:
            return {
                loggingIn: true,
            };
        case homeConstants.UTILS_DATA_SUCCESS:
            return {
                loggedIn: true,
                items: action.data
            };
        case homeConstants.UTILS_DATA_FAILURE:
            return {
                errorItems: action.error
            };
        case homeConstants.UTILS_DATA_RESET:
            return {}
        default:
            return state
    }

}

export function getMyOrdersListReducer(state = {}, action) {
    switch (action.type) {
        case homeConstants.GET_MY_ORDERS_LIST_REQUEST:
            return {
                loading: true,
                loggingIn: true,
            };
        case homeConstants.GET_MY_ORDERS_LIST_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.orders
            };
        case homeConstants.GET_MY_ORDERS_LIST_FAILURE:
            return {
                loading: true,
            };
        default:
            return state
    }
}


//fetch  enums reducer
export function fetchEnumsReducer(state = {}, action) {
    switch (action.type) {
        case homeConstants.GET_ENUMS_REQUEST:
            return {
                loggingIn: true,
            };
        case homeConstants.GET_ENUMS_SUCCESS:
            return {
                loggedIn: true,
                items: action.enums
            };
        case homeConstants.GET_ENUMS_FAILURE:
            return {};
        default:
            return state
    }
}

export function uploadMultipleDocumentReducer(state = {}, action) {
    switch (action.type) {
        case homeConstants.UPLOAD_MULTIPLE_FILE_REQUEST:
            return {
                loggingIn: true,
            };
        case homeConstants.UPLOAD_MULTIPLE_FILE_SUCCESS:
            return {
                loggedIn: true,
                items: action.multiUploadRes
            };
        case homeConstants.UPLOAD_MULTIPLE_FILE_FAILURE:
            return {
                errorItems: action.error
            };

        case homeConstants.UPLOAD_MULTIPLE_FILE_RESET:
            return {}
        default:
            return state
    }
}

//fetch  authorities reducer
export function fetchAuthoritiesReducer(state = {}, action) {
    switch (action.type) {
        case homeConstants.GET_AUTHORITIES_REQUEST:
            return {
                loggingIn: true,
            };
        case homeConstants.GET_AUTHORITIES_SUCCESS:
            return {
                loggedIn: true,
                items: action.authorities
            };
        case homeConstants.GET_AUTHORITIES_FAILURE:
            return {};
        default:
            return state
    }
}


export function getTeamOrdersListReducer(state = {}, action) {
    switch (action.type) {
        case homeConstants.GET_TEAM_ORDERS_LIST_REQUEST:
            return {
                loading: true,
                loggingIn: true,
            };
        case homeConstants.GET_TEAM_ORDERS_LIST_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.teamOrders
            };
        case homeConstants.GET_TEAM_ORDERS_LIST_FAILURE:
            return {
                loading: true,
            };
        default:
            return state
    }
}

export function getUnAssignedUsersTeamOrdersListReducer(state = {}, action) {

    switch (action.type) {
        case homeConstants.GET_UNASSIGNED_TEAM_ORDERS_LIST_REQUEST:
            return {
                loading: true,
                loggingIn: true,
            };
        case homeConstants.GET_UNASSIGNED_TEAM_ORDERS_LIST_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.unassignedTeamOrders
            };
        case homeConstants.GET_UNASSIGNED_TEAM_ORDERS_LIST_FAILURE:
            return {
                loading: true,
            };
        default:
            return state
    }
}

export function getOrderCompletionPercentageReducer(state = {}, action) {
    const ORDER_COMPLETION_PERCENTAGE_REQUEST = 'ORDER_COMPLETION_PERCENTAGE_REQUEST';
    const ORDER_COMPLETION_PERCENTAGE_SUCCESS = 'ORDER_COMPLETION_PERCENTAGE_SUCCESS';
    const ORDER_COMPLETION_PERCENTAGE_FAILURE = 'ORDER_COMPLETION_PERCENTAGE_FAILURE';
    const ORDER_COMPLETION_PERCENTAGE_RESET = 'ORDER_COMPLETION_PERCENTAGE_RESET';
    switch (action.type) {
        case ORDER_COMPLETION_PERCENTAGE_REQUEST:
            return {
                loading: true,
                loggingIn: true,
            };
        case ORDER_COMPLETION_PERCENTAGE_SUCCESS:
            return {

                loggedIn: true,
                loading: false,
                items: action.record,
            };
        case ORDER_COMPLETION_PERCENTAGE_FAILURE:
            return {
                loading: true,
            };
        default:
            return state
    }
}



export function getAllTgxFilesReducer(state = {}, action) {
    switch (action.type) {
        case homeConstants.GET_ALL_TGX_FILES_REQUEST:
            return {
                loading: true, loggingIn: true,
            };
        case homeConstants.GET_ALL_TGX_FILES_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.files
            };
        case homeConstants.GET_ALL_TGX_FILES_FAILURE:
            return {};
        case homeConstants.GET_ALL_TGX_FILES_REST:
            return {
                items: {
                    data: []
                }
            };
        default:
            return state
    }
}
/*********** Reducers ended here. ******************/


/*********** Services started here. ******************/
export async function getOrdersCount() {
    return axiosInstance.get(`/ajax/orders-count-by-status`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function getMyOrderList(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/get-my-order?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function getUnAssignedUserTeamsOrder(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/user/teams/unassigned-products?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function getTeamOrders(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/my-team-orders?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function orderCompletionPercentage() {
    return axiosInstance.get(`/ajax/orderCompletionStats`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function markAsReadNotification(id) {
    return axiosInstance.put(`/ajax/markAsRead/${id}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function uploadMultipleDocument(uploadFilesDto, relationId, responseArray, setResponseArray) {
    let errorOccurred = false;

    for (let i = 0; i < uploadFilesDto.length; i++) {
        let defaultErrorMessage = {}

        const formData = new FormData();
        formData.append(`file`, uploadFilesDto[i].file);
        // delete uploadFilesDto[i].file;
        formData.append(`tgxFilesData`, JSON.stringify(uploadFilesDto[i]));
        const cancelClicked = localStorage.getItem('cancelClicked');
        if (cancelClicked === "true") {
            await axiosInstance.post(`/ajax/delete-temp-file`, formData)
            break;
        }
        await axiosInstance.post(`/ajax/upload-multiple-files/${relationId}`, formData).then(
            (response) => {

                let handledRes = exceptionHandler(response);

                if (uploadFilesDto.length - 1 === i) {
                    if (handledRes.message === "Success") {

                        setResponseArray(previousState => {
                            return [...previousState, handledRes.multiUploadResponse]
                        });

                    } else {

                        defaultErrorMessage.message = handledRes.message;
                        defaultErrorMessage.fileName = uploadFilesDto[i].filename;

                        errorOccurred = true;
                        setResponseArray(previousState => {
                            return [...previousState, defaultErrorMessage]
                        });
                    }
                }

            }).catch(error => {

            defaultErrorMessage.message = error['message'] == null || error['message'] === "" ? "Internal server error!" : error['message'];
            defaultErrorMessage.fileName = uploadFilesDto[i].filename;

            setResponseArray(previousState => {
                return [...previousState, defaultErrorMessage]
            });
            errorOccurred = true; // set the flag to true if an error occurs

        })
        if (errorOccurred) {
            break; // break out of the loop if an error has occurred
        }
    }

    return {
        errorCode: 200,
        data: {id: relationId, multiUploadResponse: responseArray},
        message: "Success",
        success: true
    };
}

export async function markListAsUnReadNotification() {
    return axiosInstance.put(`/ajax/notifications/markListAsUnRead`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function utilData(timeZone) {
    return axiosInstance.post(`/utility/username/${timeZone}`).then(
        (response) => {
            return exceptionHandler(response);
        }
    )
}
export async function getAllTgxFilesByRelationId(query) {
    return axiosInstance.post(`/ajax/tgx-files/search`, query).then((response) => {
        return exceptionHandler(response);
    })
}

export async function getAssignOrder() {
    return axiosInstance.post(`/ajax/get-next-order`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function fetchEnums() {
    return axiosInstance.get(`/utility/tgx/enums`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


export async function fetchAuthorities() {
    try {
        const response = await axiosInstance.get(`/utility/authorities`);
        return exceptionHandler(response);
    } catch (error) {
        // Handle error appropriately
        return {
            success: false,
            message: error.message || "Internal server error!",
            error,
        };
    }
}


function percentage(val1, val2) {
    if (val2 === 0) {
        return "0.00";
    }

    return ((val1 / val2) * 100).toFixed(2);
}