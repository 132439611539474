import axiosInstance from "../../../axiosInstance";
import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showSuccessToast} from "../../../common/components/Toast";
import {alertActions} from "../../alert/alert";
import {history} from "../../../common/helpers/history";
import {serialize} from "../../../common/helpers/utils";

export const cannedNotesActions = {
    getAllData,
    createCannedNote,
    getCannedNoteByIdAction,
    updateCannedNoteAction,
    deleteCannedNoteByIdAction
};


//export constants
export const cannedNotesConstant = {

    GET_ALL_CANNED_NOTES_REQUEST: 'GET_ALL_CANNED_NOTES_REQUEST',
    GET_ALL_CANNED_NOTES_SUCCESS: 'GET_ALL_CANNED_NOTES_SUCCESS',
    GET_ALL_CANNED_NOTES_FAILURE: 'GET_ALL_CANNED_NOTES_FAILURE',

    CREATE_CANNED_NOTES_REQUEST: 'CREATE_CANNED_NOTES_REQUEST',
    CREATE_CANNED_NOTES_SUCCESS: 'CREATE_CANNED_NOTES_SUCCESS',
    CREATE_CANNED_NOTES_FAILURE: 'CREATE_CANNED_NOTES_FAILURE',

    DELETE_CANNED_NOTE_REQUEST: 'DELETE_CANNED_NOTE_REQUEST',
    DELETE_CANNED_NOTE_SUCCESS: 'DELETE_CANNED_NOTE_SUCCESS',
    DELETE_CANNED_NOTE_FAILURE: 'DELETE_CANNED_NOTE_FAILURE',

    GET_CANNED_NOTES_BY_ID_REQUEST: 'GET_CANNED_NOTES_BY_ID_REQUEST',
    GET_CANNED_NOTES_BY_ID_SUCCESS: 'GET_CANNED_NOTES_BY_ID_SUCCESS',
    GET_CANNED_NOTES_BY_ID_FAILURE: 'GET_CANNED_NOTES_BY_ID_FAILURE',

    UPDATE_CANNED_NOTES_REQUEST: 'UPDATE_CANNED_NOTES_REQUEST',
    UPDATE_CANNED_NOTES_SUCCESS: 'UPDATE_CANNED_NOTES_SUCCESS',
    UPDATE_CANNED_NOTES_FAILURE: 'UPDATE_CANNED_NOTES_FAILURE',


}


//export service
export const cannedNotesService = {
    getAllCannedNotesService,
    createCannedNoteService,
    deleteCannedNoteService,
    getCannedNoteByIdService,
    updateCannedNoteService,
}


/******************************** canned notes  Actions Starts here  ****************************************/

// get All Canned Note Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());
        cannedNotesService.getAllCannedNotesService(query)
            .then(
                cannedNotes => dispatch(success(cannedNotes)),
                error => dispatch(failure(error.toString()))
            );

    };

    function request() {
        return {type: cannedNotesConstant.GET_ALL_CANNED_NOTES_REQUEST}
    }

    function success(cannedNotes) {
        return {type: cannedNotesConstant.GET_ALL_CANNED_NOTES_SUCCESS, cannedNotes}
    }

    function failure(error) {
        return {type: cannedNotesConstant.GET_ALL_CANNED_NOTES_FAILURE, error}
    }

}

// get Canned Note By Id Action
function getCannedNoteByIdAction(id) {
    return dispatch => {
        dispatch(request());
        cannedNotesService.getCannedNoteByIdService(id)
            .then(
                cannedNotes => dispatch(success(cannedNotes)),
                error => dispatch(failure(error.toString()))
            );

    };

    function request() {
        return {type: cannedNotesConstant.GET_CANNED_NOTES_BY_ID_REQUEST}
    }

    function success(cannedNotes) {
        return {type: cannedNotesConstant.GET_CANNED_NOTES_BY_ID_SUCCESS, cannedNotes}
    }

    function failure(error) {
        return {type: cannedNotesConstant.GET_CANNED_NOTES_BY_ID_FAILURE, error}
    }

}



//create canned-notes Action
function createCannedNote(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        cannedNotesService.createCannedNoteService(obj)
            .then(
                cannedNotes => {
                    if (cannedNotes['errorCode'] === 200) {
                        showSuccessToast("canned note created successfully!");
                        dispatch(success(cannedNotes));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(cannedNotes);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(cannedNotes) {
        return {type: cannedNotesConstant.CREATE_CANNED_NOTES_REQUEST, cannedNotes}
    }

    function success(cannedNotes) {
        return {type: cannedNotesConstant.CREATE_CANNED_NOTES_SUCCESS, cannedNotes}
    }

    function failure(error) {
        return {type: cannedNotesConstant.CREATE_CANNED_NOTES_FAILURE, error}
    }

}

//update canned-notes Action
function updateCannedNoteAction(cannedId, obj,setDisable, from) {
    return dispatch => {
        dispatch(request({obj}));
        cannedNotesService.updateCannedNoteService(cannedId,obj)
            .then(
                cannedNotes => {
                    if (cannedNotes['errorCode'] === 200) {
                        showSuccessToast("Canned note update successfully!");
                        dispatch(success(cannedNotes));
                         history.push(from);
                    } else {
                        responseDTOExceptionHandler(cannedNotes);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(cannedNotes) {
        return {type: cannedNotesConstant.UPDATE_CANNED_NOTES_REQUEST, cannedNotes}
    }

    function success(cannedNotes) {
        return {type: cannedNotesConstant.UPDATE_CANNED_NOTES_SUCCESS, cannedNotes}
    }

    function failure(error) {
        return {type: cannedNotesConstant.UPDATE_CANNED_NOTES_FAILURE, error}
    }

}

//delete note logic
function deleteCannedNoteByIdAction(deleteQuery) {
    return dispatch => {
        dispatch(request(deleteQuery));
        cannedNotesService.deleteCannedNoteService(deleteQuery.deleteReference)
            .then(
                cannedNote => {
                    if (cannedNote['errorCode'] === 200) {
                        showSuccessToast("Canned Note deleted Successfully!");
                        dispatch(success(cannedNote));
                        deleteQuery.setShowDeleteConfirm(false);
                        history.push(deleteQuery.from);
                    } else {
                        responseDTOExceptionHandler(cannedNote);
                    }
                    deleteQuery.setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(id) {
        return {type: cannedNotesConstant.DELETE_CANNED_NOTE_REQUEST,id}
    }

    function success(cannedNote) {
        return {type: cannedNotesConstant.DELETE_CANNED_NOTE_SUCCESS, cannedNote}
    }

    function failure(error) {
        return {type: cannedNotesConstant.DELETE_CANNED_NOTE_REQUEST, error}
    }

}

/******************************** Canned message Actions ENDS here  ****************************************/


/************************** Reducers starts here. **************************************/

//get all canned notes reducer
export function getAllCannedNotesReducer(state = {}, action) {
    switch (action.type) {
        case cannedNotesConstant.GET_ALL_CANNED_NOTES_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case cannedNotesConstant.GET_ALL_CANNED_NOTES_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.cannedNotes
            };
        case cannedNotesConstant.GET_ALL_CANNED_NOTES_FAILURE:
            return {};
        default:
            return state
    }

}


//get all canned notes reducer
export function getCannedNoteByIdReducer(state = {}, action) {
    switch (action.type) {
        case cannedNotesConstant.GET_CANNED_NOTES_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case cannedNotesConstant.GET_CANNED_NOTES_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.cannedNotes
            };
        case cannedNotesConstant.GET_CANNED_NOTES_BY_ID_FAILURE:
            return {};
        default:
            return state
    }

}


/************************** Reducers ends here. **************************************/


/******************************canned notes Services starts here  ****************************************/

//get all canned notes
export function getAllCannedNotesService(query) {
    let paramData = serialize(query);
    return axiosInstance.post(`/ajax/canned-notes?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


// create new canned notes
export function createCannedNoteService(obj) {
    return axiosInstance.post(`/ajax/canned-notes/create`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

// update canned notes
export function updateCannedNoteService(id,obj){
    return axiosInstance.put(`/ajax/update-canned-note/${id}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

// Delete Canned Note by Id
export function deleteCannedNoteService(id) {
    return axiosInstance.delete(`/ajax/canned-note/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

// Get Canned Note by I'd
export function getCannedNoteByIdService(id){
    return axiosInstance.get(`/ajax/canned-note/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}



/****************************** appraisal Roll Services ends here  ****************************************/




