import exceptionHandler, {responseDTOExceptionHandler} from "../../common/helpers/exceptionHandler";
import {showErrorToast, showInfoToast, showSuccessToast} from "../../common/components/Toast";
import {alertActions} from "../alert/alert";
import {handleExportError, resourceToFile, serialize} from "../../common/helpers/utils";
import axiosInstance from "../../axiosInstance";

export const vendorPricingActions = {
    getAllData,
    deleteVendorPricingAction,
    submitVendorPricingAction,
    exportVendorPricingAction
}

export const vendorPricingConstants = {
    GET_ALL_VENDOR_PRICING_REQUEST: 'GET_ALL_VENDOR_PRICING_REQUEST',
    GET_ALL_VENDOR_PRICING_SUCCESS: 'GET_ALL_VENDOR_PRICING_SUCCESS',
    GET_ALL_VENDOR_PRICING_FAILURE: 'GET_ALL_VENDOR_PRICING_FAILURE',

    DELETE_VENDOR_PRICING_REQUEST: 'DELETE_VENDOR_PRICING_REQUEST',
    DELETE_VENDOR_PRICING_SUCCESS: 'DELETE_VENDOR_PRICING_SUCCESS',
    DELETE_VENDOR_PRICING_FAILURE: 'DELETE_VENDOR_PRICING_FAILURE',

    CREATE_VENDOR_PRICING_REQUEST: 'CREATE_VENDOR_PRICING_REQUEST',
    CREATE_VENDOR_PRICING_SUCCESS: 'CREATE_VENDOR_PRICING_SUCCESS',
    CREATE_VENDOR_PRICING_FAILURE: 'CREATE_VENDOR_PRICING_FAILURE',

    EXPORT_VENDOR_PRICING_REQUEST: 'EXPORT_VENDOR_PRICING_REQUEST',
    EXPORT_VENDOR_PRICING_SUCCESS: 'EXPORT_VENDOR_PRICING_SUCCESS',
    EXPORT_VENDOR_PRICING_FAILURE: 'EXPORT_VENDOR_PRICING_FAILURE',
}

export const vendorPricingService = {
    getAllVendorPricing,
    deleteVendorPricingService,
    submitVendorPricing,
    exportVendorPricing
}


function getAllData(query) {
    return dispatch => {
        dispatch(request());

        vendorPricingService.getAllVendorPricing(query)
            .then(
                vendors => {
                    dispatch(success(vendors));
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() {
        return {type: vendorPricingConstants.GET_ALL_VENDOR_PRICING_REQUEST}
    }

    function success(vendors) {
        return {type: vendorPricingConstants.GET_ALL_VENDOR_PRICING_SUCCESS, vendors}
    }

    function failure(error) {
        return {type: vendorPricingConstants.GET_ALL_VENDOR_PRICING_FAILURE, error}
    }

}

function deleteVendorPricingAction(id, setShowConfirmation, resetInput, setDisable) {
    return dispatch => {
        dispatch(request());
        vendorPricingService.deleteVendorPricingService(id)
            .then(
                pricing => {
                    if (pricing['errorCode'] === 200) {
                        showSuccessToast("Vendor Pricing Deleted Successfully!");
                        dispatch(success(pricing));
                    } else {
                        responseDTOExceptionHandler(pricing);
                    }
                    setDisable(false);
                    setShowConfirmation(false);
                    resetInput();
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: vendorPricingConstants.DELETE_VENDOR_PRICING_REQUEST}
    }

    function success(pricing) {
        return {type: vendorPricingConstants.DELETE_VENDOR_PRICING_SUCCESS, pricing}
    }

    function failure(error) {
        return {type: vendorPricingConstants.DELETE_VENDOR_PRICING_FAILURE, error}
    }
}

function submitVendorPricingAction(obj, id, setIsSubmit, resetInput, setShowEditIcon) {
    return dispatch => {
        dispatch(request({obj}));
        vendorPricingService.submitVendorPricing(obj)
            .then(
                data => {
                    if (data['errorCode'] === 200) {
                        if (id === null || id === '') {
                            showSuccessToast("Vendor pricing added successfully!");
                        } else {
                            showSuccessToast("Vendor pricing updated successfully!");
                        }
                        dispatch(success(data));
                        resetInput();
                        setShowEditIcon(false);
                    } else {
                        responseDTOExceptionHandler(data);
                    }
                    setIsSubmit(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(data) {
        return {type: vendorPricingConstants.CREATE_VENDOR_PRICING_REQUEST, data}
    }

    function success(data) {
        return {type: vendorPricingConstants.CREATE_VENDOR_PRICING_SUCCESS, data}
    }

    function failure(error) {
        return {type: vendorPricingConstants.CREATE_VENDOR_PRICING_FAILURE, error}
    }
}

/************ Reducers started here. *******************/

export function vendorPricingReducer(state = {}, action) {
    switch (action.type) {
        case vendorPricingConstants.GET_ALL_VENDOR_PRICING_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case vendorPricingConstants.GET_ALL_VENDOR_PRICING_SUCCESS:
            return {
                loggedIn: true,
                items: action.vendors
            };
        case vendorPricingConstants.GET_ALL_VENDOR_PRICING_FAILURE:
            return {};
        default:
            return state
    }
}

/************ Reducers ended here. *******************/

function getAllVendorPricing(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/ajax/vendor-pricing?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

function deleteVendorPricingService(id) {
    return axiosInstance.delete(`/ajax/delete-by-vendor-pricing-id/${id}`).then(
        (response) => {
            return response.data;
        }).catch((error) => {
        console.log(error);
    })
}

export async function submitVendorPricing(data) {
    return axiosInstance.post(`/ajax/create-vendor-pricing`, data).then(
        (response) => {
            return response.data;
        })
}


function exportVendorPricingAction(query, setIsExport) {

    return dispatch => {
        dispatch(request());
        vendorPricingService.exportVendorPricing(query)
            .then(
                exportData => {
                    dispatch(success(exportData))
                    setIsExport(false)
                },
                error => {
                    dispatch(failure(error.toString()))
                    setIsExport(false)
                }
            );
    };

    function request() {
        return {type: vendorPricingConstants.EXPORT_VENDOR_PRICING_REQUEST}
    }

    function success(exportData) {

        return {type: vendorPricingConstants.CREATE_VENDOR_PRICING_SUCCESS, exportData}
    }

    function failure(error) {
        return {type: vendorPricingConstants.CREATE_VENDOR_PRICING_FAILURE, error}
    }
}


export async function exportVendorPricing(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/vendorPricing?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    ).then((response) => {
        if (response.data.byteLength === 0) {
            showInfoToast("No data found to export!")
        } else {
            resourceToFile(response.data, "VendorPricing");
        }
    })
        .catch(handleExportError);


}


