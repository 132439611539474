import exceptionHandler from "../../../common/helpers/exceptionHandler";
import {serialize} from "../../../common/helpers/utils";
import axiosInstance from "../../../axiosInstance";

export const reportHistoryActions = {
    getAllData
}

export const reportHistoryConstants = {

    GET_ALL_REPORTS_HISTORY_REQUEST: 'GET_ALL_REPORTS_HISTORY_REQUEST',
    GET_ALL_REPORTS_HISTORY_SUCCESS: 'GET_ALL_REPORTS_HISTORY_SUCCESS',
    GET_ALL_REPORTS_HISTORY_FAILURE: 'GET_ALL_REPORTS_HISTORY_FAILURE',

}

export const reportHistoryService = {
    getAllReportHistoryService
}



function getAllData(query) {
    return dispatch => {
        dispatch(request());

        reportHistoryService.getAllReportHistoryService(query)
            .then(
                reportHistory => dispatch(success(reportHistory)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: reportHistoryConstants.GET_ALL_REPORTS_HISTORY_REQUEST}
    }

    function success(reportHistory) {
        return {type: reportHistoryConstants.GET_ALL_REPORTS_HISTORY_SUCCESS, reportHistory}
    }

    function failure(error) {
        return {type: reportHistoryConstants.GET_ALL_REPORTS_HISTORY_FAILURE, error}
    }
}

/*********** Reducers started here. ******************/

//get all user reducer
export function getAllReportHistoryReducer(state = {}, action) {
    switch (action.type) {
        case reportHistoryConstants.GET_ALL_REPORTS_HISTORY_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case reportHistoryConstants.GET_ALL_REPORTS_HISTORY_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.reportHistory
            };
        case reportHistoryConstants.GET_ALL_REPORTS_HISTORY_FAILURE:
            return {};
        default:
            return state
    }
}



export async function getAllReportHistoryService(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/ajax/report-history?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


