import exceptionHandler from "../../../common/helpers/exceptionHandler";
import {resourceToFile, serialize} from "../../../common/helpers/utils";
import axiosInstance from "../../../axiosInstance";


export const errorLogActions = {
    getAllErrorLogAction,
    exportDataErrorLogsAction
};

export const errorLogService = {
    getAllErrorLogService,
    exportDataErrorLogs
};

export const errorConfigurationConstants = {

    GET_ERROR_LOGS_REQUEST: 'GET_ERROR_LOGS_REQUEST',
    GET_ERROR_LOGS_SUCCESS: 'GET_ERROR_LOGS_SUCCESS',
    GET_ERROR_LOGS_FAILURE: 'GET_ERROR_LOGS_FAILURE',


    EXPORT_ERROR_LOGS_REQUEST:'EXPORT_ERROR_LOGS_REQUEST',
    EXPORT_ERROR_LOGS_SUCCESS:'EXPORT_ERROR_LOGS_SUCCESS',
    EXPORT_ERROR_LOGS_FAILURE:'EXPORT_ERROR_LOGS_FAILURE',

};



function getAllErrorLogAction(query) {
    return dispatch => {
        dispatch(request());
        errorLogService.getAllErrorLogService(query)
            .then(
                errorLogs => dispatch(success(errorLogs)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: errorConfigurationConstants.GET_ERROR_LOGS_REQUEST}
    }
    function success(errorLogs) {
        return {type: errorConfigurationConstants.GET_ERROR_LOGS_SUCCESS, errorLogs}
    }

    function failure(error) {
        return {type: errorConfigurationConstants.GET_ERROR_LOGS_FAILURE, error}
    }
}


//export Customers Data
function exportDataErrorLogsAction(query) {
    return dispatch => {
        dispatch(request());
        errorLogService.exportDataErrorLogs(query)
            .then(
                dataRetrievalData => dispatch(success(dataRetrievalData)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() {return {type: errorConfigurationConstants.EXPORT_ERROR_LOGS_REQUEST}}
    function success(dataRetrievalData) {query.setExportBtn(false); return {type: errorConfigurationConstants.EXPORT_ERROR_LOGS_SUCCESS, dataRetrievalData}}
    function failure(error) {query.setExportBtn(false); return {type: errorConfigurationConstants.EXPORT_ERROR_LOGS_FAILURE, error}}

}



export function  getErrorLogsReducer(state = {}, action) {
    switch (action.type) {
        case errorConfigurationConstants.GET_ERROR_LOGS_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case errorConfigurationConstants.GET_ERROR_LOGS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.errorLogs
            };
        case  errorConfigurationConstants.GET_ERROR_LOGS_FAILURE:
            return {};
        default:
            return state
    }
}

//get all error logs
export function getAllErrorLogService(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/get-all-error-logs?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}



//export customer Data
export async function exportDataErrorLogs(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/ajax/export-error-logs?${paramData}`,null,
        {
            headers:
                {'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            resourceToFile(response.data,"dataRetrievalData");
        })
        .catch((error) => console.log(error));
}





