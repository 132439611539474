import exceptionHandler, {responseDTOExceptionHandler} from "../../common/helpers/exceptionHandler";
import { showInfoToast, showSuccessToast} from "../../common/components/Toast";
import {alertActions} from "../alert/alert";
import {handleExportError, resourceToFile, serialize} from "../../common/helpers/utils";
import axiosInstance from "../../axiosInstance";



export const revenueEventActions = {
    getAllRevenueEventsAction,
    createRevenueEventAction,
    exportRevenueEventDataAction,
};

export const revenueEventService = {
    getAllRevenueEventsService,
    createRevenueEventService,
    exportRevenueEventDataService,
};


export const revenueEventConstant = {

    GET_ALL_REVENUE_EVENTS_REQUEST: 'GET_ALL_REVENUE_EVENTS_REQUEST',
    GET_ALL_REVENUE_EVENTS_SUCCESS: 'GET_ALL_REVENUE_EVENTS_SUCCESS',
    GET_ALL_REVENUE_EVENTS_FAILURE: 'GET_ALL_REVENUE_EVENTS_FAILURE',

    CREARTE_REVENUE_EVENT_REQUEST: 'CREARTE_REVENUE_EVENT_REQUEST',
    CREARTE_REVENUE_EVENT_SUCCESS: 'CREARTE_REVENUE_EVENT_SUCCESS',
    CREARTE_REVENUE_EVENT_FAILURE: 'CREARTE_REVENUE_EVENT_FAILURE',

    CREARTE_REVENUE_EVENT_FOR_PRODUCT_REQUEST: 'CREARTE_REVENUE_EVENT_FOR_PRODUCT_REQUEST',
    CREARTE_REVENUE_EVENT_FOR_PRODUCT_SUCCESS: 'CREARTE_REVENUE_EVENT_FOR_PRODUCT_SUCCESS',
    CREARTE_REVENUE_EVENT_FOR_PRODUCT_FAILURE: 'CREARTE_REVENUE_EVENT_FOR_PRODUCT_FAILURE',

    CANCEL_REVENUE_EVENT_FOR_PRODUCT_REQUEST: 'CANCEL_REVENUE_EVENT_FOR_PRODUCT_REQUEST',
    CANCEL_REVENUE_EVENT_FOR_PRODUCT_SUCCESS: 'CANCEL_REVENUE_EVENT_FOR_PRODUCT_SUCCESS',
    CANCEL_REVENUE_EVENT_FOR_PRODUCT_FAILURE: 'CANCEL_REVENUE_EVENT_FOR_PRODUCT_FAILURE',

    GET_REVENUE_EVENTS_FOR_ORDER_REQUEST: 'GET_REVENUE_EVENTS_FOR_ORDER_REQUEST',
    GET_REVENUE_EVENTS_FOR_ORDER_SUCCESS: 'GET_REVENUE_EVENTS_FOR_ORDER_SUCCESS',
    GET_REVENUE_EVENTS_FOR_ORDER_FAILURE: 'GET_REVENUE_EVENTS_FOR_ORDER_FAILURE',

    EXPORT_REVENUE_EVENT_REQUEST:'EXPORT_REVENUE_EVENT_REQUEST',
    EXPORT_REVENUE_EVENT_SUCCESS:'EXPORT_REVENUE_EVENT_SUCCESS',
    EXPORT_REVENUE_EVENT_FAILURE:'EXPORT_REVENUE_EVENT_FAILURE',
};


function getAllRevenueEventsAction(query) {
    return dispatch => {
        dispatch(request());
        revenueEventService.getAllRevenueEventsService(query)
            .then(
                revenueEvents => dispatch(success(revenueEvents)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: revenueEventConstant.GET_ALL_REVENUE_EVENTS_REQUEST}
    }

    function success(revenueEvents) {
        return {type: revenueEventConstant.GET_ALL_REVENUE_EVENTS_SUCCESS, revenueEvents}
    }

    function failure(error) {
        return {type: revenueEventConstant.GET_ALL_REVENUE_EVENTS_FAILURE, error}
    }
}


function createRevenueEventAction(obj, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        revenueEventService.createRevenueEventService(obj)
            .then(
                revenueEvent => {
                    if (revenueEvent['errorCode'] === 200) {
                        showSuccessToast("Revenue Event added successfully!");
                        dispatch(success(revenueEvent));
                    } else {
                        responseDTOExceptionHandler(revenueEvent);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: revenueEventConstant.CREARTE_REVENUE_EVENT_REQUEST}
    }

    function success(revenueEvent) {
        return {type: revenueEventConstant.CREARTE_REVENUE_EVENT_SUCCESS, revenueEvent}
    }

    function failure(error) {
        return {type: revenueEventConstant.CREARTE_REVENUE_EVENT_FAILURE, error}
    }
}



//export Payment Data
function exportRevenueEventDataAction(query) {
    return dispatch => {
        dispatch(request());
        revenueEventService.exportRevenueEventDataService(query)
            .then(
                revenueEvent => dispatch(success(revenueEvent)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: revenueEventConstant.EXPORT_REVENUE_EVENT_REQUEST}
    }

    function success(revenueEvent) {
        query.setExportBtn(false);
        return {type: revenueEventConstant.EXPORT_REVENUE_EVENT_SUCCESS, revenueEvent}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: revenueEventConstant.EXPORT_REVENUE_EVENT_SUCCESS, error}
    }
}





/***********************Reducers starts here*********************************/

export function getRevenueEventsReducer(state = {}, action) {
    switch (action.type) {
        case revenueEventConstant.GET_ALL_REVENUE_EVENTS_REQUEST:
            return {
                loggingIn: true,
                loading: true
            };
        case revenueEventConstant.GET_ALL_REVENUE_EVENTS_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.revenueEvents
            };
        case  revenueEventConstant.GET_ALL_REVENUE_EVENTS_FAILURE:
            return {
                loading: true
            };
        default:
            return state
    }
}

/***********************Reducers ends here*********************************/


/***********************service apis starts here*********************************/

//get all payments
export function getAllRevenueEventsService(query) {
    let paramData = serialize(query)

    return axiosInstance.get(`/ajax/revenue-events?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export async function createRevenueEventService(obj) {
    return axiosInstance.post(`/ajax/create-bill-release`, obj)
        .then((response) => {
            return response.data
        })
}


//export Payment Data Service
export async function exportRevenueEventDataService(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/revenue-events?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "RevenueEventData");
            }
        })
        .catch(handleExportError);
}




/***********************service apis ends here*********************************/

