import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showSuccessToast} from "../../../common/components/Toast";
import {alertActions} from "../../alert/alert";
import axiosInstance from "../../../axiosInstance";


export const childAccountActions = {
    getAllData,
    createChildAccountAction,
    deleteChildAccountAction,
    getChildAccountByIdAction,
    updateChildAccountAction

};


export const childAccountConstant = {
    GET_ALL_CHILD_ACCOUNT_REQUEST: 'GET_ALL_CHILD_ACCOUNT_REQUEST',
    GET_ALL_CHILD_ACCOUNT_SUCCESS: 'GET_ALL_CHILD_ACCOUNT_SUCCESS',
    GET_ALL_CHILD_ACCOUNT_FAILURE: 'GET_ALL_CHILD_ACCOUNT_FAILURE',

    CREATE_CHILD_ACCOUNT_REQUEST: 'CREATE_CHILD_ACCOUNT_REQUEST',
    CREATE_CHILD_ACCOUNT_SUCCESS: 'CREATE_CHILD_ACCOUNT_SUCCESS',
    CREATE_CHILD_ACCOUNT_FAILURE: 'CREATE_CHILD_ACCOUNT_FAILURE',

    DELETE_CHILD_ACCOUNT_REQUEST: 'DELETE_CHILD_ACCOUNT_REQUEST',
    DELETE_CHILD_ACCOUNT_SUCCESS: 'DELETE_CHILD_ACCOUNT_SUCCESS',
    DELETE_CHILD_ACCOUNT_FAILURE: 'DELETE_CHILD_ACCOUNT_FAILURE',

    GET_CHILD_ACCOUNT_BY_ID_REQUEST: 'GET_CHILD_ACCOUNT_BY_ID_REQUEST',
    GET_CHILD_ACCOUNT_BY_ID_SUCCESS: 'GET_CHILD_ACCOUNT_BY_ID_SUCCESS',
    GET_CHILD_ACCOUNT_BY_ID_FAILURE: 'GET_CHILD_ACCOUNT_BY_ID_FAILURE',

    UPDATE_CHILD_ACCOUNT_REQUEST: 'UPDATE_CHILD_ACCOUNT_REQUEST',
    UPDATE_CHILD_ACCOUNT_SUCCESS: 'UPDATE_CHILD_ACCOUNT_SUCCESS',
    UPDATE_CHILD_ACCOUNT_FAILURE: 'UPDATE_CHILD_ACCOUNT_FAILURE',

};


export const childAccountService = {
    getAllChildAccount,
    createChildAccount,
    deleteChildAccount,
    getChildAccountById,
    updateChildAccount

}


/******************************** ChildAccount Actions Starts here  ****************************************/

// get All ChildAccount Action
function getAllData(subdivisionId) {
    return dispatch => {
        dispatch(request());
        childAccountService.getAllChildAccount(subdivisionId)
            .then(
                childAccounts => dispatch(success(childAccounts)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: childAccountConstant.GET_ALL_CHILD_ACCOUNT_REQUEST}
    }

    function success(childAccounts) {
        return {type: childAccountConstant.GET_ALL_CHILD_ACCOUNT_SUCCESS, childAccounts}
    }

    function failure(error) {
        return {type: childAccountConstant.GET_ALL_CHILD_ACCOUNT_FAILURE, error}
    }
}


//create childAccount  Action
function createChildAccountAction(obj, subdivisionId, setDisable, setShow) {
    return dispatch => {
        dispatch(request({obj}));

        childAccountService.createChildAccount(obj, subdivisionId)
            .then(
                childAccount => {
                    if (childAccount['errorCode'] === 200) {
                        showSuccessToast("Child Account added successfully!");
                        dispatch(success(childAccount));
                        setShow(false)
                        dispatch(getAllData(subdivisionId));
                    } else {
                        responseDTOExceptionHandler(childAccount);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(childAccount) {
        return {type: childAccountConstant.CREATE_CHILD_ACCOUNT_REQUEST, childAccount}
    }

    function success(childAccount) {
        return {type: childAccountConstant.CREATE_CHILD_ACCOUNT_SUCCESS, childAccount}
    }

    function failure(error) {
        return {type: childAccountConstant.CREATE_CHILD_ACCOUNT_FAILURE, error}
    }
}


// delete ParentAccount Action
function deleteChildAccountAction(childId, setDisable, subdivisionId, setShowChildDeleteConfirmation) {
    return dispatch => {
        dispatch(request());
        childAccountService.deleteChildAccount(childId)
            .then(
                childAccount => {
                    if (childAccount['errorCode'] === 200) {
                        showSuccessToast("Child Account deleted successfully!");
                        dispatch(success(childAccount));
                        setShowChildDeleteConfirmation(false)
                        dispatch(getAllData(subdivisionId));
                    } else {
                        responseDTOExceptionHandler(childAccount);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: childAccountConstant.DELETE_CHILD_ACCOUNT_REQUEST}
    }

    function success(childAccount) {
        return {type: childAccountConstant.DELETE_CHILD_ACCOUNT_SUCCESS, childAccount}
    }

    function failure(error) {
        return {type: childAccountConstant.DELETE_CHILD_ACCOUNT_FAILURE, error}
    }
}


function getChildAccountByIdAction(childId) {
    return dispatch => {
        dispatch(request());
        childAccountService.getChildAccountById(childId)
            .then(
                childAccount => dispatch(success(childAccount)),
                error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: childAccountConstant.GET_CHILD_ACCOUNT_BY_ID_REQUEST}
    }

    function success(childAccount) {
        return {type: childAccountConstant.GET_CHILD_ACCOUNT_BY_ID_SUCCESS, childAccount}
    }

    function failure(error) {
        return {type: childAccountConstant.GET_CHILD_ACCOUNT_BY_ID_FAILURE, error}
    }
}


//update childAccount  Action
function updateChildAccountAction(obj, childId, setDisable, setShow, subdivisionId) {
    return dispatch => {
        dispatch(request({obj}));

        childAccountService.updateChildAccount(obj, childId)
            .then(
                childAccount => {
                    if (childAccount['errorCode'] === 200) {
                        showSuccessToast("Child  Account Updated  Successfully!");
                        dispatch(success(childAccount));
                        setShow(false)
                        dispatch(getAllData(subdivisionId));
                    } else {
                        responseDTOExceptionHandler(childAccount);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(childAccount) {
        return {type: childAccountConstant.UPDATE_CHILD_ACCOUNT_REQUEST, childAccount}
    }

    function success(childAccount) {
        return {type: childAccountConstant.UPDATE_CHILD_ACCOUNT_SUCCESS, childAccount}
    }

    function failure(error) {
        return {type: childAccountConstant.UPDATE_CHILD_ACCOUNT_FAILURE, error}
    }
}


/******************************** Child Account Actions Ends here  ****************************************/


/************************** Child Reducers starts here. **************************************/

//get all Child Account reducer
export function getAllChildAccountReducer(state = {}, action) {
    switch (action.type) {
        case childAccountConstant.GET_ALL_CHILD_ACCOUNT_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case childAccountConstant.GET_ALL_CHILD_ACCOUNT_SUCCESS:
            return {
                loggedIn: true,
                items: action.childAccounts
            };
        case childAccountConstant.GET_ALL_CHILD_ACCOUNT_FAILURE:
            return {};
        default:
            return state
    }
}


//get Parent Account By Id reducer
export function getChildAccountByIdReducer(state = {}, action) {
    switch (action.type) {
        case childAccountConstant.GET_CHILD_ACCOUNT_BY_ID_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case childAccountConstant.GET_CHILD_ACCOUNT_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.childAccount
            };
        case childAccountConstant.GET_CHILD_ACCOUNT_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}


/************************** Child Reducers ends here. **************************************/


/******************************Child Services starts here  ****************************************/
//get all Child-Account
export function getAllChildAccount(subdivisionId) {
    return axiosInstance.get(`/ajax/child-account/${subdivisionId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


//create Child Account service api
export function createChildAccount(obj, subdivisionId) {
    return axiosInstance.post(`/ajax/create-child-account/${subdivisionId}`, obj).then(
        (response) => {
            return response.data;
        }
    )
}


//delete Child Account service api
export function deleteChildAccount(childId) {
    return axiosInstance.post(`/ajax/delete-child-account/${childId}`).then(
        (response) => {
            return response.data;
        }
    )
}

//get Child-Account By Id
export function getChildAccountById(childId) {
    return axiosInstance.get(`/ajax/get-child-account-by-id/${childId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


//update Child Account service api
export function updateChildAccount(obj, childId) {
    return axiosInstance.put(`/ajax/update-child-account/${childId}`, obj).then(
        (response) => {
            return response.data;
        }
    )
}