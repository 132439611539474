import _ from "lodash";

export function convertLowerCase(value) {
    if (value != null && value !== '') {
        return value.toLowerCase();
    }
    return value != null ? value : "";
}


export function sortAssessmentList(list, sortingKeyArrayInOrder, order) {
    return _.orderBy(list, sortingKeyArrayInOrder, order);
}

export function commonSortingList(list, sortingKeyArrayInOrder, order) {
    return _.orderBy(list, sortingKeyArrayInOrder, order);
}

export function sortByKeysPriority(jurisdictionList, sortingKeyArrayInOrder, order) {
    return _.orderBy(jurisdictionList, sortingKeyArrayInOrder, order);
}

export function sortCollectorSummaryList(summary, sortingKeyArrayInOrder, order) {
    return _.orderBy(summary, sortingKeyArrayInOrder, order);
}
export function sortTaxSuits(taxSuits) {
    return []
        .concat(...taxSuits)
        .sort((a, b) => convertLowerCase(a.payee).localeCompare(convertLowerCase(b.payee)));
}
