import {history} from "../../common/helpers/history";
import {alertActions} from "../alert/alert";
import {showErrorToast, showInfoToast, showSuccessToast} from "../../common/components/Toast";
import exceptionHandler, {responseDTOExceptionHandler} from "../../common/helpers/exceptionHandler";
import { handleExportError, resourceToFile, serialize} from "../../common/helpers/utils";
import {homeActions} from "../home/home";
import axiosInstance from "../../axiosInstance";

//export actions
export const customerActions = {
    getAllData,
    getAllTenantAction,
    submitCustomerAction,
    getAllStateAction,
    updateCustomerAction,
    getCustomerByCustomerIdAction,
    getCustomerByTenantAction,
    getAllRegularCustomerAction,
    exportData,
    getAllCustomersListAction,
    getPdfImageByNameAction,
    getAllCustomerByCustomerTypeAction,
    deleteCustomerByIdAction,
    getCustomerByTenantAndLevelAction,
    getFulfillmentCustomerByTenantAction,
    getParentCustomerByTenantNameAction,
    getAllParentCustomerAction,
    getPdfSettingByCustomerIdAction
}

//export constants
export const customerConstants = {
    GET_ALL_CUSTOMER_REQUEST: 'GET_ALL_CUSTOMER_REQUEST',
    GET_ALL_CUSTOMER_SUCCESS: 'GET_ALL_CUSTOMER_SUCCESS',
    GET_ALL_CUSTOMER_FAILURE: 'GET_ALL_CUSTOMER_FAILURE',

    GET_ALL_TENANT_REQUEST: 'GET_ALL_TENANT_REQUEST',
    GET_ALL_TENANT_SUCCESS: 'GET_ALL_TENANT_SUCCESS',
    GET_ALL_TENANT_FAILURE: 'GET_ALL_TENANT_FAILURE',

    GET_PDF_IMAGE_BY_NAME_REQUEST: 'GET_PDF_IMAGE_BY_NAME_REQUEST',
    GET_PDF_IMAGE_BY_NAME_SUCCESS: 'GET_PDF_IMAGE_BY_NAME_SUCCESS',
    GET_PDF_IMAGE_BY_NAME_FAILURE: 'GET_PDF_IMAGE_BY_NAME_FAILURE',

    CREATE_CUSTOMER_REQUEST: 'CREATE_CUSTOMER_REQUEST',
    CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
    CREATE_CUSTOMER_FAILURE: 'CREATE_CUSTOMER_FAILURE',

    GET_ALL_STATES_REQUEST: 'GET_ALL_STATES_REQUEST',
    GET_ALL_STATES_SUCCESS: 'GET_ALL_STATES_SUCCESS',
    GET_ALL_STATES_FAILURE: 'GET_ALL_STATES_FAILURE',

    GET_CUSTOMER_BY_NAME_REQUEST: 'GET_CUSTOMER_BY_NAME_REQUEST',
    GET_CUSTOMER_BY_NAME_SUCCESS: 'GET_CUSTOMER_BY_NAME_SUCCESS',
    GET_CUSTOMER_BY_NAME_FAILURE: 'GET_CUSTOMER_BY_NAME_FAILURE',

    UPDATE_CUSTOMER_REQUEST: 'UPDATE_CUSTOMER_REQUEST',
    UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
    UPDATE_CUSTOMER_FAILURE: 'UPDATE_CUSTOMER_FAILURE',

    UPLOAD_CUSTOMER_PDF_LOGO_REQUEST: 'UPLOAD_CUSTOMER_PDF_LOGO_REQUEST',
    UPLOAD_CUSTOMER_PDF_LOGO_SUCCESS: 'UPLOAD_CUSTOMER_PDF_LOGO_SUCCESS',
    UPLOAD_CUSTOMER_PDF_LOGO_FAILURE: 'UPLOAD_CUSTOMER_PDF_LOGO_FAILURE',

    GET_CUSTOMER_BY_ID_REQUEST: 'GET_CUSTOMER_BY_ID_REQUEST',
    GET_CUSTOMER_BY_ID_SUCCESS: 'GET_CUSTOMER_BY_ID_SUCCESS',
    GET_CUSTOMER_BY_ID_FAILURE: 'GET_CUSTOMER_BY_ID_FAILURE',

    GET_CUSTOMER_PDF_SETTING_BY_ID_REQUEST: 'GET_CUSTOMER_PDF_SETTING_BY_ID_REQUEST',
    GET_CUSTOMER_PDF_SETTING_BY_ID_SUCCESS: 'GET_CUSTOMER_PDF_SETTING_BY_ID_SUCCESS',
    GET_CUSTOMER_PDF_SETTING_BY_ID_FAILURE: 'GET_CUSTOMER_PDF_SETTING_BY_ID_FAILURE',

    UPDATE_PDF_SETTING_BY_ID_REQUEST: 'UPDATE_PDF_SETTING_BY_ID_REQUEST',
    UPDATE_PDF_SETTING_BY_ID_SUCCESS: 'UPDATE_PDF_SETTING_BY_ID_SUCCESS',
    UPDATE_PDF_SETTING_BY_ID_FAILURE: 'UPDATE_PDF_SETTING_BY_ID_FAILURE',

    GET_ALL_REGULAR_CUSTOMER_REQUEST: 'GET_ALL_REGULAR_CUSTOMER_REQUEST',
    GET_ALL_REGULAR_CUSTOMER_SUCCESS: 'GET_ALL_REGULAR_CUSTOMER_SUCCESS',
    GET_ALL_REGULAR_CUSTOMER_FAILURE: 'GET_ALL_REGULAR_CUSTOMER_FAILURE',

    EXPORT_CUSTOMER_DATA_REQUEST: 'EXPORT_CUSTOMER_DATA_REQUEST',
    EXPORT_CUSTOMER_DATA_SUCCESS: 'EXPORT_CUSTOMER_DATA_SUCCESS',
    EXPORT_CUSTOMER_DATA_FAILURE: 'EXPORT_CUSTOMER_DATA_FAILURE',

    ALL_CUSTOMER_BY_TENANT_REQUEST: 'ALL_CUSTOMER_BY_TENANT_REQUEST',
    ALL_CUSTOMER_BY_TENANT_SUCCESS: 'ALL_CUSTOMER_BY_TENANT_SUCCESS',
    ALL_CUSTOMER_BY_TENANT_FAILURE: 'ALL_CUSTOMER_BY_TENANT_FAILURE',

    ALL_FULFILLMENT_CUSTOMER_BY_TENANT_REQUEST: 'ALL_FULFILLMENT_CUSTOMER_BY_TENANT_REQUEST',
    ALL_FULFILLMENT_CUSTOMER_BY_TENANT_SUCCESS: 'ALL_FULFILLMENT_CUSTOMER_BY_TENANT_SUCCESS',
    ALL_FULFILLMENT_CUSTOMER_BY_TENANT_FAILURE: 'ALL_FULFILLMENT_CUSTOMER_BY_TENANT_FAILURE',

    ALL_PARENT_CUSTOMER_BY_TENANT_REQUEST: 'ALL_PARENT_CUSTOMER_BY_TENANT_REQUEST',
    ALL_PARENT_CUSTOMER_BY_TENANT_SUCCESS: 'ALL_PARENT_CUSTOMER_BY_TENANT_SUCCESS',
    ALL_PARENT_CUSTOMER_BY_TENANT_FAILURE: 'ALL_PARENT_CUSTOMER_BY_TENANT_FAILURE',

    ALL_PARENT_CUSTOMER_REQUEST: 'ALL_PARENT_CUSTOMER_REQUEST',
    ALL_PARENT_CUSTOMER_SUCCESS: 'ALL_PARENT_CUSTOMER_SUCCESS',
    ALL_PARENT_CUSTOMER_FAILURE: 'ALL_PARENT_CUSTOMER_FAILURE',

    ALL_CUSTOMER_BY_TENANT_AND_CUSTOMER_LEVEL_REQUEST: 'ALL_CUSTOMER_BY_TENANT_AND_CUSTOMER_LEVEL_REQUEST',
    ALL_CUSTOMER_BY_TENANT_AND_CUSTOMER_LEVEL_SUCCESS: 'ALL_CUSTOMER_BY_TENANT_AND_CUSTOMER_LEVEL_SUCCESS',
    ALL_CUSTOMER_BY_TENANT_AND_CUSTOMER_LEVEL_FAILURE: 'ALL_CUSTOMER_BY_TENANT_AND_CUSTOMER_LEVEL_FAILURE',

    GET_ALL_CUSTOMER_LIST_REQUEST: 'GET_ALL_CUSTOMER_LIST_REQUEST',
    GET_ALL_CUSTOMER_LIST_SUCCESS: 'GET_ALL_CUSTOMER_LIST_SUCCESS',
    GET_ALL_CUSTOMER_LIST_FAILURE: 'GET_ALL_CUSTOMER_LIST_FAILURE',

    GET_REGULAR_TENANT_REQUEST: 'GET_REGULAR_TENANT_REQUEST',
    GET_REGULAR_TENANT_SUCCESS: 'GET_REGULAR_TENANT_SUCCESS',
    GET_REGULAR_TENANT_FAILURE: 'GET_REGULAR_TENANT_FAILURE',

    ALL_CUSTOMER_BY_CUSTOMER_TYPE_AND_TENANT_REQUEST: 'ALL_CUSTOMER_BY_CUSTOMER_TYPE_AND_TENANT_REQUEST',
    ALL_CUSTOMER_BY_CUSTOMER_TYPE_AND_TENANT_SUCCESS: 'ALL_CUSTOMER_BY_CUSTOMER_TYPE_AND_TENANT_SUCCESS',
    ALL_CUSTOMER_BY_CUSTOMER_TYPE_AND_TENANT_FAILURE: 'ALL_CUSTOMER_BY_CUSTOMER_TYPE_AND_TENANT_FAILURE',

    ALL_CUSTOMER_BY_CUSTOMER_TYPE_REQUEST: 'ALL_CUSTOMER_BY_CUSTOMER_TYPE_REQUEST',
    ALL_CUSTOMER_BY_CUSTOMER_TYPE_SUCCESS: 'ALL_CUSTOMER_BY_CUSTOMER_TYPE_SUCCESS',
    ALL_CUSTOMER_BY_CUSTOMER_TYPE_FAILURE: 'ALL_CUSTOMER_BY_CUSTOMER_TYPE_FAILURE',

    DELETE_CUSTOMER_REQUEST: 'DELETE_CUSTOMER_REQUEST',
    DELETE_CUSTOMER_SUCCESS: 'DELETE_CUSTOMER_SUCCESS',
    DELETE_CUSTOMER_FAILURE: 'DELETE_CUSTOMER_FAILURE',

}


//export service
export const customersService = {
    getAllCustomers,
    getAllTenantService,
    submitCustomerService,
    getAllState,
    updateCustomerService,
    updatePdfSettingService,
    getCustomerByIdService,
    getPdfSettingByCustomerIdService,
    getAllRegularCustomer,
    getCustomerByTenantService,
    exportCustomerData,
    getAllCustomersList,
    uploadPdfLogoService,
    getPdfImageByNameService,
    getAllCustomerByCustomerTypeService,
    deleteCustomerByIdService,
    getCustomerByTenantAndLevelService,
    getFulfillmentCustomerByTenantService,
    getParentCustomersByTenantService,
    getParentCustomersService
};

/************************ Actions started here. *******************************/


//update resware Type Map action
function deleteCustomerByIdAction(deleteQuery) {
    return dispatch => {
        dispatch(request());
        customersService.deleteCustomerByIdService(deleteQuery.deleteReference)
            .then(customer => {
                if (customer['errorCode'] === 200) {
                    showSuccessToast("Customer deleted successfully!");
                    dispatch(success(customer));
                    deleteQuery.setShowDeleteConfirm(false);
                    history.push(deleteQuery.from);
                } else {
                    responseDTOExceptionHandler(customer);
                }
                deleteQuery.setDisable(false)
            }, error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: customerConstants.DELETE_CUSTOMER_REQUEST}
    }

    function success(customer) {
        return {type: customerConstants.DELETE_CUSTOMER_SUCCESS, customer}
    }

    function failure(error) {
        return {type: customerConstants.DELETE_CUSTOMER_FAILURE, error}
    }
}


function getAllData(query) {
    return dispatch => {
        dispatch(request());

        customersService.getAllCustomers(query)
            .then(customers => dispatch(success(customers)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: customerConstants.GET_ALL_CUSTOMER_REQUEST}
    }

    function success(customers) {
        return {type: customerConstants.GET_ALL_CUSTOMER_SUCCESS, customers}
    }

    function failure(error) {
        return {type: customerConstants.GET_ALL_CUSTOMER_FAILURE, error}
    }
}


function getAllTenantAction() {
    return dispatch => {
        dispatch(request());
        customersService.getAllTenantService()
            .then(tenants => dispatch(success(tenants)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: customerConstants.GET_ALL_TENANT_REQUEST}
    }

    function success(tenants) {
        return {type: customerConstants.GET_ALL_TENANT_SUCCESS, tenants}
    }

    function failure(error) {
        return {type: customerConstants.GET_ALL_TENANT_FAILURE, error}
    }
}


function submitCustomerAction(obj, file, setDisable) {
    return dispatch => {
        dispatch(request({obj}));
        customersService.submitCustomerService(obj)
            .then(data => {
                if (data['errorCode'] === 200) {
                    dispatch(uploadCustomerPdfLogoAction(data['id'], file, setDisable, "Customer added successfully!", obj['customerName'], "create"));
                    dispatch(success(data));
                } else {
                    responseDTOExceptionHandler(data);
                    setDisable(false);
                }
            }, error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request(data) {
        return {type: customerConstants.CREATE_CUSTOMER_REQUEST, data}
    }

    function success(data) {
        return {type: customerConstants.CREATE_CUSTOMER_SUCCESS, data}
    }

    function failure(error) {
        return {type: customerConstants.CREATE_CUSTOMER_FAILURE, error}
    }
}


function uploadCustomerPdfLogoAction(id, image, setDisable, msg, customerName, formType) {
    return dispatch => {
        dispatch(request());
        customersService.uploadPdfLogoService(id, image)
            .then(doc => {
                if (doc['errorCode'] === 200) {
                    showSuccessToast(msg);
                    dispatch(success(doc));
                    if (formType === "create") {
                        history.push("/customers/" + id);
                    } else {
                        showSuccessToast("Customer updated successfully!");
                        dispatch(customerActions.getCustomerByCustomerIdAction(id))
                    }
                } else {
                    responseDTOExceptionHandler(doc);
                }
                setDisable(false)
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: customerConstants.UPLOAD_CUSTOMER_PDF_LOGO_REQUEST}
    }

    function success(doc) {
        return {type: customerConstants.UPLOAD_CUSTOMER_PDF_LOGO_SUCCESS, doc}
    }

    function failure(error) {
        return {type: customerConstants.UPLOAD_CUSTOMER_PDF_LOGO_FAILURE, error}
    }
}

function getAllStateAction() {
    return dispatch => {
            dispatch(request());
            customersService.getAllState()
                .then(states => dispatch(success(states)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: customerConstants.GET_ALL_STATES_REQUEST}
    }

    function success(states) {
        return {type: customerConstants.GET_ALL_STATES_SUCCESS, states}
    }

    function failure(error) {
        return {type: customerConstants.GET_ALL_STATES_FAILURE, error}
    }

}


function getPdfImageByNameAction(imageName) {
    return dispatch => {
        dispatch(request());

        customersService.getPdfImageByNameService(imageName)
            .then(image => dispatch(success(image)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: customerConstants.GET_PDF_IMAGE_BY_NAME_REQUEST}
    }

    function success(image) {
        return {type: customerConstants.GET_PDF_IMAGE_BY_NAME_SUCCESS, image}
    }

    function failure(error) {
        return {type: customerConstants.GET_PDF_IMAGE_BY_NAME_FAILURE, error}
    }
}


function updateCustomerAction(obj, from, setDisable, setShowConfirmation, file, pdfInput, hasPdfUpdatePermission = false) {
    return dispatch => {
        dispatch(request());
        customersService.updateCustomerService(obj)
            .then(data => {
                if (data['errorCode'] === 200) {
                    dispatch(homeActions.resetReducerAction("getCustomerByIdReducer"));

                    if (pdfInput && hasPdfUpdatePermission) {
                        customersService.updatePdfSettingService(pdfInput).then(response => {
                            if (response['errorCode'] !== 200) {
                                responseDTOExceptionHandler(response);
                            }
                        });
                    }

                    if (file === null) {
                        showSuccessToast("Super Market settings updated!")
                    }

                    if (file !== null) {
                        dispatch(uploadCustomerPdfLogoAction(obj['id'], file, setDisable, "Customer updated successfully!", obj['customerName'], "update"));
                    } else {
                        window.location.reload();
                    }

                    dispatch(success(data));
                } else {
                    responseDTOExceptionHandler(data);
                }
                setShowConfirmation(false)
                setDisable(false)

            }, error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: customerConstants.UPDATE_CUSTOMER_REQUEST}
    }

    function success(data) {
        return {type: customerConstants.UPDATE_CUSTOMER_SUCCESS, data}
    }

    function failure(error) {
        return {type: customerConstants.UPDATE_CUSTOMER_FAILURE, error}
    }
}

// get all customer By tenant
function getCustomerByTenantAction(tenantName) {

    return dispatch => {
        dispatch(request());
        customersService.getCustomerByTenantService(tenantName)
            .then(customersTenant => dispatch(success(customersTenant)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: customerConstants.ALL_CUSTOMER_BY_TENANT_REQUEST}
    }

    function success(customersTenant) {
        return {type: customerConstants.ALL_CUSTOMER_BY_TENANT_SUCCESS, customersTenant}
    }

    function failure(error) {
        return {type: customerConstants.ALL_CUSTOMER_BY_TENANT_FAILURE, error}
    }

}

function getFulfillmentCustomerByTenantAction(tenantName) {

    return dispatch => {
        dispatch(request());
        customersService.getFulfillmentCustomerByTenantService(tenantName)
            .then(fulfillment => dispatch(success(fulfillment)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: customerConstants.ALL_FULFILLMENT_CUSTOMER_BY_TENANT_REQUEST}
    }

    function success(fulfillment) {
        return {type: customerConstants.ALL_FULFILLMENT_CUSTOMER_BY_TENANT_SUCCESS, fulfillment}
    }

    function failure(error) {
        return {type: customerConstants.ALL_FULFILLMENT_CUSTOMER_BY_TENANT_FAILURE, error}
    }

}

function getParentCustomerByTenantNameAction(tenantName) {

    return dispatch => {
        dispatch(request());
        customersService.getParentCustomersByTenantService(tenantName)
            .then(parentCustomer => dispatch(success(parentCustomer)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: customerConstants.ALL_PARENT_CUSTOMER_BY_TENANT_REQUEST}
    }

    function success(parentCustomer) {
        return {type: customerConstants.ALL_PARENT_CUSTOMER_BY_TENANT_SUCCESS, parentCustomer}
    }

    function failure(error) {
        return {type: customerConstants.ALL_PARENT_CUSTOMER_BY_TENANT_FAILURE, error}
    }

}

function getAllParentCustomerAction() {

    return dispatch => {
        dispatch(request());
        customersService.getParentCustomersService()
            .then(parentCustomers => dispatch(success(parentCustomers)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: customerConstants.ALL_PARENT_CUSTOMER_REQUEST}
    }

    function success(parentCustomers) {
        return {type: customerConstants.ALL_PARENT_CUSTOMER_SUCCESS, parentCustomers}
    }

    function failure(error) {
        return {type: customerConstants.ALL_PARENT_CUSTOMER_FAILURE, error}
    }

}


function getCustomerByTenantAndLevelAction(tenantName, customerLevel) {

    return dispatch => {
        dispatch(request());
        customersService.getCustomerByTenantAndLevelService(tenantName, customerLevel)
            .then(customerData => dispatch(success(customerData)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: customerConstants.ALL_CUSTOMER_BY_TENANT_AND_CUSTOMER_LEVEL_REQUEST}
    }

    function success(customerData) {
        return {type: customerConstants.ALL_CUSTOMER_BY_TENANT_AND_CUSTOMER_LEVEL_SUCCESS, customerData}
    }

    function failure(error) {
        return {type: customerConstants.ALL_CUSTOMER_BY_TENANT_AND_CUSTOMER_LEVEL_FAILURE, error}
    }

}


function getAllRegularCustomerAction() {
    return dispatch => {
        dispatch(request());
        customersService.getAllRegularCustomer()
            .then(customers => dispatch(success(customers)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: customerConstants.GET_ALL_REGULAR_CUSTOMER_REQUEST}
    }

    function success(customers) {
        return {type: customerConstants.GET_ALL_REGULAR_CUSTOMER_SUCCESS, customers}
    }

    function failure(error) {
        return {type: customerConstants.GET_ALL_REGULAR_CUSTOMER_FAILURE, error}
    }
}

function getCustomerByCustomerIdAction(customerId) {
    return dispatch => {
        dispatch(request());
        customersService.getCustomerByIdService(customerId)
            .then(customer => dispatch(success(customer)),
                error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: customerConstants.GET_CUSTOMER_BY_ID_REQUEST}
    }

    function success(customer) {
        return {type: customerConstants.GET_CUSTOMER_BY_ID_SUCCESS, customer}
    }

    function failure(error) {
        return {type: customerConstants.GET_CUSTOMER_BY_ID_FAILURE, error}
    }
}

function getPdfSettingByCustomerIdAction(customerId) {
    return dispatch => {
        dispatch(request());
        customersService.getPdfSettingByCustomerIdService(customerId)
            .then(customer => dispatch(success(customer)),
                error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: customerConstants.GET_CUSTOMER_PDF_SETTING_BY_ID_REQUEST}
    }

    function success(customer) {
        return {type: customerConstants.GET_CUSTOMER_PDF_SETTING_BY_ID_SUCCESS, customer}
    }

    function failure(error) {
        return {type: customerConstants.GET_CUSTOMER_PDF_SETTING_BY_ID_FAILURE, error}
    }
}


//export Customers Data
function exportData(query) {
    return dispatch => {
        dispatch(request());
        customersService.exportCustomerData(query)
            .then(users => dispatch(success(users)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: customerConstants.EXPORT_CUSTOMER_DATA_REQUEST}
    }

    function success(userData) {
        query.setExportBtn(false);
        return {type: customerConstants.EXPORT_CUSTOMER_DATA_SUCCESS, userData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: customerConstants.EXPORT_CUSTOMER_DATA_FAILURE, error}
    }

}


function getAllCustomersListAction() {
    return dispatch => {
        dispatch(request());

        customersService.getAllCustomersList()
            .then(customers => dispatch(success(customers)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: customerConstants.GET_ALL_CUSTOMER_LIST_REQUEST}
    }

    function success(customers) {
        return {type: customerConstants.GET_ALL_CUSTOMER_LIST_SUCCESS, customers}
    }

    function failure(error) {
        return {type: customerConstants.GET_ALL_CUSTOMER_LIST_FAILURE, error}
    }
}


function getAllCustomerByCustomerTypeAction(customerType) {
    return dispatch => {
        dispatch(request());
        customersService.getAllCustomerByCustomerTypeService(customerType)
            .then(customers => dispatch(success(customers)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: customerConstants.ALL_CUSTOMER_BY_CUSTOMER_TYPE_REQUEST}
    }

    function success(customers) {
        return {type: customerConstants.ALL_CUSTOMER_BY_CUSTOMER_TYPE_SUCCESS, customers}
    }

    function failure(error) {
        return {type: customerConstants.ALL_CUSTOMER_BY_CUSTOMER_TYPE_FAILURE, error}
    }
}

export function getAllCustomerByCustomerTypeReducer(state = {}, action) {

    switch (action.type) {
        case customerConstants.ALL_CUSTOMER_BY_CUSTOMER_TYPE_REQUEST:
            return {
                loggingIn: true,
            };
        case customerConstants.ALL_CUSTOMER_BY_CUSTOMER_TYPE_SUCCESS:
            return {
                loggedIn: true, items: action.customers
            };
        case customerConstants.ALL_CUSTOMER_BY_CUSTOMER_TYPE_FAILURE:
            return {};
        default:
            return state
    }
}

export function getAllCustomerByCustomerLevelAndTenantReducer(state = {}, action) {

    switch (action.type) {
        case customerConstants.ALL_CUSTOMER_BY_TENANT_AND_CUSTOMER_LEVEL_REQUEST:
            return {
                loggingIn: true,
            };
        case customerConstants.ALL_CUSTOMER_BY_TENANT_AND_CUSTOMER_LEVEL_SUCCESS:
            return {
                loggedIn: true, items: action.customerData
            };
        case customerConstants.ALL_CUSTOMER_BY_TENANT_AND_CUSTOMER_LEVEL_FAILURE:
            return {};
        default:
            return state
    }
}


export function getAllCustomerByCustomerTypeAndTenantReducer(state = {}, action) {
    switch (action.type) {
        case customerConstants.ALL_CUSTOMER_BY_CUSTOMER_TYPE_AND_TENANT_REQUEST:
            return {
                loggingIn: true,
            };
        case customerConstants.ALL_CUSTOMER_BY_CUSTOMER_TYPE_AND_TENANT_SUCCESS:
            return {
                loggedIn: true, items: action.customers
            };
        case customerConstants.ALL_CUSTOMER_BY_CUSTOMER_TYPE_AND_TENANT_FAILURE:
            return {};
        default:
            return state
    }
}


export function customers(state = {}, action) {
    switch (action.type) {
        case customerConstants.GET_ALL_CUSTOMER_REQUEST:
            return {
                ...state, loading: true, loggingIn: true,
            };
        case customerConstants.GET_ALL_CUSTOMER_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.customers
            };
        case customerConstants.GET_ALL_CUSTOMER_FAILURE:
            return {};
        default:
            return state
    }
}

//tenant reducer
export function getAllTenantReducer(state = {}, action) {
    switch (action.type) {
        case customerConstants.GET_ALL_TENANT_REQUEST:
            return {
                loggingIn: true,
            };
        case customerConstants.GET_ALL_TENANT_SUCCESS:
            return {
                loggedIn: true, items: action.tenants
            };
        case customerConstants.GET_ALL_TENANT_FAILURE:
            return {};
        default:
            return state
    }
}


//get all state reducer
export function getAllStateReducer(state = {}, action) {
    switch (action.type) {
        case customerConstants.GET_ALL_STATES_REQUEST:
            return {
                loggingIn: true,
            };
        case customerConstants.GET_ALL_STATES_SUCCESS:
            return {
                loggedIn: true, items: action.states
            };
        case customerConstants.GET_ALL_STATES_FAILURE:
            return {};
        default:
            return state
    }
}

export function getPdfImageByNameReducer(state = {}, action) {
    switch (action.type) {
        case customerConstants.GET_PDF_IMAGE_BY_NAME_REQUEST:
            return {
                loggingIn: true,
            };
        case customerConstants.GET_PDF_IMAGE_BY_NAME_SUCCESS:
            return {
                loggedIn: true, items: action.image
            };
        case customerConstants.GET_PDF_IMAGE_BY_NAME_FAILURE:
            return {};
        default:
            return state
    }
}


export function getAllCustomerByTenantName(state = {}, action) {
    switch (action.type) {
        case customerConstants.ALL_CUSTOMER_BY_TENANT_REQUEST:
            return {
                loggingIn: true,
            };
        case customerConstants.ALL_CUSTOMER_BY_TENANT_SUCCESS:
            return {
                loggedIn: true, items: action.customersTenant
            };
        case customerConstants.ALL_CUSTOMER_BY_TENANT_FAILURE:
            return {};
        default:
            return state
    }
}

export function getAllFulfillmentCustomerByTenantName(state = {}, action) {
    switch (action.type) {
        case customerConstants.ALL_FULFILLMENT_CUSTOMER_BY_TENANT_REQUEST:
            return {
                loggingIn: true,
            };
        case customerConstants.ALL_FULFILLMENT_CUSTOMER_BY_TENANT_SUCCESS:
            return {
                loggedIn: true, items: action.fulfillment
            };
        case customerConstants.ALL_FULFILLMENT_CUSTOMER_BY_TENANT_FAILURE:
            return {};
        default:
            return state
    }
}

export function getAllParentCustomerByTenantNameReducer(state = {}, action) {
    switch (action.type) {
        case customerConstants.ALL_PARENT_CUSTOMER_BY_TENANT_REQUEST:
            return {
                loggingIn: true,
            };
        case customerConstants.ALL_PARENT_CUSTOMER_BY_TENANT_SUCCESS:
            return {
                loggedIn: true, items: action.parentCustomer
            };
        case customerConstants.ALL_PARENT_CUSTOMER_BY_TENANT_FAILURE:
            return {};
        default:
            return state
    }
}


export function getAllParentCustomersReducer(state = {}, action) {
    switch (action.type) {
        case customerConstants.ALL_PARENT_CUSTOMER_REQUEST:
            return {
                loggingIn: true,
            };
        case customerConstants.ALL_PARENT_CUSTOMER_SUCCESS:
            return {
                loggedIn: true, items: action.parentCustomers
            };
        case customerConstants.ALL_PARENT_CUSTOMER_FAILURE:
            return {};
        default:
            return state
    }
}

export function getCustomerByIdReducer(state = {}, action) {
    switch (action.type) {
        case customerConstants.GET_CUSTOMER_BY_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case customerConstants.GET_CUSTOMER_BY_ID_SUCCESS:
            return {
                loggedIn: true, items: action.customer
            };
        case customerConstants.GET_CUSTOMER_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}

export function getPdfSettingByCustomerIdReducer(state = {}, action) {
    switch (action.type) {
        case customerConstants.GET_CUSTOMER_PDF_SETTING_BY_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case customerConstants.GET_CUSTOMER_PDF_SETTING_BY_ID_SUCCESS:
            return {
                loggedIn: true, items: action.customer
            };
        case customerConstants.GET_CUSTOMER_PDF_SETTING_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}


//Regular Customers Reducer
export function getAllRegularCustomerReducer(state = {}, action) {
    switch (action.type) {
        case customerConstants.GET_ALL_REGULAR_CUSTOMER_REQUEST:
            return {
                loggingIn: true,
            };
        case customerConstants.GET_ALL_REGULAR_CUSTOMER_SUCCESS:
            return {
                loggedIn: true, items: action.customers
            };
        case customerConstants.GET_ALL_REGULAR_CUSTOMER_FAILURE:
            return {};
        default:
            return state
    }
}

//Regular Customers Reducer
export function getAllCustomersListReducer(state = {}, action) {
    switch (action.type) {
        case customerConstants.GET_ALL_CUSTOMER_LIST_REQUEST:
            return {
                loggingIn: true,
            };
        case customerConstants.GET_ALL_CUSTOMER_LIST_SUCCESS:
            return {
                loggedIn: true, items: action.customers
            };
        case customerConstants.GET_ALL_CUSTOMER_LIST_FAILURE:
            return {};
        default:
            return state
    }
}


/************************ Reducers ended here. *******************************/


/************************ Services started here. *******************************/
export async function getAllCustomers(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/ajax/customers?${paramData}`).then((response) => {
        return exceptionHandler(response);
    })
}


export async function getAllTenantService() {
    return axiosInstance.get(`/ajax/tenant`).then((response) => {
        return exceptionHandler(response);
    })

}

export async function submitCustomerService(data) {
    return axiosInstance.post(`/ajax/submitAccount`, data)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


//get all states list
export async function getAllState() {
    return await axiosInstance.get(`/ajax/get-all-states`).then((response) => {
        return exceptionHandler(response);
    })

}

export function uploadPdfLogoService(id, logo) {
    let data = new FormData();
    data.append("file", logo);

    return axiosInstance.post(`/ajax/uploadPdfLogo/${id}`, data).then((response) => {
        return exceptionHandler(response);
    })
}

export function updateCustomerService(data) {
    return axiosInstance.put(`/ajax/update/customer`, data).then((response) => {
        return response.data;
    })
}

export function updatePdfSettingService(data) {
    return axiosInstance.put(`/ajax/update-pdf-setting`, data).then((response) => {
        return response.data;
    })
}

export function getCustomerByIdService(customerId) {
    return axiosInstance.get(`/ajax/get-customer-by-id/${customerId}`).then((response) => {
        return response.data;
    })
}

export function getPdfSettingByCustomerIdService(customerId) {
    return axiosInstance.get(`/ajax/get-customer-pdf-setting-by-id/${customerId}`).then((response) => {
        return response.data;
    })
}

//get Regular Customers list
export async function getAllRegularCustomer() {
    return await axiosInstance.get(`/ajax/get-all-regular-customer`).then((response) => {
        return exceptionHandler(response);
    })
}

export async function getCustomerByTenantService(tenantName) {

    return await axiosInstance.get(`/ajax/get-all-customer-tenant/${tenantName}`).then((response) => {
        return exceptionHandler(response);
    })

}

export async function getFulfillmentCustomerByTenantService(tenantName) {

    return await axiosInstance.get(`/ajax/get-all-fulfillment-customer-tenant/${tenantName}`).then((response) => {
        return exceptionHandler(response);
    })

}

export async function getParentCustomersByTenantService(tenantName) {

    return await axiosInstance.get(`/ajax/get-all-parent-customer-by-tenant/${tenantName}`).then((response) => {
        return exceptionHandler(response);
    })

}

export async function getParentCustomersService() {

    return await axiosInstance.get(`/ajax/get-all-parent-customer`).then((response) => {
        return exceptionHandler(response);
    })

}

export async function getCustomerByTenantAndLevelService(tenantName, level) {

    return await axiosInstance.get(`/ajax/get-all-customer-by-tenant-and-level/${tenantName}/${level}`).then((response) => {
        return exceptionHandler(response);
    })

}


//export customer Data
export async function exportCustomerData(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/customer?${paramData}`, null, {
        headers: {
            'Content-Disposition': "attachment; filename=template.csv",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }, responseType: 'arraybuffer',
    })
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "customerData");
            }
        })
        .catch(handleExportError);
}


//get all Customers list
export async function getAllCustomersList() {
    return await axiosInstance.get(`/ajax/get-all-customer-list`).then((response) => {
        return exceptionHandler(response);
    })
}


export async function getPdfImageByNameService(imageName) {
    return await axiosInstance.get(`/ajax/get-image-by-name/${imageName}`).then((response) => {
        return exceptionHandler(response);
    })
}

export async function getAllCustomerByCustomerTypeService(customerType) {
    return await axiosInstance.get(`/ajax/customers/customerType/${customerType}`).then((response) => {
        return exceptionHandler(response);
    })
}

export async function deleteCustomerByIdService(customerId) {
    return axiosInstance.delete(`/ajax/customers/soft_delete/${customerId}`).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error)
    })
}

{/************************ Services ended here. *************************************/
}

