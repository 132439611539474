import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {alertActions} from "../../alert/alert";
import {showErrorToast, showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";
import axiosInstance from "../../../axiosInstance";


//export actions
export const collectorActions = {
    getAllData,
    submitCollectorAction,
    getCollectorByIdAction,
    updateCollectorAction,
    getCollectorNotesAction,
    createCollectorNoteAction,
    getCollectorConfigAction,
    deleteCollectorConfigByIdAction,
    getCollectorDocumentsAction,
    submitConfigurationAction,
    updateConfigurationAction,
    getCollectorLogAction,
    exportData,
    checkJobExistAction,
    createFileJobAction,
    deleteCollectorByIdAction
};

//export constants
export const collectorConstants = {
    GET_ALL_COLLECTOR_REQUEST: 'GET_ALL_COLLECTOR_REQUEST',
    GET_ALL_COLLECTOR_SUCCESS: 'GET_ALL_COLLECTOR_SUCCESS',
    GET_ALL_COLLECTOR_FAILURE: 'GET_ALL_COLLECTOR_FAILURE',


    CREATE_COLLECTOR_REQUEST: 'CREATE_COLLECTOR_REQUEST',
    CREATE_COLLECTOR_SUCCESS: 'CREATE_COLLECTOR_SUCCESS',
    CREATE_COLLECTOR_FAILURE: 'CREATE_COLLECTOR_FAILURE',

    GET_COLLECTOR_BY_ID_REQUEST: 'GET_COLLECTOR_BY_ID_REQUEST',
    GET_COLLECTOR_BY_ID_SUCCESS: 'GET_COLLECTOR_BY_ID_SUCCESS',
    GET_COLLECTOR_BY_ID_FAILURE: 'GET_COLLECTOR_BY_ID_FAILURE',

    UPDATE_COLLECTOR_REQUEST: 'UPDATE_COLLECTOR_REQUEST',
    UPDATE_COLLECTOR_SUCCESS: 'UPDATE_COLLECTOR_SUCCESS',
    UPDATE_COLLECTOR_FAILURE: 'UPDATE_COLLECTOR_FAILURE',

    GET_COLLECTOR_NOTES_REQUEST: 'GET_COLLECTOR_NOTES_REQUEST',
    GET_COLLECTOR_NOTES_SUCCESS: 'GET_COLLECTOR_NOTES_SUCCESS',
    GET_COLLECTOR_NOTES_FAILURE: 'GET_COLLECTOR_NOTES_FAILURE',

    CREATE_COLLECTOR_NOTE_REQUEST: 'CREATE_COLLECTOR_NOTE_REQUEST',
    CREATE_COLLECTOR_NOTE_SUCCESS: 'CREATE_COLLECTOR_NOTE_SUCCESS',
    CREATE_COLLECTOR_NOTE_FAILURE: 'CREATE_COLLECTOR_NOTE_FAILURE',

    CREATE_CONFIGURATION_REQUEST: 'CREATE_CONFIGURATION_REQUEST',
    CREATE_CONFIGURATION_SUCCESS: 'CREATE_CONFIGURATION_SUCCESS',
    CREATE_CONFIGURATION_FAILURE: 'CREATE_CONFIGURATION_FAILURE',

    GET_COLLECTOR_CONFIG_REQUEST: 'GET_COLLECTOR_CONFIG_REQUEST',
    GET_COLLECTOR_CONFIG_SUCCESS: 'GET_COLLECTOR_CONFIG_SUCCESS',
    GET_COLLECTOR_CONFIG_FAILURE: 'GET_COLLECTOR_CONFIG_FAILURE',

    GET_COLLECTOR_LOGS_REQUEST: 'GET_COLLECTOR_LOGS_REQUEST',
    GET_COLLECTOR_LOGS_SUCCESS: 'GET_COLLECTOR_LOGS_SUCCESS',
    GET_COLLECTOR_LOGS_FAILURE: 'GET_COLLECTOR_LOGS_FAILURE',


    GET_COLLECTOR_DOCUMENT_LIST_REQUEST: 'GET_COLLECTOR_DOCUMENT_LIST_REQUEST',
    GET_COLLECTOR_DOCUMENT_LIST_SUCCESS: 'GET_COLLECTOR_DOCUMENT_LIST_SUCCESS',
    GET_COLLECTOR_DOCUMENT_LIST_FAILURE: 'GET_COLLECTOR_DOCUMENT_LIST_FAILURE',

    EXPORT_COLLECTOR_DATA_REQUEST: 'EXPORT_COLLECTOR_DATA_REQUEST',
    EXPORT_COLLECTOR_DATA_SUCCESS: 'EXPORT_COLLECTOR_DATA_SUCCESS',
    EXPORT_COLLECTOR_DATA_FAILURE: 'EXPORT_COLLECTOR_DATA_FAILURE',

    UPDATE_CONFIGURATION_REQUEST: 'UPDATE_CONFIGURATION_REQUEST',
    UPDATE_CONFIGURATION_SUCCESS: 'UPDATE_CONFIGURATION_SUCCESS',
    UPDATE_CONFIGURATION_FAILURE: 'UPDATE_CONFIGURATION_FAILURE',


    DELETE_COLLECTOR_CONFIG_BY_ID_REQUEST: 'DELETE_COLLECTOR_CONFIG_BY_ID_REQUEST',
    DELETE_COLLECTOR_CONFIG_BY_ID_SUCCESS: 'DELETE_COLLECTOR_CONFIG_BY_ID_SUCCESS',
    DELETE_COLLECTOR_CONFIG_BY_ID_FAILURE: 'DELETE_COLLECTOR_CONFIG_BY_ID_FAILURE',

    CHECK_COLLECTOR_JOB_STATUS_REQUEST: 'CHECK_COLLECTOR_JOB_STATUS_REQUEST',
    CHECK_COLLECTOR_JOB_STATUS_SUCCESS: 'CHECK_COLLECTOR_JOB_STATUS_SUCCESS',
    CHECK_COLLECTOR_JOB_STATUS_FAILURE: 'CHECK_COLLECTOR_JOB_STATUS_FAILURE',

    CREATE_JOB_REQUEST: 'CREATE_JOB_REQUEST',
    CREATE_JOB_SUCCESS: 'CREATE_JOB_SUCCESS',
    CREATE_JOB_FAILURE: 'CREATE_JOB_FAILURE',

    DELETE_COLLECTOR_REQUEST: 'DELETE_COLLECTOR_REQUEST',
    DELETE_COLLECTOR_SUCCESS: 'DELETE_COLLECTOR_SUCCESS',
    DELETE_COLLECTOR_FAILURE: 'DELETE_COLLECTOR_FAILURE',


};


//export service
export const collectorService = {
    getAllCollector,
    createCollector,
    getCollectorById,
    updateCollector,
    getCollectorNotesService,
    createCollectorNoteService,
    createConfiguration,
    updateConfiguration,
    getCollectorDocuments,
    getCollectorConfig,
    deleteCollectorConfigByIdService,
    getCollectorLogs,
    exportCollectorData,
    checkJobExist,
    createFileJob,
    deleteCollectorById
};


{/******************************** collector Actions Starts here  ****************************************/
}

// get All collector Note

function getCollectorNotesAction(collectorId) {
    return dispatch => {
        dispatch(request());

        collectorService.getCollectorNotesService(collectorId)
            .then(
                notes => dispatch(success(notes)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: collectorConstants.GET_COLLECTOR_NOTES_REQUEST}
    }

    function success(notes) {
        return {type: collectorConstants.GET_COLLECTOR_NOTES_SUCCESS, notes}
    }

    function failure(error) {
        return {type: collectorConstants.GET_COLLECTOR_NOTES_FAILURE, error}
    }

}

// create Add Note
function createCollectorNoteAction(collectorId, obj, setDisable, setShow) {
    return dispatch => {
        dispatch(request(obj));
        collectorService.createCollectorNoteService(collectorId, obj)
            .then(
                note => {
                    if (note['errorCode'] === 200) {
                        showSuccessToast("Note created successfully!");
                        dispatch(success(note));
                        dispatch(getCollectorNotesAction(collectorId))
                    } else {
                        responseDTOExceptionHandler(note);
                    }
                    setShow(false)
                    setDisable(false)
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(note) {
        return {type: collectorConstants.CREATE_COLLECTOR_NOTE_REQUEST, note}
    }

    function success(note) {
        return {type: collectorConstants.CREATE_COLLECTOR_NOTE_SUCCESS, note}
    }

    function failure(error) {
        return {type: collectorConstants.CREATE_COLLECTOR_NOTE_FAILURE, error}
    }
}


//get all Collector Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());

        collectorService.getAllCollector(query)
            .then(
                collectors => dispatch(success(collectors)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: collectorConstants.GET_ALL_COLLECTOR_REQUEST}
    }

    function success(collectors) {
        return {type: collectorConstants.GET_ALL_COLLECTOR_SUCCESS, collectors}
    }

    function failure(error) {
        return {type: collectorConstants.GET_ALL_COLLECTOR_FAILURE, error}
    }
}


//create Collector Action
function submitCollectorAction(obj, from, setDisable) {
    return dispatch => {
        dispatch(request({obj}));

        collectorService.createCollector(obj)
            .then(
                collector => {
                    if (collector['errorCode'] === 200) {
                        showSuccessToast("Collector added successfully!");
                        dispatch(success(collector));
                        history.push(from);
                    } else {
                        responseDTOExceptionHandler(collector);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(collector) {
        return {type: collectorConstants.CREATE_COLLECTOR_REQUEST, collector}
    }

    function success(collector) {
        return {type: collectorConstants.CREATE_COLLECTOR_SUCCESS, collector}
    }

    function failure(error) {
        return {type: collectorConstants.CREATE_COLLECTOR_FAILURE, error}
    }
}


//get collector by id
function getCollectorByIdAction(collectorId) {
    return dispatch => {
        dispatch(request());
        collectorService.getCollectorById(collectorId)
            .then(
                collector => dispatch(success(collector)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: collectorConstants.GET_COLLECTOR_BY_ID_REQUEST}
    }

    function success(collector) {
        return {type: collectorConstants.GET_COLLECTOR_BY_ID_SUCCESS, collector}
    }

    function failure(error) {
        return {type: collectorConstants.GET_COLLECTOR_BY_ID_FAILURE, error}
    }
}

// update Collector Action
function updateCollectorAction(obj, from, setDisable, collectorId, isEditable) {
    return dispatch => {
        dispatch(request({obj}));
        collectorService.updateCollector(obj)
            .then(
                collector => {
                    if (collector['errorCode'] === 200) {
                        isEditable(false);
                        showSuccessToast("Collector updated successfully!");
                        dispatch(success(collector));
                        dispatch(getCollectorByIdAction(collectorId));
                    } else {
                        responseDTOExceptionHandler(collector);
                    }
                    setDisable(false);
                },
                error => {
                    setDisable(false);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(collector) {
        return {type: collectorConstants.UPDATE_COLLECTOR_REQUEST, collector}
    }

    function success(collector) {
        return {type: collectorConstants.UPDATE_COLLECTOR_SUCCESS, collector}
    }

    function failure(error) {
        return {type: collectorConstants.UPDATE_COLLECTOR_FAILURE, error}
    }
}

//create collector configuration
function submitConfigurationAction(obj, setDisable, collectorId) {
    return dispatch => {
        dispatch(request({obj}));

        collectorService.createConfiguration(obj)
            .then(
                config => {
                    if (config['errorCode'] === 200) {
                        showSuccessToast("Configuration added successfully!");
                        dispatch(success(config));
                        dispatch(collectorActions.getCollectorConfigAction(collectorId));
                    } else {
                        responseDTOExceptionHandler(config);
                    }
                    setDisable(false)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(config) {
        return {type: collectorConstants.CREATE_CONFIGURATION_REQUEST, config}
    }

    function success(config) {
        return {type: collectorConstants.CREATE_CONFIGURATION_SUCCESS, config}
    }

    function failure(error) {
        return {type: collectorConstants.CREATE_CONFIGURATION_FAILURE, error}
    }
}


//update collector configuration
function updateConfigurationAction(obj, IsSubmit) {
    return dispatch => {
        dispatch(request({obj}));
        collectorService.updateConfiguration(obj)
            .then(
                config => {
                    if (config['errorCode'] === 200) {
                        showSuccessToast("Configuration updated successfully!");
                        dispatch(success(config));
                        IsSubmit(false);
                        dispatch(getCollectorConfigAction(obj.collector.id));
                    } else {
                        IsSubmit(false);
                        dispatch(getCollectorConfigAction(obj.collector.id));
                        responseDTOExceptionHandler(config);
                    }
                },
                error => {
                    IsSubmit(false)
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(config) {
        return {type: collectorConstants.UPDATE_CONFIGURATION_REQUEST, config}
    }

    function success(config) {
        return {type: collectorConstants.UPDATE_CONFIGURATION_SUCCESS, config}
    }

    function failure(error) {
        return {type: collectorConstants.UPDATE_CONFIGURATION_FAILURE, error}
    }
}


//get collector config action
function getCollectorConfigAction(collectorId) {
    return dispatch => {
        dispatch(request());
        collectorService.getCollectorConfig(collectorId)
            .then(
                configs => dispatch(success(configs)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: collectorConstants.GET_COLLECTOR_CONFIG_REQUEST}
    }

    function success(configs) {
        return {type: collectorConstants.GET_COLLECTOR_CONFIG_SUCCESS, configs}
    }

    function failure(error) {
        return {type: collectorConstants.GET_COLLECTOR_CONFIG_FAILURE, error}
    }
}

// delete Collector Config By Id
function deleteCollectorConfigByIdAction(collectorConfigId, collectorId, setDeleteCollectorConfigurationModel, setDisable) {
    return dispatch => {
        dispatch(request());
        collectorService.deleteCollectorConfigByIdService(collectorConfigId)
            .then(
                configCollector => {
                    if (configCollector['errorCode'] === 200) {
                        showSuccessToast("Configuration deleted successfully!");
                        dispatch(success(configCollector));
                        dispatch(getCollectorConfigAction(collectorId));
                        setDeleteCollectorConfigurationModel(false)
                    } else {
                        responseDTOExceptionHandler(configCollector);
                    }
                    setDisable(false)
                },
                error => {
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );

    };

    function request() {
        return {type: collectorConstants.DELETE_COLLECTOR_CONFIG_BY_ID_REQUEST}
    }

    function success(configCollector) {
        return {type: collectorConstants.DELETE_COLLECTOR_CONFIG_BY_ID_SUCCESS, configCollector}
    }

    function failure(error) {
        return {type: collectorConstants.DELETE_COLLECTOR_CONFIG_BY_ID_FAILURE, error}
    }

}


//get collector documents action
function getCollectorDocumentsAction(collectorId) {
    return dispatch => {
        dispatch(request());
        collectorService.getCollectorDocuments(collectorId)
            .then(
                docs => dispatch(success(docs)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: collectorConstants.GET_COLLECTOR_DOCUMENT_LIST_REQUEST}
    }

    function success(docs) {
        return {type: collectorConstants.GET_COLLECTOR_DOCUMENT_LIST_SUCCESS, docs}
    }

    function failure(error) {
        return {type: collectorConstants.GET_COLLECTOR_DOCUMENT_LIST_FAILURE, error}
    }
}

//get collector log action
function getCollectorLogAction(collectorId) {
    return dispatch => {
        dispatch(request());
        collectorService.getCollectorLogs(collectorId)
            .then(
                log => dispatch(success(log)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: collectorConstants.GET_COLLECTOR_LOGS_REQUEST}
    }

    function success(log) {
        return {type: collectorConstants.GET_COLLECTOR_LOGS_SUCCESS, log}
    }

    function failure(error) {
        return {type: collectorConstants.GET_COLLECTOR_LOGS_FAILURE, error}
    }
}


//export Collector Data
function exportData(query) {
    return dispatch => {
        dispatch(request());
        collectorService.exportCollectorData(query)
            .then(
                collectorData => dispatch(success(collectorData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: collectorConstants.EXPORT_COLLECTOR_DATA_REQUEST}
    }

    function success(collectorData) {
        query.setExportBtn(false);
        return {type: collectorConstants.EXPORT_COLLECTOR_DATA_SUCCESS, collectorData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: collectorConstants.EXPORT_COLLECTOR_DATA_FAILURE, error}
    }
}

export function checkJobExistAction(collectorId, fileId, setFileId) {

    return dispatch => {
        dispatch(request({collectorId, fileId}));
        collectorService.checkJobExist(collectorId, fileId)
            .then(
                status => {
                    if (status.length === 0) {
                        dispatch(success(status))
                        dispatch(createFileJobAction(collectorId, fileId, "MAP_IMPORT", setFileId));
                    } else if (status.length > 0 && status.length < 2) {
                        dispatch(success(status))
                        status[0].type === 'MAP_IMPORT' ?
                            showSuccessToast("Job Already Exists")
                            :
                            dispatch(createFileJobAction(collectorId, fileId, 'MAP_IMPORT', setFileId));
                    } else {
                        showSuccessToast("Job Already Exists")
                        dispatch(success(status))
                    }
                    setFileId(null);
                },
                error => {
                    setFileId(null);
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request(status) {
        return {type: collectorConstants.CHECK_COLLECTOR_JOB_STATUS_REQUEST, status}
    }

    function success(status) {
        return {type: collectorConstants.CHECK_COLLECTOR_JOB_STATUS_SUCCESS, status}
    }

    function failure(error) {
        return {type: collectorConstants.CHECK_COLLECTOR_JOB_STATUS_FAILURE, error}
    }

}


export function createFileJobAction(collectorId, fileId, jobType, setFileId) {

    return dispatch => {
        dispatch(request({collectorId, fileId}));
        collectorService.createFileJob(collectorId, fileId, jobType)
            .then(
                job => {
                    setFileId(null)
                    if (job['errorCode'] === 200) {
                        showSuccessToast(job['message']);
                        dispatch(success(job));
                        dispatch(getCollectorByIdAction(collectorId))
                    } else {
                        responseDTOExceptionHandler(job);
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(job) {
        return {type: collectorConstants.CREATE_JOB_REQUEST, job}
    }

    function success(job) {
        return {type: collectorConstants.CREATE_JOB_SUCCESS, job}
    }

    function failure(error) {
        return {type: collectorConstants.CREATE_JOB_FAILURE, error}
    }

}

//delete collector Action
function deleteCollectorByIdAction(deleteQuery) {
    return dispatch => {
        dispatch(request());
        if (deleteQuery?.nestedItems?.items && deleteQuery?.nestedItems?.items.length > 0) {
            deleteQuery.setNestModalReference(true);
            deleteQuery.setDisable(false);
            deleteQuery.setShowDeleteConfirm(false);
        } else {
            collectorService.deleteCollectorById(deleteQuery.deleteReference)
                .then(
                    collector => {
                        if (collector['errorCode'] === 200) {
                            showSuccessToast("Collector deleted successfully!");
                            dispatch(success(collector));
                            deleteQuery.setShowDeleteConfirm(false);
                            history.push(deleteQuery.from);
                        } else {
                            responseDTOExceptionHandler(collector);
                        }
                        deleteQuery.setDisable(false)
                    },
                    error => {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    });
        }

    };

    function request() {
        return {type: collectorConstants.DELETE_COLLECTOR_REQUEST,}
    }

    function success(collector) {
        return {type: collectorConstants.DELETE_COLLECTOR_SUCCESS, collector}
    }

    function failure(error) {
        return {type: collectorConstants.DELETE_COLLECTOR_FAILURE, error}
    }
}


/************************************* collector Actions Ends here ********************************************/


/************************** Reducers starts here. **************************************/

//get all Collector reducer
export function getAllCollectorReducer(state = {}, action) {
    switch (action.type) {
        case collectorConstants.GET_ALL_COLLECTOR_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case collectorConstants.GET_ALL_COLLECTOR_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.collectors
            };
        case collectorConstants.GET_ALL_COLLECTOR_FAILURE:
            return {};
        default:
            return state
    }
}


//Collector by id Reducer
export function getCollectorByIdReducer(state = {}, action) {
    switch (action.type) {
        case collectorConstants.GET_COLLECTOR_BY_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case collectorConstants.GET_COLLECTOR_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.collector
            };
        case collectorConstants.GET_COLLECTOR_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}

// CollectorNote
export function getAllCollectorNotesByCollectorIdReducer(state = {}, action) {
    switch (action.type) {
        case collectorConstants.GET_COLLECTOR_NOTES_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case collectorConstants.GET_COLLECTOR_NOTES_SUCCESS:
            return {
                loggedIn: true,
                items: action.notes
            };
        case collectorConstants.GET_COLLECTOR_NOTES_FAILURE:
            return {};
        default:
            return state
    }
}

// Collector Config Reducer
export function getCollectorConfigListReducer(state = {}, action) {
    switch (action.type) {
        case collectorConstants.GET_COLLECTOR_CONFIG_REQUEST:
            return {
                loggingIn: true,
            };
        case collectorConstants.GET_COLLECTOR_CONFIG_SUCCESS:
            return {
                loggedIn: true,
                items: action.configs
            };
        case collectorConstants.GET_COLLECTOR_CONFIG_FAILURE:
            return {};
        default:
            return state
    }
}


export function getCollectorDocumentListReducer(state = {}, action) {
    switch (action.type) {
        case collectorConstants.GET_COLLECTOR_DOCUMENT_LIST_REQUEST:
            return {};

        case collectorConstants.GET_COLLECTOR_DOCUMENT_LIST_SUCCESS:
            return {
                loggedIn: true,
                items: action.docs
            };
        case collectorConstants.GET_COLLECTOR_DOCUMENT_LIST_FAILURE:
            return {};

        default:
            return state
    }
}


// Collector log Reducer
export function getCollectorLogReducer(state = {}, action) {
    switch (action.type) {
        case collectorConstants.GET_COLLECTOR_LOGS_REQUEST:
            return {
                loggingIn: true,
            };
        case collectorConstants.GET_COLLECTOR_LOGS_SUCCESS:
            return {
                loggedIn: true,
                items: action.log
            };
        case collectorConstants.GET_COLLECTOR_LOGS_FAILURE:
            return {};
        default:
            return state
    }
}

/*************************** Reducers ends here. **************************************/


/******************************collectors Services starts here  ****************************************/
//get all collectors
export function getAllCollector(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/ajax/collectors?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


// get collector notes
export function getCollectorNotesService(collectorId) {
    return axiosInstance.get(`/ajax/get-collector-note-by-collector-id/${collectorId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}


// create new Collector
export function createCollector(obj) {
    return axiosInstance.post(`/ajax/create/collector`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

//get collector by id
export function getCollectorById(collectorId) {
    return axiosInstance.get(`/ajax/get-collector-by-id/${collectorId}`).then(
        (response) => {
            return response.data;
        })
}


// create collector Note
export function createCollectorNoteService(collectorId, obj) {
    return axiosInstance.post(`/ajax/create-collector-note/${collectorId}/notes`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


///update collector
export function updateCollector(obj) {
    return axiosInstance.put(`/ajax/update/collector/${obj.collectorId}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function createConfiguration(obj) {
    return axiosInstance.post(`/ajax/create/collector-config`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function updateConfiguration(obj) {
    return axiosInstance.put(`/ajax/update/collector-config`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function getCollectorConfig(collectorId) {
    return axiosInstance.get(`/ajax/get-collector-config-by-collector-id/${collectorId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export function deleteCollectorConfigByIdService(configId) {

    return axiosInstance.delete(`/ajax/delete-collector-config-by-configId/${configId}`).then(
        (response) => {
            return exceptionHandler(response);
        })

}


export function getCollectorDocuments(collectorId) {
    return axiosInstance.get(`/ajax/get-collector-document-list/${collectorId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

export function getCollectorLogs(collectorId) {
    return axiosInstance.get(`/ajax/get-collector-logs-by-collector-id/${collectorId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

//export Collector Data Service
export async function exportCollectorData(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/collector?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "collectorData");
            }
        })
        .catch(handleExportError);
}

//get job exists for collector
export function checkJobExist(collectorId, fileId) {
    return axiosInstance.get(`/ajax/get/collector-job/${collectorId}/${fileId}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

//create job for collector file
export function createFileJob(collectorId, fileId, jobType) {
    return axiosInstance.post(`/ajax/create/collector-file-job/${collectorId}/${fileId}/${jobType}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export async function deleteCollectorById(id) {
    return axiosInstance.delete(`/ajax/delete-collector-by-id/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

/******************************CollectorLinks Services ends here  ****************************************/




