import exceptionHandler, {responseDTOExceptionHandler} from "../../../common/helpers/exceptionHandler";
import {showInfoToast, showSuccessToast} from "../../../common/components/Toast";
import {history} from "../../../common/helpers/history";
import {alertActions} from "../../alert/alert";
import {handleExportError, resourceToFile, serialize} from "../../../common/helpers/utils";
import axiosInstance from "../../../axiosInstance";

export const autoHoaActions = {
    getAllData,
    createAutoHoaAction,
    updateAutoHoaAction,
    deleteAutoHoaAction,
    getAutoHoaByIdAction,
    exportData
}

export const autoHoaConstant = {
    GET_ALL_AUTO_HOA_REQUEST: 'GET_ALL_AUTO_HOA_REQUEST',
    GET_ALL_AUTO_HOA_SUCCESS: 'GET_ALL_AUTO_HOA_SUCCESS',
    GET_ALL_AUTO_HOA_FAILURE: 'GET_ALL_AUTO_HOA_FAILURE',

    CREATE_AUTO_HOA_REQUEST: 'CREATE_AUTO_HOA_REQUEST',
    CREATE_AUTO_HOA_SUCCESS: 'CREATE_AUTO_HOA_SUCCESS',
    CREATE_AUTO_HOA_FAILURE: 'CREATE_AUTO_HOA_FAILURE',

    GET_AUTO_HOA_BY_ID_REQUEST: 'GET_AUTO_HOA_BY_ID_REQUEST',
    GET_AUTO_HOA_BY_ID_SUCCESS: 'GET_AUTO_HOA_BY_ID_SUCCESS',
    GET_AUTO_HOA_BY_ID_FAILURE: 'GET_AUTO_HOA_BY_ID_FAILURE',

    UPDATE_AUTO_HOA_REQUEST: 'UPDATE_AUTO_HOA_REQUEST',
    UPDATE_AUTO_HOA_SUCCESS: 'UPDATE_AUTO_HOA_SUCCESS',
    UPDATE_AUTO_HOA_FAILURE: 'UPDATE_AUTO_HOA_FAILURE',

    DELETE_AUTO_HOA_REQUEST: 'DELETE_AUTO_HOA_REQUEST',
    DELETE_AUTO_HOA_SUCCESS: 'DELETE_AUTO_HOA_SUCCESS',
    DELETE_AUTO_HOA_FAILURE: 'DELETE_AUTO_HOA_FAILURE',

    EXPORT_AUTO_HOA_REQUEST: 'EXPORT_AUTO_HOA_REQUEST',
    EXPORT_AUTO_HOA_SUCCESS: 'EXPORT_AUTO_HOA_SUCCESS',
    EXPORT_AUTO_HOA_FAILURE: 'EXPORT_AUTO_HOA_FAILURE',
}

export const autoHoaService = {
    getAllAutoHoa,
    submitAutoHoa,
    updateAutoHoa,
    deleteAutoHoa,
    getAutoHoaById,
    exportAutoHOAData
}

// get All auto hoa Action
function getAllData(query) {
    return dispatch => {
        dispatch(request());
        autoHoaService.getAllAutoHoa(query)
            .then(
                autoHoa => dispatch(success(autoHoa)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: autoHoaConstant.GET_ALL_AUTO_HOA_REQUEST}
    }

    function success(autoHoa) {
        return {type: autoHoaConstant.GET_ALL_AUTO_HOA_SUCCESS, autoHoa}
    }

    function failure(error) {
        return {type: autoHoaConstant.GET_ALL_AUTO_HOA_FAILURE, error}
    }

}

//get Auto hoa by id
function getAutoHoaByIdAction(id) {
    return dispatch => {
        dispatch(request());
        autoHoaService.getAutoHoaById(id)
            .then(
                autoHoa => dispatch(success(autoHoa)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: autoHoaConstant.GET_AUTO_HOA_BY_ID_REQUEST}
    }

    function success(autoHoa) {
        return {type: autoHoaConstant.GET_AUTO_HOA_BY_ID_SUCCESS, autoHoa}
    }

    function failure(error) {
        return {type: autoHoaConstant.GET_AUTO_HOA_BY_ID_FAILURE, error}
    }
}


//create auto hoa Action
function createAutoHoaAction(obj, from, setDisable, setShow) {
    return dispatch => {
        dispatch(request({obj}));
        autoHoaService.submitAutoHoa(obj)
            .then(
                autoHoa => {
                    if (autoHoa['errorCode'] === 200) {
                        showSuccessToast("Auto HOA added successfully!");
                        dispatch(success(autoHoa));
                        if (obj.customerId) {
                            setShow(false)
                            dispatch(getAllData({customer: obj.customerId}))
                        } else {
                            history.push(from);
                        }
                    } else {
                        responseDTOExceptionHandler(autoHoa);
                    }
                    setDisable(false);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(autoHoa) {
        return {type: autoHoaConstant.CREATE_AUTO_HOA_REQUEST, autoHoa}
    }

    function success(autoHoa) {
        return {type: autoHoaConstant.CREATE_AUTO_HOA_SUCCESS, autoHoa}
    }

    function failure(error) {
        return {type: autoHoaConstant.CREATE_AUTO_HOA_FAILURE, error}
    }
}


//update auto hoa Action
function updateAutoHoaAction(obj, id, from, setDisable, setShow) {
    return dispatch => {
        dispatch(request({obj}));
        autoHoaService.updateAutoHoa(obj, id)
            .then(
                autoHoa => {
                    if (autoHoa['errorCode'] === 200) {
                        showSuccessToast("Auto HOA updated successfully!");
                        dispatch(success(autoHoa));
                        if (obj.customerId !== undefined) {
                            setShow(false)
                            dispatch(getAllData({customer: obj.customerId}))
                        } else {
                            history.push(from);
                        }
                        setDisable(false);
                    } else {
                        responseDTOExceptionHandler(autoHoa);
                        setDisable(false);
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(autoHoa) {
        return {type: autoHoaConstant.UPDATE_AUTO_HOA_REQUEST, autoHoa}
    }

    function success(autoHoa) {
        return {type: autoHoaConstant.UPDATE_AUTO_HOA_SUCCESS, autoHoa}
    }

    function failure(error) {
        return {type: autoHoaConstant.UPDATE_AUTO_HOA_FAILURE, error}
    }
}

//delete Auto Hoa
function deleteAutoHoaAction(deleteQuery) {
    return dispatch => {
        dispatch(request());
        autoHoaService.deleteAutoHoa(deleteQuery.deleteReference)
            .then(
                autoHoa => {
                    if (autoHoa['errorCode'] === 200) {
                        showSuccessToast("Auto HOA Deleted successfully!");
                        dispatch(success(autoHoa));
                        if (deleteQuery.customerId!==undefined && deleteQuery.customerId !== null) {
                            deleteQuery.setShowDeleteConfirm(false)
                            dispatch(getAllData({customer: deleteQuery.customerId}))
                        } else {
                            deleteQuery.setShowDeleteConfirm(false)
                            history.push(deleteQuery.from);
                        }
                        deleteQuery.setDisable(false);
                    } else {
                        responseDTOExceptionHandler(autoHoa);
                        deleteQuery.setDisable(false);
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(autoHoa) {
        return {type: autoHoaConstant.DELETE_AUTO_HOA_REQUEST, autoHoa}
    }

    function success(autoHoa) {
        return {type: autoHoaConstant.DELETE_AUTO_HOA_SUCCESS, autoHoa}
    }

    function failure(error) {
        return {type: autoHoaConstant.DELETE_AUTO_HOA_FAILURE, error}
    }
}

//export Customers Data
function exportData(query) {
    return dispatch => {
        dispatch(request());
        autoHoaService.exportAutoHOAData(query)
            .then(
                autoHoa => dispatch(success(autoHoa)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: autoHoaConstant.EXPORT_AUTO_HOA_REQUEST}
    }

    function success(autoHoa) {
        query.setExportBtn(false);
        return {type: autoHoaConstant.EXPORT_AUTO_HOA_SUCCESS, autoHoa}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: autoHoaConstant.EXPORT_AUTO_HOA_FAILURE, error}
    }

}

/******************************** Auto HOA Actions ENDS here  ****************************************/


/************************** Reducers starts here. **************************************/
//get all auto hoa reducer
export function getAllAutoHoaReducer(state = {}, action) {
    switch (action.type) {
        case autoHoaConstant.GET_ALL_AUTO_HOA_REQUEST:
            return {
                ...state,
                loading: true,
                loggingIn: true,
            };
        case autoHoaConstant.GET_ALL_AUTO_HOA_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                items: action.autoHoa
            };
        case autoHoaConstant.GET_ALL_AUTO_HOA_FAILURE:
            return {};
        default:
            return state
    }

}

//get auto hoa by id reducer
export function getAutoHoaIdReducer(state = {}, action) {
    switch (action.type) {
        case autoHoaConstant.GET_AUTO_HOA_BY_ID_REQUEST:
            return {
                loggingIn: true,
            };
        case autoHoaConstant.GET_AUTO_HOA_BY_ID_SUCCESS:
            return {
                loggedIn: true,
                items: action.autoHoa
            };
        case autoHoaConstant.GET_AUTO_HOA_BY_ID_FAILURE:
            return {};
        default:
            return state
    }
}


/****************************** Auto HOA Services starts here  ****************************************/
//get all appraisalRolls
export function getAllAutoHoa(query) {
    let paramData = serialize(query)
    return axiosInstance.get(`/ajax/auto-hoa?${paramData}`).then(
        (response) => {
            return exceptionHandler(response);
        })
}

// create new auto hoa
export function submitAutoHoa(obj) {
    return axiosInstance.post(`/ajax/create/auto-hoa`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function getAutoHoaById(id) {
    return axiosInstance.get(`/ajax/get-auto-hoa-by-id/${id}`).then(
        (response) => {
            return response.data;
        })
}

export function updateAutoHoa(obj, id) {
    return axiosInstance.put(`/ajax/update/auto-hoa/${id}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


export function deleteAutoHoa(id) {
    return axiosInstance.delete(`/ajax/delete/auto-hoa/${id}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}


//export Auto Hoa Data
export async function exportAutoHOAData(query) {
    let paramData = serialize(query)
    return axiosInstance.post(`/export/auto-hoa?${paramData}`, null,
        {
            headers:
                {
                    'Content-Disposition': "attachment; filename=template.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }
    )
        .then((response) => {
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "HOAData");
            }
        })
        .catch(handleExportError);
}



